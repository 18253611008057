import React, {
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPopper } from '@popperjs/core';
import getBasePlacement from '@popperjs/core/lib/utils/getBasePlacement';
import getOppositePlacement from '@popperjs/core/lib/utils/getOppositePlacement';
import getOppositeVariationPlacement from '@popperjs/core/lib/utils/getOppositeVariationPlacement';
import { auto } from '@popperjs/core/lib/enums';
import { produce } from 'immer';
import token from '@mc/design-tokens/dist/tokens.common';
import useJoinedRef from '@mc/hooks/useJoinedRef';
import Portal from '../Portal';
import usePrefersReducedMotion from '@mc/hooks/usePrefersReducedMotion';

export const useLastResized = (ref) => {
  const [lastResized, setLastResized] = useState(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      setLastResized(Date.now());
    };

    handleResize();
    let resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
      resizeObserver = null;
    };
  }, [ref]);

  return lastResized;
};

/**
 * Meant for internal use within component library only.
 *
 * Popup is a style-less component that positions itself. Popup will prefer
 * to orient itself in a particular direction relative to its target. If there
 * is no room in the viewport, Popup will attempt to flip to the other side of
 * the target (when `fixed` is false).
 *
 * The Popup's width will be determined by its content. Clamp its width with
 * min-width and/or max-width, or use `matchTargetWidth` to have Popup match
 * the target's width. Passing an `offset` increases the distance between the
 * target and Popup.
 *
 * Optionally, you can pass an `arrow` JSX element. The arrow is also
 * style-less, and is positioned by Popup as well.
 *
 * Styling Popup is a matter of passing a `className`. Popup puts the
 * current placement inside an attribute, `data-popup-arrow-placement`. This
 * is helpful for rendering arrows. See Tooltip for an example.
 */
const Popup = React.forwardRef(function Popup(
  {
    arrow,
    children,
    fixed = false,
    inline = false,
    matchTargetWidth = false,
    offset = 0,
    placement: preferredPlacement = 'bottom',
    targetRef,
    style,
    unsafe_key,
    ...props
  },
  forwardedRef,
) {
  const popperInstanceRef = useRef(null);
  const popupRef = useRef(null);
  const arrowRef = useRef(null);
  const lastResized = useLastResized(targetRef);
  const ref = useJoinedRef(popupRef, forwardedRef);

  // Popper updates the position asynchronously, but folks may want to start
  // focusing on an item inside the popup right away before Popper finishes
  // positioning. As a result, the focus will scroll the screen to wherever
  // Popper's initial position is. Using an initial fixed position off-screen
  // will prevent scrolling and the 100% positioning also prevents flashes of
  // content. See: https://github.com/reakit/reakit/pull/848 as well as
  // https://github.com/reakit/reakit/pull/853
  const [popupStyles, setPopupStyles] = useState({
    position: 'fixed',
    left: '100%',
    top: '100%',
  });
  const [arrowStyles, setArrowStyles] = useState(undefined);
  const [placement, setPlacement] = useState(preferredPlacement);

  // Calculates and provides fallback placement given the available screen size.
  // https://github.com/popperjs/popper-core/blob/4800b37c5b4cabb711ac1d904664a70271487c4b/src/modifiers/flip.js#L25
  function getExpandedFallbackPlacements(currentPlacement) {
    if (getBasePlacement(currentPlacement) === auto) {
      return [];
    }

    const oppositePlacement = getOppositePlacement(currentPlacement);

    return [
      getOppositeVariationPlacement(currentPlacement),
      oppositePlacement,
      getOppositeVariationPlacement(oppositePlacement),
    ];
  }

  const clonedArrow = arrow
    ? React.cloneElement(arrow, {
        style: { ...arrow.props.style, ...arrowStyles },
        ref: arrowRef,
      })
    : null;

  const prefersReducedMotion = usePrefersReducedMotion();

  const getPopperOptions = useCallback(() => {
    return {
      placement: preferredPlacement,
      strategy: 'absolute',
      // Code inspired by reakit and react-popper:
      // https://github.com/reakit/reakit/blob/4a0ae41/packages/reakit/src/Popover/PopoverState.ts
      // https://github.com/popperjs/react-popper/blob/0dc52d2/src/usePopper.js
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
            adaptive: false,
          },
        },
        // https://popper.js.org/docs/v2/modifiers/#custom-modifiers
        // For slide animations we need to compute the offset
        // while the animation is handed off to CSS
        {
          name: 'setAnimationOffset',
          enabled: !prefersReducedMotion,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            const { x, y } = state.modifiersData.offset[state.placement];
            state.styles.popper.left = state.modifiersData.popperOffsets.x - x;
            state.styles.popper.top = state.modifiersData.popperOffsets.y - y;
            state.styles.popper.transform = `translate(${x}px, ${y}px)`;
          },
        },
        {
          // https://popper.js.org/docs/v2/modifiers/#custom-modifiers
          // Automatically sets the popup width to the target width.
          name: 'sameWidth',
          enabled: matchTargetWidth,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`;
          },
          effect: ({ state }) => {
            state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
          },
        },
        {
          // https://popper.js.org/docs/v2/modifiers/#custom-modifiers
          // React prefers to control all the DOM, so we disable Popper's
          // automatic application of styles and do it ourselves within React.
          name: 'updateState',
          enabled: true,
          phase: 'write',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            setPlacement(state.placement);
            // We use immer to maintain referential equality. This helps cut
            // down on re-renders.
            setPopupStyles(
              produce((draftState) => ({
                ...draftState,
                ...state.styles.popper,
                zIndex: token.zModal,
              })),
            );
            setArrowStyles(
              produce((draftState) => ({
                ...draftState,
                ...state.styles.arrow,
              })),
            );
          },
        },
        {
          // https://popper.js.org/docs/v2/modifiers/apply-styles/
          name: 'applyStyles',
          enabled: false,
        },
        {
          // https://popper.js.org/docs/v2/modifiers/flip/
          name: 'flip',
          enabled: !fixed,
          options: {
            padding: 8,
            // Fallback for collision detection on mobile
            // https://popper.js.org/docs/v2/modifiers/flip/#fallbackplacements
            fallbackPlacements: [
              ...getExpandedFallbackPlacements(preferredPlacement),
              'bottom',
              'top',
            ],
          },
        },
        {
          // https://popper.js.org/docs/v2/modifiers/offset/
          name: 'offset',
          enabled: true,
          // We probably don't ever need to set the skidding.
          options: { offset: offset ? [0, offset] : undefined },
        },
        {
          // https://popper.js.org/docs/v2/modifiers/prevent-overflow/
          name: 'preventOverflow',
          enabled: true,
          options: {
            tetherOffset: () => {
              return arrowRef.current ? arrowRef.current.clientWidth : 0;
            },
          },
        },
        {
          // https://popper.js.org/docs/v2/modifiers/arrow/
          name: 'arrow',
          enabled: !!arrowRef.current,
          options: { element: arrowRef.current },
        },
      ],
    };
  }, [
    fixed,
    matchTargetWidth,
    offset,
    preferredPlacement,
    prefersReducedMotion,
  ]);

  useLayoutEffect(() => {
    if (popperInstanceRef.current) {
      popperInstanceRef.current.setOptions(getPopperOptions());
    }
  }, [getPopperOptions]);

  useLayoutEffect(() => {
    if (!targetRef.current || !popupRef.current) {
      return;
    }

    popperInstanceRef.current = createPopper(
      targetRef.current,
      popupRef.current,
      getPopperOptions(),
    );

    return () => {
      if (popperInstanceRef.current) {
        popperInstanceRef.current.destroy();
        popperInstanceRef.current = null;
      }
    };
    // We do not want to add `getPopperOptions` to the deps because it'll
    // reconstruct the popper instance every time a prop changes. At the same
    // time, we do want to calculate the options dynamically so that we get the
    // latest references to elements.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef]);

  // Force updates to the underlying popper instance whenever the target rect
  // changes (via ResizeObserver) or when unsafe_key changes.
  useEffect(() => {
    if (popperInstanceRef.current) {
      popperInstanceRef.current.forceUpdate();
    }
  }, [lastResized, unsafe_key]);

  const Component = inline ? React.Fragment : Portal;

  return (
    <Component className="mcds-popup-portal-root">
      <div
        ref={ref}
        style={{ ...style, ...popupStyles }}
        {...props}
        data-popup-arrow-placement={placement}
      >
        {children}
        {clonedArrow}
      </div>
    </Component>
  );
});

Popup.propTypes = {
  /** Pass an element to render it as the arrow. Does not accept Fragments. */
  arrow: PropTypes.element,
  /** The internals of the popup. */
  children: PropTypes.node,
  /** If true, no longer flips to the opposite placement when there is no more room in the default placement. */
  fixed: PropTypes.bool,
  /** If true, renders inline instead of a Portal at the end of the DOM. */
  inline: PropTypes.bool,
  /** If true, expands the popup width to match its target. */
  matchTargetWidth: PropTypes.bool,
  /** The offset, in pixels, between the target and the popup. You'll probably want to adjust this if you're using an arrow. */
  offset: PropTypes.number,
  /** The default placement of the popup relative to its target. */
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /** @ignore */
  style: PropTypes.object,
  /** A reference to the element that the popup orients itself around. */
  targetRef: PropTypes.shape({ current: PropTypes.any }),
  /** Forces the Popup to reposition when this key changes. */
  unsafe_key: PropTypes.any,
};

export default Popup;
