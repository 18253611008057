import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuDownIcon } from '@mc/wink-icons';
import Animate from '../Animate';
import stylesheet from './Disclosure.less';

const Disclosure = React.forwardRef(function Disclosure(
  { children, heading },
  forwardedRef,
) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={stylesheet.root} ref={forwardedRef}>
      <button
        aria-expanded={isExpanded}
        type="button"
        className={stylesheet.heading}
        onClick={() => setIsExpanded((value) => !value)}
      >
        <MenuDownIcon />
        {heading}
      </button>
      <Animate
        animation="slide"
        easing="expressive"
        toggle={isExpanded}
        className={stylesheet.body}
      >
        {children}
      </Animate>
    </div>
  );
});

Disclosure.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
};

export default Disclosure;
