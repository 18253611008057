import React from 'react';
import PropTypes from 'prop-types';
import useId from '@mc/hooks/useId';
import stylesheet from './PageHeaderBreadcrumbs.less';
import ButtonOrLink from '../ButtonOrLink';
import { TranslatePage } from '../Page/TranslatePage';

export const PageHeaderBreadcrumb = ({ isCurrent, children, ...props }) => {
  return (
    <ButtonOrLink
      {...props}
      className={stylesheet.breadcrumb}
      disabled={isCurrent}
    >
      {children}
    </ButtonOrLink>
  );
};

PageHeaderBreadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
};

const PageHeaderBreadcrumbs = ({ children }) => {
  const id = useId();
  // Translate default text
  const { breadcrumbsText } = TranslatePage();
  return (
    <nav aria-labelledby={id} className={stylesheet.breadcrumbs}>
      <span id={id} className="wink-visually-hidden">
        {breadcrumbsText}
      </span>
      <ul>
        {React.Children.map(children, (child) => (
          <li aria-current={child.props.isCurrent ? 'page' : undefined}>
            {child}
          </li>
        ))}
      </ul>
    </nav>
  );
};

PageHeaderBreadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeaderBreadcrumbs;
