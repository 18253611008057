import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useUpdateEffect from '@mc/hooks/useUpdateEffect';
import TabContext from './TabContext';
import getTabId from './getTabId';
import stylesheet from './Tab.less';
function Tab({ index, onClick, ...props }) {
  const ref = useRef();
  const {
    id,
    currentIndex,
    setCurrentIndex,
    size,
    focusIndex,
    setFocusIndex,
    isManual,
    showOutline,
    setShowOutline,
  } = useContext(TabContext);
  const tabId = getTabId(id, index);
  const isCurrentTab = currentIndex === index;
  const isFocused = focusIndex === index;

  const classList = cx(
    stylesheet.root,
    stylesheet[size],
    isManual && showOutline && stylesheet['focus-outline'],
  );

  useUpdateEffect(() => {
    if (isManual && isFocused) {
      ref.current.focus();
    }

    if (!isManual && isCurrentTab) {
      ref.current.focus();
    }
  }, [index, isCurrentTab, isFocused, isManual]);

  const handleClick = () => {
    if (isManual) {
      setFocusIndex(index);
    }

    if (onClick) {
      onClick();
    }

    setCurrentIndex(index);
    setShowOutline(false);
  };

  const getTabIndex = () => {
    const trackedIndex = isManual ? focusIndex : currentIndex;

    return trackedIndex === index ? 0 : -1;
  };

  return (
    <span
      className={classList}
      ref={ref}
      role="tab"
      id={tabId}
      tabIndex={getTabIndex()}
      aria-selected={isCurrentTab}
      aria-controls={tabId && `${tabId}:panel`}
      onClick={() => handleClick()}
      {...props}
    />
  );
}

Tab.propTypes = {
  index: PropTypes.number,
  onClick: PropTypes.func,
};

export default Tab;
