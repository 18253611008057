import React from 'react';
/**
 *  This is temporary until the small icons are added to wink-icons - Adekunle
 *
 */
const TickIconSmall = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.65 3.24a1 1 0 01.11 1.41l-6 7a1 1 0 01-1.467.057l-3-3a1 1 0 011.414-1.414l2.237 2.236 5.297-6.18a1 1 0 011.41-.108z"
      />
    </svg>
  );
};

export default TickIconSmall;
