import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';
import { TickIcon } from '@mc/wink-icons';
import stylesheet from './ComboboxOption.less';

const ComboboxOption = ({
  'aria-selected': ariaSelected,
  children,
  value,
  isHighlighted = false,
  setActive,
  onSelect,
  ...props
}) => {
  const ref = useRef();
  useLayoutEffect(() => {
    if (isHighlighted && ref.current) {
      scrollIntoView(ref.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
      });
    }
  }, [isHighlighted]);

  return (
    <li
      ref={ref}
      className={cx(stylesheet.option, {
        [stylesheet.isHighlighted]: isHighlighted,
      })}
      role="option"
      aria-selected={ariaSelected}
      onClick={() => onSelect(value)}
      // If options appear under the mouse, onMouseEnter will fire, but
      // onMouseMove will not. A subtle but effective usability improvement.
      onMouseMove={() => {
        setActive();
      }}
      {...props}
    >
      {children || value}
      {ariaSelected && <TickIcon className={stylesheet.selectedIcon} />}
    </li>
  );
};

ComboboxOption.propTypes = {
  /** Can be true, false, or undefined. True and false will announce to screen readers; undefined will not. */
  'aria-selected': PropTypes.bool,
  /** If added, this will display in the suggest instead of the value. Note that value will still be whats populated in the input upon selection. */
  children: PropTypes.node,
  /** @ignore */
  isHighlighted: PropTypes.bool,
  /** @ignore */
  onSelect: PropTypes.func,
  /** @ignore */
  setActive: PropTypes.func,
  /** The text used to populate the input when added */
  value: PropTypes.string.isRequired,
};

export default ComboboxOption;
