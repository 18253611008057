import React from 'react';
import PropTypes from 'prop-types';
import { RedirectIcon } from '@mc/wink-icons';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

const NewWindowLink = React.forwardRef(function NewWindowLink(
  { href, rel = 'noopener noreferrer', children, ...props },
  forwardedRef,
) {
  // Translate default text
  const openWindowText = useDsTranslateMessage({
    id: 'mcds.new_window_link.open_text',
    defaultMessage: '(opens in new window)',
  });
  return (
    <a
      href={href}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      rel={rel === '' ? undefined : rel}
      ref={forwardedRef}
      {...props}
    >
      {children}
      <RedirectIcon style={{ verticalAlign: 'middle', height: '1em' }} />
      <span className="wink-visually-hidden">{openWindowText}</span>
    </a>
  );
});

NewWindowLink.propTypes = {
  /** The content inside the link */
  children: PropTypes.node.isRequired,
  /** The same URL string an `<a>` tag would expect. */
  href: PropTypes.string.isRequired,
  /** Added to prevent phishing attacks by link target. */
  rel: PropTypes.string,
};

export default NewWindowLink;
