import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuDownIcon } from '@mc/wink-icons';
import Animate from '../Animate';
import stylesheet from './Accordion.less';

const AccordionItem = React.forwardRef(function AccordionItem(
  { children, heading, onToggle = () => {}, defaultIsExpanded = false },
  forwardedRef,
) {
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);
  return (
    <div className={stylesheet.item} ref={forwardedRef}>
      <button
        aria-expanded={isExpanded}
        type="button"
        className={stylesheet.heading}
        onClick={() => {
          onToggle(!isExpanded);
          setIsExpanded((value) => !value);
        }}
      >
        <MenuDownIcon />
        {heading}
      </button>
      <Animate
        animation="slide"
        easing="expressive"
        toggle={isExpanded}
        className={stylesheet.body}
      >
        {children}
      </Animate>
    </div>
  );
});

AccordionItem.propTypes = {
  /** Content inside the accordion */
  children: PropTypes.node.isRequired,
  /** When true, the accordion item will be inititally expanded */
  defaultIsExpanded: PropTypes.bool,
  /** The text shown in the toggle button  */
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** A callback that triggers when the accordion expands/collapses. */
  onToggle: PropTypes.func,
};

const Accordion = React.forwardRef(function Accordion(
  { children },
  forwardedRef,
) {
  return (
    // We explicitly set role="list" to accommodate VoiceOver, which hides list
    // semantics when `list-style` is reset.
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ul role="list" className={stylesheet.root} ref={forwardedRef}>
      {React.Children.map(children, (child) => {
        return child && <li className={stylesheet.listItem}>{child}</li>;
      })}
    </ul>
  );
});

Accordion.propTypes = {
  /** Pass `AccordionItem` components */
  children: PropTypes.node,
};

export default Accordion;
export { AccordionItem };
