import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './Modal.less';

const ModalSideView = ({ children, className, ...rest }) => {
  return (
    <div className={cx(stylesheet.sideView, className)} {...rest}>
      {children}
    </div>
  );
};

ModalSideView.propTypes = {
  /** The side view content inside the modal.  */
  children: PropTypes.node,
  /** Additional class names to apply to the side view. */
  className: PropTypes.string,
};

export default ModalSideView;
