import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { useTransition, animated } from 'react-spring';
import bezier from 'bezier-easing';
import usePrefersReducedMotion from '@mc/hooks/usePrefersReducedMotion';

const ANIMATIONS = {
  fade: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  slide: {
    from: { x: 0, opacity: 0 },
    enter: { x: 1, opacity: 1 },
    // We want the leaving animation to appear a bit less severe so we don't return to 1
    leave: { x: 0.5, opacity: 0 },
  },
};

const SPEED = {
  instant: 0,
  fast: 120,
  medium: 200,
  slow: 320,
};

const EASING = {
  cubic: (t) => ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2,
  calm: bezier(0.4, 0, 0.64, 1),
  dynamic: bezier(0.84, 0, 0.16, 1),
  expressive: bezier(0.8, 0, 0.16, 1),
  productive: bezier(0.56, 0.08, 0.4, 1),
};

/**
 * Internal component for standardizing animations. Currently used for mount and
 * unmount animations.
 *
 * This API is experimental and likely to change.
 */
const Animate = React.forwardRef(function Animate(
  {
    animation = 'fade',
    children,
    component,
    easing = 'cubic',
    speed = 'medium',
    style,
    toggle,
    ...props
  },
  forwardedRef,
) {
  const prefersReducedMotion = usePrefersReducedMotion();

  const springOptions = {
    ...ANIMATIONS[animation],
    config: {
      duration: prefersReducedMotion ? 0 : SPEED[speed],
      easing: EASING[easing],
    },
  };

  const Component = useMemo(() => {
    if (!component) {
      return animated.div;
    }
    return animated(component);
  }, [component]);

  const transitions = useTransition(toggle, springOptions);

  return transitions((styles, item) => {
    if (!item) {
      return item;
    }

    if (animation === 'slide') {
      return (
        <Component style={style} ref={forwardedRef} {...props}>
          <animated.div
            style={{
              opacity: styles.opacity,
              transform: styles.x.to((v) => `translateY(${-0.25 * (1 - v)}em)`),
            }}
          >
            {children}
          </animated.div>
        </Component>
      );
    }

    return (
      <Component style={{ ...style, ...styles }} ref={forwardedRef} {...props}>
        {children}
      </Component>
    );
  });
});

Animate.propTypes = {
  /** The kind of animation to use */
  animation: PropTypes.oneOf(['fade', 'slide']),
  children: PropTypes.node,
  /** The component to animate */
  component: PropTypes.elementType,
  /** The easing function for the animation */
  easing: PropTypes.oneOf([
    'cubic',
    'calm',
    'dynamic',
    'expressive',
    'productive',
  ]),
  /** The speed of the animation */
  speed: PropTypes.oneOf(['instant', 'fast', 'medium', 'slow']),
  /** @ignore */
  style: PropTypes.object,
  /** To toggle the enter/leave animation */
  toggle: PropTypes.bool.isRequired,
};

export default Animate;
