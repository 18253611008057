import React from 'react';
import PropTypes from 'prop-types';

import Select, { Option } from '../Select';

function pad(input) {
  return String(input).padStart(2, '0');
}

const formatTime = (twentyFourHour, hour, minute) => {
  // Adds consistency with time display as referenced in styleguide
  // See https://styleguide.mailchimp.com/grammar-and-mechanics/#header-4-time
  const min = minute > 0 ? ':' + pad(minute) : ' ';
  const ampm = hour < 12 || hour === 24 ? 'am' : 'pm';
  return twentyFourHour
    ? pad(hour) + ':' + pad(minute)
    : (hour % 12 || 12) + min + ampm;
};

function getTimes(twentyFourHour = false, min, max) {
  const [minHour, minMinute] = min.split(':').map(Number);
  const [maxHour, maxMinute] = max.split(':').map(Number);
  const times = [];

  const minTime = minHour * 60 + minMinute;
  const maxTime = maxHour * 60 + maxMinute;

  for (let time = minTime; time <= maxTime; time += 15) {
    const minute = time % 60;
    const hour = Math.floor(time / 60);
    const formatted = formatTime(twentyFourHour, hour, minute);

    times.push({
      formatted,
      value: `${hour}:${pad(minute)}`,
    });
  }

  return times;
}

const InputTime = React.forwardRef(function InputTime(
  { twentyFourHour = false, min = '0:00', max = '23:59', ...props },
  forwardedRef,
) {
  return (
    <Select searchable {...props} ref={forwardedRef}>
      {getTimes(twentyFourHour, min, max).map(({ formatted, value }) => (
        <Option key={value} label={formatted} value={value}>
          {formatted}
        </Option>
      ))}
    </Select>
  );
});

InputTime.propTypes = {
  /** Whether or not the form field is interactive. */
  disabled: PropTypes.bool,
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error: PropTypes.string,
  /** Text that appears below the input. */
  helpText: PropTypes.node,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** The label of the input. */
  label: PropTypes.node,
  /** The latest time option to display. Inclusive. Default = "24:00" */
  max: PropTypes.string,
  /** The earliest time option to display. Default - "0:00" */
  min: PropTypes.string,
  /** The callback to fire when the user selects a new time. */
  onChange: PropTypes.func.isRequired,
  /** Whether or not to display the times in twenty-four hour format. */
  twentyFourHour: PropTypes.bool,
  /** The value for the field. */
  value: PropTypes.string.isRequired,
};

export default InputTime;
