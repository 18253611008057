import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuDownIcon, MenuUpIcon } from '@mc/wink-icons';
import Checkbox from '../Checkbox';
import IconButton from '../IconButton';
import { TranslateSlat } from './TranslateSlat';
import stylesheet from './Slat.less';

const NestedContext = createContext();

/* Slat Wrapper */
const Slats = ({ children, className, ...props }) => {
  return (
    <ul className={className} {...props}>
      {children}
    </ul>
  );
};

Slats.propTypes = {
  /** Consume `Slat` or `SlatGeneric` components */
  children: PropTypes.node.isRequired,
  /** Optional CSS class */
  className: PropTypes.string,
};

/* Individual Slat */
const Slat = React.forwardRef(function Slat(
  {
    children,
    onSelect,
    className,
    action,
    isSelected,
    isOpen = false,
    ...props
  },
  forwardedRef,
) {
  const [isExpanded, setIsExpanded] = useState(isOpen);

  let nestedSlats;
  const filteredChildren = [];

  // Handle rendering of nested Slats
  React.Children.forEach(children, (child) => {
    if (child.type === Slats) {
      nestedSlats = child;
    } else {
      filteredChildren.push(child);
    }
  });

  const classList = cx(
    stylesheet.root,
    {
      [stylesheet.interactive]: !!onSelect,
    },
    className,
  );

  const { checkBoxLabelText } = TranslateSlat();

  const initialContextValues = {
    isExpanded: nestedSlats ? isExpanded : null,
    setIsExpanded: setIsExpanded,
  };

  return (
    <NestedContext.Provider value={initialContextValues}>
      <li className={classList} ref={forwardedRef} {...props}>
        <div className={stylesheet.wrapper}>
          {!!onSelect && (
            <div className={stylesheet.selection}>
              <Checkbox
                hideLabel
                // TODO: Refactor to take children of SlatContent. This is too vague. aria-labelledby would work but this prop is required
                label={checkBoxLabelText}
                value={isSelected}
                onChange={onSelect}
              />
            </div>
          )}
          {filteredChildren}
          <div
            className={cx(
              action && action.props.icon
                ? stylesheet.iconAction
                : stylesheet.action,
            )}
          >
            {action && action}
          </div>
        </div>
        {/* If Slat is nested in another Slats wrapper */}
        {nestedSlats && (
          <div
            className={cx(stylesheet.child, {
              [stylesheet.expanded]: isExpanded,
            })}
          >
            {nestedSlats}
          </div>
        )}
      </li>
    </NestedContext.Provider>
  );
});

Slat.propTypes = {
  /** May consume `ComboButton`, `ActionList`, or `Button` for additional actions */
  action: PropTypes.element,
  /** Consume `SlatContent` */
  children: PropTypes.node.isRequired,
  /** Set on parent `Slat` to expand or collapse nested items. Defaults to collapsed. */
  isOpen: PropTypes.bool,
  /** The current value of the selected `Slat`. Used with `onSelect` */
  isSelected: PropTypes.bool,
  /** Triggers when the `Slat` is selected. */
  onSelect: PropTypes.func,
};

/* Content that lives inside of a Slat */
const SlatContent = ({ children, stats, thumbnail }) => {
  const { isExpanded, setIsExpanded } = useContext(NestedContext);
  const {
    iconButtonCollapseLabelText,
    iconButtonExpandLabelText,
  } = TranslateSlat();
  return (
    <React.Fragment>
      <div className={stylesheet.content}>
        <div>
          {thumbnail && (
            <img src={thumbnail} alt="" className={stylesheet.thumbnail} />
          )}
          {children}
        </div>
        {isExpanded !== null && (
          <div className={stylesheet.toggle}>
            <IconButton
              label={
                isExpanded
                  ? iconButtonCollapseLabelText
                  : iconButtonExpandLabelText
              }
              icon={isExpanded ? <MenuUpIcon /> : <MenuDownIcon />}
              onClick={() => setIsExpanded((last) => !last)}
              aria-expanded={isExpanded}
            />
          </div>
        )}
      </div>
      <SlatStats stats={stats} />
    </React.Fragment>
  );
};

SlatContent.propTypes = {
  /** Copy provided for individual `Slat` */
  children: PropTypes.node.isRequired,
  /** Additional detail with `name` and `value` keys. Name will set the subtitle.*/
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  /** Image src path for thumbnail preview */
  thumbnail: PropTypes.string,
};

const SlatStats = ({ stats }) => {
  return (
    <div className={stylesheet.stats}>
      {stats.map(({ name, value, className }) => (
        <dl key={name + value} className={className}>
          <dt>{name}</dt>
          <dd>{value}</dd>
        </dl>
      ))}
    </div>
  );
};

SlatStats.propTypes = {
  /** Additional detail with `name` and `value` keys. Name will set the subtitle.*/
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export { Slat as default, Slats, SlatContent, NestedContext };
