import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for TagInput default values
export const TranslateTagInput = (value) => {
  return {
    selectedTagText: useDsTranslateMessage({
      id: 'mcds.tag_input.selected_tag_text',
      defaultMessage: 'Selected tag:',
    }),
    hiddenTagCountText: useDsTranslateMessage({
      id: 'mcds.tag_input.hidden_tag_count_text',
      defaultMessage: `${value.count} more`,
      values: { count: value.count },
    }),
    showFewerText: useDsTranslateMessage({
      id: 'mcds.tag_input.show_fewer_text',
      defaultMessage: 'Show fewer',
    }),
    createText: useDsTranslateMessage({
      id: 'mcds.tag_input.create_text',
      defaultMessage: 'Create',
    }),
  };
};
