import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PlusIcon } from '@mc/wink-icons';

import IconButton from '../IconButton';
import Text from '../Text';
import TextButton from '../TextButton';

import stylesheet from './Swatches.less';
import convertColors from '@mc/fn/convertColors';
import { TranslateSwatches } from './TranslateColorPicker';

const VALUE_TYPE = {
  HEX: 'hex',
  RGB: 'rgb',
};

const Swatches = React.forwardRef(function Swatches(
  {
    colorValueType,
    swatches,
    onChange,
    onSwatchesChange,
    currentRgbaValue = {},
    rawHexValue,
    hideLabel = false,
  },
  forwardedRef,
) {
  const {
    r: currentRedValue,
    g: currentGreenValue,
    b: currentBlueValue,
    a: currentAlphaValue,
  } = currentRgbaValue;

  const editableSwatches = !!onSwatchesChange;

  const swatchGroupRef = useRef();
  const addToSwatchesRef = useRef();

  const {
    swatchesIconButtonLabelInitialText,
    swatchesIconButtonRgbLabelText,
    swatchesLabelText,
    swatchesIconButtonLabelEndText,
    swatchesTextButtonLabelText,
  } = TranslateSwatches({
    currentRedValue: currentRedValue,
    currentBlueValue: currentBlueValue,
    currentGreenValue: currentGreenValue,
  });

  return (
    // Wrapped with div instead of React fragment because it effects spacing in layout primitives.
    <div ref={forwardedRef}>
      <div className={stylesheet.header}>
        <div
          className={cx(
            'mcds-label-default',
            hideLabel && 'wink-visually-hidden',
          )}
        >
          {swatchesLabelText}
        </div>
        {editableSwatches && (
          <div className={stylesheet.addGroup}>
            <IconButton
              ref={addToSwatchesRef}
              className={stylesheet.addBtn}
              label={
                swatchesIconButtonLabelInitialText +
                (colorValueType === VALUE_TYPE.RGB
                  ? swatchesIconButtonRgbLabelText + currentAlphaValue * 100
                  : rawHexValue) +
                swatchesIconButtonLabelEndText
              }
              icon={<PlusIcon />}
              onClick={() => {
                onSwatchesChange((currentSwatches) => {
                  const newSwatch =
                    currentAlphaValue < 1 ? currentRgbaValue : rawHexValue;
                  return [newSwatch, ...currentSwatches];
                });
              }}
            />
          </div>
        )}
      </div>
      <div
        className={editableSwatches ? stylesheet.list : stylesheet.grid}
        ref={swatchGroupRef}
      >
        {swatches.map((colorValue, i) => {
          const { rgb, rgbString, hex } = convertColors(colorValue);
          const isTransparent = rgb.a < 1;
          const swatchValue =
            colorValueType === VALUE_TYPE.RGB
              ? `${rgb.r}, ${rgb.g}, ${rgb.b}`
              : hex
                  // Outputs in #rrggbbaa. This removes alpha values.
                  .substring(0, 7)
                  .toUpperCase();
          return (
            <div key={i} className={stylesheet.item}>
              <button
                type="button"
                className={stylesheet.button}
                onClick={() => onChange(rgbString)}
              >
                <span className={stylesheet.checkerBg}>
                  <span
                    className={cx(stylesheet.color, {
                      [stylesheet.isTransparent]: isTransparent,
                    })}
                    style={{
                      backgroundColor: isTransparent ? swatchValue : rgbString,
                    }}
                  />
                </span>
                {/* Hex/RGB value */}
                <span
                  className={cx(!editableSwatches && 'wink-visually-hidden')}
                >
                  {swatchValue}
                </span>
                {/* Alpha value */}
                {isTransparent && (
                  <Text
                    as="span"
                    appearance="small-secondary"
                    className={cx(!editableSwatches && 'wink-visually-hidden')}
                  >
                    {(rgb.a * 100).toFixed(0)}%
                  </Text>
                )}
              </button>
              {editableSwatches && (
                <TextButton
                  data-remove-swatch
                  className={stylesheet.itemRemoveBtn}
                  onClick={() => {
                    // Manage focus for remove buttons. This is tricky because
                    // the React key is index-based, so the swatches are not
                    // moved around, but are being modified in place.
                    const numSwatches = swatches.length;
                    if (numSwatches === 1) {
                      // This is the only swatch? Focus the "add" button.
                      addToSwatchesRef.current.focus();
                    } else if (i === numSwatches - 1) {
                      // Is this the last swatch? Focus the previous button.
                      const removeButtons = swatchGroupRef.current.querySelectorAll(
                        '[data-remove-swatch]',
                      );
                      removeButtons[i - 1].focus();
                    } else {
                      // Otherwise, do nothing--the `key` modifies elements in
                      // place so we never lose focus anyway.
                    }

                    // After managing focus, send messages.
                    onSwatchesChange(() => {
                      return swatches.filter((swatch, swatchIndex) => {
                        return swatchIndex !== i;
                      });
                    });
                  }}
                >
                  {swatchesTextButtonLabelText}
                  <span className="wink-visually-hidden">{swatchValue}</span>
                </TextButton>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

Swatches.propTypes = {
  /** Value type of color passed. */
  colorValueType: PropTypes.string,
  /** Current RGBA value of color. */
  currentRgbaValue: PropTypes.object,
  /** If true, the title of swatches will be hidden. */
  hideLabel: PropTypes.bool,
  /**  Called when the color changes. The first argument is an object of `{r, g, b, a}`. The RGB values are integers ranging from 0-255 and A is a float ranging from 0-1. */
  onChange: PropTypes.func,
  /** Called when swatches are added or removed. The first argument is the updated array of swatches. */
  onSwatchesChange: PropTypes.func,
  /** Hex format of current color. */
  rawHexValue: PropTypes.string,
  /** The array of swatches to display inside the Color Picker. The format is the same as value's. */
  swatches: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
};

export default Swatches;
