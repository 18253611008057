import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ListboxOption from '../Listbox/Option';
import { ListboxModeContext } from '../Listbox/Listbox';

const Option = ({ label, ...props }) => {
  const isListbox = useContext(ListboxModeContext);
  if (__DEV__) {
    if (isListbox && !label) {
      console.error(
        `when Select is given a prop of mode="listbox" (or the prop multiple), Options must have a label.`,
      );
    }
  }
  // Label is not passed as a prop for Option but used by Listbox. Specifically for
  // screen reader users or filtering/search. See Listbox/Listbox.js for context on how label is used.
  return isListbox ? <ListboxOption {...props} /> : <option {...props} />;
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  /** When using Listbox Mode, include a `label` as a plain string version of the option.
   * This is useful for screen reader users to filter through. */
  label: PropTypes.string,
};

export default Option;
