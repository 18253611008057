import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './ClickArea.less';

/**
 * When you click anywhere inside a `<ClickArea>`, the main click target gets
 * clicked instead. `<a>` or `<button>` elements can then receive larger click
 * targets than you'd normally feel comfortable giving.
 *
 * This improves the screen reader experience: Rather than wrap an <em>entire
 * component</em> with a button or link, we wrap an area with ClickArea, which
 * improves the experience for mouse- and touch-users while keeping it the same
 * for screen reader and keyboard users.
 *
 * Here's the magic: Let's assume you click anywhere inside a `<ClickArea>`. The
 * click event bubbles to `<ClickArea>`. Once here, `<ClickArea>` determines
 * whether a click event originated from the click target or somewhere else. If
 * the event originated from the click target, we continue bubbling. If not,
 * we call `.click()` on the click target. <strong>Note that this means elements
 * within ClickArea may receive two or more click events.</strong> However, the
 * main click target only ever receives one click event, and outside
 * `<ClickArea>`, only one click event bubbles, always originating from the main
 * click target.
 *
 * If you want to support secondary click targets within `<ClickArea>` you will
 * have to attach an `onClick` prop to each secondary click target and call
 * `event.stopPropagation()` inside them so they don't bubble to ClickArea.
 * Events with `event.preventDefault()` called continue bubbling, but are not
 * proxied to the main click target.
 */
const ClickArea = React.forwardRef(
  (
    {
      as: Component = 'div',
      children,
      className,
      onClick,
      targetRef,
      ...props
    },
    ref,
  ) => {
    const handleClick = useCallback(
      (event) => {
        const el = targetRef.current;

        if (!el) {
          return;
        }

        if (event.defaultPrevented) {
          return;
        }

        // If we clicked `targetRef` or its descendants, call `onClick` and bubble.
        if (el.contains(event.target)) {
          if (onClick) {
            onClick(event);
          }
        } else {
          // Otherwise, prevent bubbling, prevent default, and click the target.
          event.stopPropagation();
          event.preventDefault();
          el.focus();
          el.click();
        }
      },
      [onClick, targetRef],
    );

    return (
      <Component
        ref={ref}
        {...props}
        className={cx(styles.clickArea, className)}
        onClick={handleClick}
      >
        {children}
      </Component>
    );
  },
);

ClickArea.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  targetRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

export default ClickArea;
