import React from 'react';
import PropTypes from 'prop-types';
import useId from '@mc/hooks/useId';

import stylesheet from './ActionList.less';

const ActionListGroup = ({ children, label }) => {
  const id = useId();
  return (
    <div className={stylesheet.group} role="group" aria-labelledby={id}>
      <div id={id} className={stylesheet.groupLabel}>
        {label}
      </div>
      {children}
    </div>
  );
};

ActionListGroup.propTypes = {
  /** Children should be ActionListItems. */
  children: PropTypes.node,
  /** Title of each action item */
  label: PropTypes.string,
};

export default ActionListGroup;
