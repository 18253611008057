import React from 'react';
import PropTypes from 'prop-types';

import ClusterLayout from '../ClusterLayout';
import StackLayout from '../StackLayout';
import Heading from '../Heading';
import ContentArea from '../ContentArea';
import Text from '../Text';

import stylesheet from './DetailHero.less';

const DetailHero = React.forwardRef(function DetailHero(
  { eyebrow, title, children, secondary, image },
  forwardedRef,
) {
  return (
    <ContentArea className={stylesheet.root} ref={forwardedRef}>
      <ClusterLayout alignItems="flex-start" gap={7}>
        {image && <div>{image}</div>}
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <StackLayout gap={2}>
            {eyebrow && <div>{eyebrow}</div>}
            <Heading level={2}>{title}</Heading>
            {secondary && (
              <Text appearance="medium" className={stylesheet.secondary}>
                {secondary}
              </Text>
            )}
          </StackLayout>
          <div>{children}</div>
        </div>
      </ClusterLayout>
    </ContentArea>
  );
});

DetailHero.propTypes = {
  /** Usually contains DataWells or any other complementary content. */
  children: PropTypes.node,
  /** Can contain a badge and/or meta information. */
  eyebrow: PropTypes.node,
  /** Displays an id profile when an `img` element is provided. */
  image: PropTypes.node,
  /** Will normally be a small body of text. */
  secondary: PropTypes.string,
  /** Title will be a h2 that has an h1 appearance. */
  title: PropTypes.string.isRequired,
};

export default DetailHero;
