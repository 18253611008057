import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TickIconSmall from './TickIconSmall';
import useId from '@mc/hooks/useId';
import {
  ariaDescribedByIds,
  ariaLabelledByIds,
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
} from '../utils';
import stylesheet from './ToggleButton.less';

/**
 * Unlike checkboxes, they only exist as single elements and don’t appear in
 * sequences. Toggles are independent of each other, and interacting with one
 * doesn’t affect others.
 */
const ToggleButton = React.forwardRef(function ToggleButton(
  {
    'aria-labelledby': ariaLabelledBy,
    className,
    helpText,
    onChange,
    onClick,
    disabled = false,
    hideLabel = false,
    value = false,
    label,
    miscText,
    ...rest
  },
  forwardedRef,
) {
  const labelId = useId();
  const helpTextId = useId();

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    onChange(!value);
  };

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.hideLabel]: hideLabel && !helpText,
      })}
    >
      <div className={cx(stylesheet.content)}>
        {label && (
          <div id={labelId} className={cx(hideLabel && 'wink-visually-hidden')}>
            {label}
          </div>
        )}
        {helpText && (
          <div className={stylesheet.helpText} id={helpTextId}>
            {helpText}
          </div>
        )}
      </div>
      <div className={cx(stylesheet.toggleGroup)}>
        <button
          disabled={disabled}
          className={stylesheet.toggle}
          type="button"
          aria-pressed={value}
          aria-labelledby={ariaLabelledByIds(ariaLabelledBy, label && labelId)}
          aria-describedby={ariaDescribedByIds(helpText && helpTextId)}
          onClick={handleClick}
          ref={forwardedRef}
          {...rest}
        >
          {value && <TickIconSmall className={stylesheet.tickIcon} />}
          <span className={stylesheet.knob}></span>
        </button>
      </div>
    </div>
  );
});

ToggleButton.propTypes = {
  /** @ignore Throwaway propType to do complex checks. */
  // eslint-disable-next-line react/no-unused-prop-types
  _: (props, propName, componentName) => {
    if (!props.label && !props['aria-labelledby']) {
      return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName));
    }
  },
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /** disabled */
  disabled: PropTypes.bool,
  /** Helpt text for toggle */
  helpText: PropTypes.string,
  /** Visually hides the label provided by the label prop. Used for special cases only. Removes default alignment. */
  hideLabel: PropTypes.bool,
  /** The label of the toggle. Even if visually hidden, provide a label for assitive technology. */
  label: PropTypes.node,
  /** @ignore */
  miscText: PropTypes.node,
  /** Triggers callback function when toggle state changes*/
  onChange: PropTypes.func.isRequired,
  /** Triggers callback function on mouse click event */
  onClick: PropTypes.func,
  /** Specify if toggle is on/off */
  value: PropTypes.bool.isRequired,
};

export default ToggleButton;
