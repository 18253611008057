import React, { createContext, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import stylesheet from './Table.less';

const TableHeadContext = createContext();

const Table = React.forwardRef(function Table(
  {
    className,
    children,
    caption,
    hideCaption = false,
    isStriped = false,
    fixedFirstColumn = false,
    stickyHeader = false,
  },
  forwardedRef,
) {
  const classList = cx(stylesheet.root, className, {
    [stylesheet.striped]: isStriped,
    [stylesheet.fixedFirstColumn]: fixedFirstColumn,
    [stylesheet.stickyHeader]: stickyHeader,
  });
  return (
    <table ref={forwardedRef} className={classList}>
      <caption className={cx(hideCaption && 'wink-visually-hidden')}>
        {caption}
      </caption>
      {children}
    </table>
  );
});

Table.propTypes = {
  /** Provides descriptive context about the table and allows assistive technologies to navigate to caption. */
  caption: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  /** Set whether or not the first column should remain fixed on horizontal scroll. */
  fixedFirstColumn: PropTypes.bool,
  /** Visually hides table caption but available to screen readers. */
  hideCaption: PropTypes.bool,
  /** Set row striping with alternating background colors. */
  isStriped: PropTypes.bool,
  /** Set whether or not the table head should remain fixed on verticle scroll. */
  stickyHeader: PropTypes.bool,
};

const TableHead = ({ children }) => {
  return (
    <TableHeadContext.Provider value="tHead">
      <thead>{children}</thead>
    </TableHeadContext.Provider>
  );
};

TableHead.propTypes = {
  /** Consumes `TableRow` and `TableCell` components. */
  children: PropTypes.node.isRequired,
};

const TableBody = ({ children }) => {
  return <tbody>{children}</tbody>;
};

TableBody.propTypes = {
  /** Consumes `TableRow` and `TableCell` components. */
  children: PropTypes.node.isRequired,
};

const TableRow = ({ children }) => {
  return <tr>{children}</tr>;
};

TableRow.propTypes = {
  /** Consumes `TableCell` component. */
  children: PropTypes.node.isRequired,
};

const TableCell = ({ children, scope, className }) => {
  const tHead = useContext(TableHeadContext);
  /** Identify which element to use depending on the parent wrapper of <thead></thead> or <tbody></tbody>,
   * as well as whether or not the cell is a row header. */
  const Cell = tHead || scope ? 'th' : 'td';
  return (
    <Cell className={className} scope={scope}>
      {children}
    </Cell>
  );
};

TableCell.propTypes = {
  /** Renders `td` or `th` elements depending on parent wrapper. */
  children: PropTypes.node.isRequired,
  /** If provided, turns cell into a header cell and sets scope of the header cell. */
  scope: PropTypes.oneOf(['col', 'row', 'colgroup', 'rowgroup']),
};

export { Table as default, TableHead, TableBody, TableRow, TableCell };
