import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import stylesheet from './List.less';

export const ListItem = React.forwardRef(function ListItem(
  { className, appearance, ...props },
  forwardedRef,
) {
  return (
    <li
      className={cx(stylesheet.listItem, cx(stylesheet[appearance], className))}
      ref={forwardedRef}
      {...props}
    />
  );
});

ListItem.propTypes = {
  /** Setting an appearance preset here will override the one chosen for the List for this item. */
  appearance: PropTypes.oneOf([
    'large',
    'large-secondary',
    'large-bold',
    'medium',
    'medium-secondary',
    'medium-bold',
    'small',
    'small-secondary',
    'small-bold',
  ]),
  className: PropTypes.string,
};

const List = React.forwardRef(function List(
  {
    type = 'ul',
    className,
    children,
    appearance = 'medium',
    columns = 1,
    ...props
  },
  forwardedRef,
) {
  if (__DEV__) {
    React.Children.forEach(children, (child) => {
      if (child && child.type !== ListItem) {
        console.error(`List can only have children of type ListItem.`);
      }
    });
  }
  return (
    <>
      {React.createElement(type, {
        ref: forwardedRef,
        className: cx(
          stylesheet.list,
          `mcds-list-${type}-default`,
          stylesheet[appearance],
          columns > 1 && stylesheet.multiColumn,
          className,
        ),
        ...props,
        children,
      })}
    </>
  );
});

List.propTypes = {
  /** An appearance preset. */
  appearance: PropTypes.oneOf([
    'large',
    'large-secondary',
    'large-bold',
    'medium',
    'medium-secondary',
    'medium-bold',
    'small',
    'small-secondary',
    'small-bold',
  ]),
  /** Should only ever be a group of <ListItem>s */
  children: PropTypes.node.isRequired,
  /** A class that gets applied to the ul/ol */
  className: PropTypes.string,
  /** Set to 2 to use a multi-column layout */
  columns: PropTypes.oneOf([1, 2]),
  /** Either ul for an unordered list, or ol for an ordered list */
  type: PropTypes.oneOf(['ul', 'ol']),
};

export default List;
