import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for Input default values
export const TranslateInput = () => {
  return {
    showMsg: useDsTranslateMessage({
      id: 'mcds.input.show_text',
      defaultMessage: 'Show',
    }),
    hideMsg: useDsTranslateMessage({
      id: 'mcds.input.hide_text',
      defaultMessage: 'Hide',
    }),
    characterMsg: useDsTranslateMessage({
      id: 'mcds.input.character_text',
      defaultMessage: 'character',
    }),
    charactersMsg: useDsTranslateMessage({
      id: 'mcds.input.characters_text',
      defaultMessage: 'characters',
    }),
    availableMsg: useDsTranslateMessage({
      id: 'mcds.input.available_text',
      defaultMessage: 'available',
    }),
    usedMsg: useDsTranslateMessage({
      id: 'mcds.input.used_text',
      defaultMessage: 'used',
    }),
    inputDateMsg: useDsTranslateMessage({
      id: 'mcds.input.date_text',
      defaultMessage:
        'You are currently on a calendar. To dismiss the calendar, press the Escape key.',
    }),
    inputDateOpenMsg: useDsTranslateMessage({
      id: 'mcds.input.date_open_text',
      defaultMessage: 'Open date picker',
    }),
    inputDateInvalidMsg: useDsTranslateMessage({
      id: 'mcds.input.date_invalid_text',
      defaultMessage: 'Invalid date: out of range',
    }),
    inputDateInvalidFormatMsg: useDsTranslateMessage({
      id: 'mcds.input.date_invalid_format_text',
      defaultMessage: 'Invalid format: expected',
    }),
  };
};
