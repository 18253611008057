import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TickIcon } from '@mc/wink-icons';
import scrollIntoView from 'scroll-into-view-if-needed';
import stylesheet from './Option.less';

function Option({
  value,
  children,
  disabled = false,
  id,
  isFiltered = false,
  isSelected,
  isHighlighted = false,
  onClick,
  onHighlight,
}) {
  const ref = useRef();
  useLayoutEffect(() => {
    if (isHighlighted && ref.current) {
      scrollIntoView(ref.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
        boundary: ref.current.parentNode,
      });
    }
  }, [isHighlighted]);

  if (isFiltered) {
    return null;
  }

  return (
    <div
      ref={ref}
      aria-disabled={disabled || undefined}
      aria-selected={isSelected}
      role="option"
      id={id}
      className={cx(stylesheet.option, {
        [stylesheet.isHighlighted]: isHighlighted,
      })}
      onClick={() => !disabled && onClick()}
      onMouseMove={() => !disabled && onHighlight(value)}
    >
      {children || value}
      {/* Always render the tick mark to include it in width calculations for
+      listboxes inside popups (in particular, SelectInline's). We adjust the
+      CSS `visibility`. Note that the tick mark is purely visual and not exposed
+      to assistive technology; the `aria-selected` state is. */}
      <TickIcon className={stylesheet.selectedIcon} />
    </div>
  );
}

Option.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isFiltered: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onHighlight: PropTypes.func,
  value: PropTypes.any,
};

export default Option;
