import React from 'react';
import PropTypes from 'prop-types';
import Form from '../Form';
import { getNestedValue, setNestedValue } from '@mc/fn/nestedValue';
import { useWizardActions, useWizardState } from './Wizard';

const defaultNextStep = ({ currentStep, stepNames }) => {
  const nextStep = stepNames[stepNames.indexOf(currentStep) + 1];
  return nextStep;
};

const noop = () => {};

function WizardForm({ onSubmit = noop, nextStep = defaultNextStep, ...props }) {
  const wizardActions = useWizardActions();
  const wizardState = useWizardState();

  let _nextStep = nextStep;
  if (typeof nextStep === 'function') {
    _nextStep = nextStep(wizardState);
  }

  const finishWizard = () => {
    // If there are no more next steps, we can mark the Wizard as finished.
    if (!_nextStep) {
      wizardActions.finish();
    }
  };

  const handleSubmit = async (data) => {
    const result = await onSubmit(data);
    // Sync data to the wizard inventory if its valid form data (no errors
    // returned) and additional values are supplied. Once data is synced,
    // proceed to the next step, or if no more next steps exist, finish up.
    const hasErrors = result && result.errors;
    if (hasErrors) {
      return result;
    }

    if (result && result.values) {
      wizardActions.setInventory((inventory) => {
        return {
          ...inventory,
          ...result.values,
        };
      }, finishWizard); // After inventory is saved, attempt to finish.
    } else {
      finishWizard();
    }

    if (_nextStep && result !== false) {
      wizardActions.navigate(_nextStep);
    }
  };

  // Sync all form data changes with the wizard inventory
  const handleChange = (changed, values, name) => {
    wizardActions.setInventory((inventory) => {
      const value = getNestedValue(changed, name);
      const nextInventory = setNestedValue(inventory, name, value);
      return nextInventory;
    });
  };

  return (
    <Form
      {...props}
      initialValues={wizardState.inventory}
      onSubmit={handleSubmit}
      onChange={handleChange}
    />
  );
}

WizardForm.propTypes = {
  /** Can either be a string that should match the name value of a WizardStep
   * OR a function that returns a name value of a WizardStep. */
  nextStep: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** @ignore */
  onSubmit: PropTypes.func,
};

export default WizardForm;
