import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Checkbox from '../../Checkbox';
import { TranslateSlat } from '../TranslateSlat';
import stylesheet from './SlatGeneric.less';

/* Individual SlatGeneric */
const SlatGeneric = React.forwardRef(function SlatGeneric(
  { checkboxRef, children, onSelect, className, isSelected, ...props },
  forwardedRef,
) {
  const classList = cx(
    stylesheet.root,
    {
      [stylesheet.interactive]: !!onSelect,
    },
    className,
  );
  const { checkBoxLabelText } = TranslateSlat();
  return (
    <li className={classList} ref={forwardedRef} {...props}>
      <div className={stylesheet.wrapper}>
        {!!onSelect && (
          <span className={stylesheet.selection}>
            <Checkbox
              hideLabel
              label={checkBoxLabelText}
              value={isSelected}
              onChange={onSelect}
              ref={checkboxRef}
            />
          </span>
        )}
        {children}
      </div>
    </li>
  );
});

SlatGeneric.propTypes = {
  /** Pass the target ref on ClickArea to make the entire Slat clickable with preview of checkbox */
  checkboxRef: PropTypes.object,
  /** Content within SlatGeneric */
  children: PropTypes.node.isRequired,
  /** The current value of the selected SlatGeneric. Used with `onSelect` */
  isSelected: PropTypes.bool,
  /** Triggers when the SlatGeneric is selected. */
  onSelect: PropTypes.func,
};

export { SlatGeneric };
