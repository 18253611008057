import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuDownIcon } from '@mc/wink-icons';
import chainHandlers from '@mc/fn/chainHandlers';
import useId from '@mc/hooks/useId';
import Listbox from '../Listbox';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaLabelledByIds,
} from '../utils';
import emulateSelectKeyboardSearch from './emulateSelectKeyboardSearch';
import stylesheet from './SelectInline.less';
import { TranslateSelect } from './TranslateSelect';

const defaultRenderSelectedValue = (selected, label) => {
  const isMultiple = Array.isArray(selected);
  const isSelected = isMultiple ? selected.length > 0 : !!selected;

  if (!isSelected) {
    return label;
  }

  if (isMultiple) {
    const { multipleSelected } = TranslateSelect(selected.length);
    return selected.length > 1
      ? multipleSelected
      : selected.length === 1
      ? selected.map((v) => v.children || v.value)
      : label;
  }

  return selected.children;
};

const SelectInlineListboxTrigger = React.forwardRef(
  (
    {
      selected = [],
      placeholder,
      renderSelectedValue,
      isExpanded,
      filter,
      onFilterChange,
      options,
      onBlur,
      onHighlight,
      onSelect,
      onKeyDown,
      onToggle,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <React.Fragment>
        <span
          tabIndex={0}
          className={stylesheet.selectedValue}
          {...props}
          ref={forwardedRef}
          onKeyDown={chainHandlers(onKeyDown, (event) => {
            emulateSelectKeyboardSearch(event, {
              options,
              isExpanded,
              onHighlight,
              onSelect,
              onToggle,
            });
          })}
          onClick={onToggle}
          onBlur={onBlur}
        >
          {renderSelectedValue(selected, placeholder)}
          <span className={stylesheet.indicator}>
            <MenuDownIcon />
          </span>
        </span>
      </React.Fragment>
    );
  },
);

SelectInlineListboxTrigger.propTypes = {
  // Filters are only used with inputs.
  filter: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  // Filters are only used with inputs.
  onFilterChange: PropTypes.func,
  onHighlight: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.node,
  renderSelectedValue: PropTypes.func,
  selected: PropTypes.array,
};

const SelectInline = React.forwardRef(function SelectInline(
  {
    'aria-labelledby': ariaLabelledBy,
    className,
    children,
    // miscText isn't being used in this component yet, but in the meantime it needs
    // to be filtered out from the rest props passed to the DOM node.
    miscText,
    mode = 'native',
    multiple = false,
    disabled = false,
    readOnly = false,
    placeholder,
    hideLabel,
    label,
    onChange,
    value,
    renderSelectedValue = defaultRenderSelectedValue,
    ...props
  },
  forwardedRef,
) {
  const id = useId();
  const labelId = useId();

  let selected;
  React.Children.forEach(children, (child) => {
    if (child?.props.value === value) {
      selected = child.props;
    }
  });

  const isListbox = mode === 'listbox' || multiple;

  // We need to handle three cases:
  //
  // 1. Only pass a `label`. Native selects use a native label element, but
  //    Listbox isn't a native select so it must manually use `aria-labelledby`.
  // 2. Only pass an `aria-labelledby`. We don't render a label element.
  // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
  //    `aria-labelledby` attribute.
  const labelledBy = ariaLabelledByIds(
    ariaLabelledBy,
    (ariaLabelledBy || isListbox) && label && labelId,
  );

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.disabled]: disabled,
      })}
    >
      {label && (
        <label
          id={labelId}
          htmlFor={id}
          className={cx(hideLabel && 'wink-visually-hidden')}
        >
          {label}
        </label>
      )}
      {isListbox ? (
        <Listbox
          className={stylesheet.listbox}
          renderSelectedValue={renderSelectedValue}
          value={value}
          multiple={multiple}
          trigger={SelectInlineListboxTrigger}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          id={id}
          aria-labelledby={labelledBy}
          onChange={onChange}
          {...props}
        >
          {children}
        </Listbox>
      ) : (
        <React.Fragment>
          <select
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            aria-labelledby={labelledBy}
            onChange={(event) => onChange(event.target.value)}
            ref={forwardedRef}
            {...props}
          >
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {children}
          </select>
          <span
            data-testid="selectedValue"
            className={stylesheet.selectedValue}
            aria-hidden="true"
          >
            {renderSelectedValue(selected, placeholder)}
            <span className={stylesheet.indicator}>
              <MenuDownIcon />
            </span>
          </span>
        </React.Fragment>
      )}
    </div>
  );
});

SelectInline.propTypes = {
  /** @ignore Throwaway propType to do complex checks. */
  // eslint-disable-next-line react/no-unused-prop-types
  _: (props, propName, componentName) => {
    if (!props.label && !props['aria-labelledby']) {
      return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName));
    }
  },
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /** Should be children of the `Option` component*/
  children: PropTypes.node.isRequired,
  /** Makes the input unusable and un-clickable. */
  disabled: PropTypes.bool,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** The label of the select. */
  label: PropTypes.node,
  /** @ignore */
  miscText: PropTypes.any,
  /** Switches between the two modes:
   * Native: uses a native `<select>` including the native HTML options menu. This is the preferred mode for most Selects.
   * Listbox: Uses a custom implementation of an ARIA listbox. Useful for Selects that need complex options, such as
   * images or styling. Please avoid using listbox mode if your options are plain text.
   */
  mode: PropTypes.oneOf(['native', 'listbox']),
  /** Enables a multiselect. Two important notes about this prop:
   * 1. multiselect will render "listbox" mode even if you have not chosen it in the mode prop (native multiple select is a subpar experience).
   * 2. The value will always be cast to an array onChange so ensure you intend to work with an array (the value can also be null).
   */
  multiple: PropTypes.bool,
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: PropTypes.func.isRequired,
  /** Placeholder text when no value is selected */
  placeholder: PropTypes.string,
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly: PropTypes.bool,
  /** Override the default display of the selected value in the collapsed select.
   * Defaults to the children of the selected option for single selects, multi selects show a count of selected values. */
  renderSelectedValue: PropTypes.func,
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: PropTypes.any,
};

export default SelectInline;
