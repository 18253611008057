import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import stylesheet from './IconButton.less';

/**
 * A button that displays only an icon from `@mc/wink-icons`. Requires a
 * visually hidden label for assistive technology.
 */
const IconButton = React.forwardRef(function IconButton(
  {
    icon,
    label,
    className,
    href,
    disabled = false,
    htmlType = 'button',
    onClick,
    ...rest
  },
  forwardedRef,
) {
  const element = href ? 'a' : 'button';
  const props = {
    className: cx(stylesheet.root, className),
    disabled: disabled,
    onClick: onClick,
    ref: forwardedRef,
    ...rest,
  };

  if (element === 'button') {
    props.type = htmlType;
  }

  if (element === 'a' && props.target === '_blank') {
    props.rel = 'noreferrer noopener';
  }

  if (element === 'a') {
    // Disabled links are supposed to have no hrefs, so this is how we disable href while still respecting the existing prop
    if (props.disabled) {
      props.href = undefined;
      props.onClick = (event) => {
        event.preventDefault();
      };
    } else {
      props.href = href;
    }
  }

  return React.createElement(
    element,
    props,
    <React.Fragment>
      <span className="wink-visually-hidden">{label}</span>
      {icon}
    </React.Fragment>,
  );
});

IconButton.propTypes = {
  /** A custom class name that is appended to the button classes. */
  className: PropTypes.string,
  /** Makes the button unclickable */
  disabled: PropTypes.bool,
  /** The same URL string an `<a>` tag would expect. Defining this will convert the internal markup of Button to `<a>` (instead of `<button>`).
   * It is important to note that the acceptable props for a "link"-based button are inherently different when this happens.
   */
  href: (props) => {
    const invalidLinkProps = ['htmlType'];
    const invalidButtonProps = ['rel', 'target'];

    if (props.href) {
      const invalidProps = invalidLinkProps.filter((prop) => props[prop]);
      if (invalidProps.length > 0) {
        return new Error(
          'Invalid prop `href` supplied to `Button`. `href` cannot be used with ' +
            invalidProps.join(', ') +
            '. Validation failed.',
        );
      }
    } else {
      const invalidProps = invalidButtonProps.filter((prop) => props[prop]);
      if (invalidProps.length > 0) {
        return new Error(
          'Invalid prop `href` must be used with `Button` if ' +
            invalidProps.join(', ') +
            ' are also used. Validation failed.',
        );
      }
    }
  },
  /** Only applies to Buttons without `href`. While most HTML attributes of button are passed along. The "type" property is used to describe the button's variant type (see below). To accomodate this, we rename the 'type' attribute to 'htmlType'. */
  htmlType: PropTypes.oneOf(['submit', 'reset', 'button']),
  /**
   * Icon button will only take the icon component to render,
   * the component's name should match the one in wink-icon.
   * The whole syntax for rendering component needs to be included.
   * i.e. `<MenuRightIcon />`
   * The easiest way to verify the name is click the icon in
   * the library, check the component being called in the snippet.
   * https://designsystem.mailchimp.com/foundational/icons
   */
  icon: PropTypes.element.isRequired,
  /** The purpose of this button. This is relayed to assistive technology. */
  label: PropTypes.string.isRequired,
  /** Mouse click event */
  onClick: PropTypes.func,
  /** Only applies to buttons with `href`. Passed to `<a target>` */
  target: PropTypes.string,
};

export default IconButton;
