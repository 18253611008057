import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useInteropContext } from '../WinkStyles';
import Theme, { useThemeContext, useSubbrandContext } from '../Theme';
import ClassicPortal from '@mc/components/Portal';
import useId from '@mc/hooks/useId';

export const PortalStackContext = createContext();

/**
 * Wraps the Portal component's children with an interop wrapper, if needed.
 */
const Portal = ({ children, ...props }) => {
  const portalId = useId();
  const oldStack = useContext(PortalStackContext);
  const newStack = oldStack ? `${portalId}:${oldStack}` : portalId;

  const interopContext = useInteropContext();
  const theme = useThemeContext();
  const subbrand = useSubbrandContext();

  return (
    <PortalStackContext.Provider value={newStack}>
      <ClassicPortal portalStack={newStack} {...props}>
        <Theme theme={theme} subbrand={cx(interopContext, subbrand)}>
          {children}
        </Theme>
      </ClassicPortal>
    </PortalStackContext.Provider>
  );
};

Portal.propTypes = {
  children: PropTypes.node,
};

export default Portal;
