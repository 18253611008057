import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for Page default values
export const TranslatePage = () => {
  return {
    backText: useDsTranslateMessage({
      id: 'mcds.page.back_text',
      defaultMessage: 'Back',
    }),
    actionsText: useDsTranslateMessage({
      id: 'mcds.page.actions_text',
      defaultMessage: 'Actions',
    }),
    breadcrumbsText: useDsTranslateMessage({
      id: 'mcds.page.breadcrumbs_text',
      defaultMessage: 'Breadcrumbs',
    }),
  };
};
