import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './SwitcherLayout.less';

/**
 * `SwitcherLayout` puts all its children into equal columns on the same row. If
 * its container width falls under `minContainerWidth`, then `SwitcherLayout`
 * switches to a single-column layout.
 *
 * The columns don't have to be equal in width. You can define proportions by
 * setting `style={{ flexGrow: [number] }}` on the relevant columns.
 *
 * Based on the SwitcherLayout in every-layout.dev. If you don't have access to
 * every-layout.dev, the Flexbox Holy Albatross explains the concepts for free:
 * https://www.heydonworks.com/article/the-flexbox-holy-albatross-reincarnated
 */
const SwitcherLayout = React.forwardRef(function SwitcherLayout(
  {
    as: Component = 'div',
    children,
    className,
    gap = 2,
    minContainerWidth,
    ...props
  },
  forwardedRef,
) {
  // The reason `SwitcherLayout` is two elements (and the reason we do not
  // style `.switcher`) is to insulate the negative margin. Doing that enables
  // us to nest `SwitcherLayout`s. If we get rid of the wrapping `div`, then
  // the `SwitcherLayout` margins will clash with each other. This is the same
  // reason that `--measure` is set on `Component` and not the wrapping div.
  return (
    <div
      className={cx(stylesheet.switcher, className)}
      ref={forwardedRef}
      {...props}
    >
      <Component
        className={cx(gap && stylesheet[`spacing${gap}`])}
        style={{ '--measure': minContainerWidth }}
      >
        {children}
      </Component>
    </div>
  );
});

SwitcherLayout.propTypes = {
  /** The node used for the innermost element of SwitcherLayout. */
  as: PropTypes.any,
  children: PropTypes.node,
  /** Optional classes set on the root element of SwitcherLayout. */
  className: PropTypes.string,
  /** Controls spacing between children. Uses component spacing. */
  gap: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  /**
   * SwitcherLayout switches to a single-column layout if the container width
   * falls below this value. Pass a number with a unit, e.g. "80ch".
   */
  minContainerWidth: PropTypes.string,
};

export default SwitcherLayout;
