import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InteropContext from './InteropContext';

import './legacy-reverse-style.less';
import './WinkStyles.less';

const CONTEXT = 'wink';

/**
 * Intended only for use with `global-w-front-door.less`. It imports:
 *
 * 1. A reverse stylesheet to negate the legacy global stylesheet.
 * 2. Wink's global stylesheet, scoped under `.wink`.
 *
 * In the redesign, we add the global stylesheet to the `.wink` class,
 * which uses a reset stylesheet and sets certain properties, like margins and
 * fonts, on most elements. Folks may need to override Wink's global stylesheet
 * for application styles, but due to specificity, cannot. So, we have a PostCSS
 * plugin, `postcss-wink-prefix` to do that work for us.
 *
 * The `postcss-wink-prefix` PostCSS plugin automatically transforms CSS from:
 *
 * ```css
 * .a {}
 * ```
 *
 * to:
 *
 * ```css
 * .a,
 * :global(.wink) .a {}
 * ```
 *
 *This way, CSS we write work with both legacy and current global stylesheets.
 *
 * **NOTE:** This plugin will not transform selectors with `:global(.wink)` in
 * them. Transforms are also not applied to the `web/css/less` directory,
 * including `global-w-front-door.less`.
 */
const WinkStyles = ({
  as: Component = 'div',
  children,
  className,
  ...props
}) => {
  return (
    <InteropContext.Provider value={CONTEXT}>
      <Component {...props} className={cx(CONTEXT, className)}>
        {children}
      </Component>
    </InteropContext.Provider>
  );
};

WinkStyles.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
};

export default WinkStyles;
