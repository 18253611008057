import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

export const TranslateColorPicker = () => {
  return {
    redValueLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker.red_value_label_text',
      defaultMessage: 'Red value',
    }),
    blueValueLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker.blue_value_label_text',
      defaultMessage: 'Blue value',
    }),
    greenValueLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker.green_value_label_text',
      defaultMessage: 'Green value',
    }),
    hexColorCodeLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker.hex_color_code_label_text',
      defaultMessage: 'Hex color code',
    }),
    alphaLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker.alpha_value_label_text',
      defaultMessage: 'Alpha',
    }),
    colorValueTypeLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker.color_value_type_label_text',
      defaultMessage: 'Change color value type',
    }),
    defaultColorValueTypeText: useDsTranslateMessage({
      id: 'mcds.color_picker.default_color_value_type_text',
      defaultMessage: 'Hex',
    }),
    hexText: useDsTranslateMessage({
      id: 'mcds.color_picker.hex',
      defaultMessage: 'HEX',
    }),
    rgbText: useDsTranslateMessage({
      id: 'mcds.color_picker.rgb',
      defaultMessage: 'RGB',
    }),
    rgbaText: useDsTranslateMessage({
      id: 'mcds.color_picker.rgba',
      defaultMessage: 'RGBA',
    }),
  };
};

export const TranslateSwatches = (rgbValues) => {
  return {
    swatchesLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker_swatches.label_text',
      defaultMessage: 'Swatches',
    }),
    swatchesIconButtonLabelInitialText: useDsTranslateMessage({
      id: 'mcds.color_picker_swatches.icon_button_label_initial_text',
      defaultMessage: 'Add ',
    }),
    swatchesIconButtonRgbLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker_swatches.icon_button_rgb_label_text',
      defaultMessage: `red value of ${rgbValues.currentRedValue}, green value of ${rgbValues.currentGreenValue}, blue value of ${rgbValues.currentBlueValue} and alpha color of `,
      values: {
        currentRedValue: rgbValues.currentRedValue,
        currentGreenValue: rgbValues.currentGreenValue,
        currentBlueValue: rgbValues.currentBlueValue,
      },
    }),
    swatchesIconButtonLabelEndText: useDsTranslateMessage({
      id: 'mcds.color_picker_swatches.icon_button_label_end_text',
      defaultMessage: ' to swatches',
    }),
    swatchesTextButtonLabelText: useDsTranslateMessage({
      id: 'mcds.color_picker_swatches.text_button_label_text',
      defaultMessage: 'Remove',
    }),
  };
};
