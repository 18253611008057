import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';
import stylesheet from './FullHeightLayout.css';

export type FullHeightLayoutType = PropsWithChildren<{
  as?: React.ElementType;
  /** Set children to center align vertically and horizontally. */
  centered?: boolean;
}> & AllHTMLAttributes<unknown>;

/**
 * A component to create full-height layouts. This is typically used on the
 * outermost elements of the page so that they can expand to the full height of
 * their parent. The last child of `FullHeightLayout` automatically grows to fit
 * the remaining height. Children can overflow the parent.
 */
const FullHeightLayout = React.forwardRef<HTMLElement, FullHeightLayoutType>(function FullHeightLayout(
  { as: Component = 'div', children, className, centered = false, ...props },
  forwardedRef,
) {
  return (
    <Component
      className={cx(
        stylesheet.root,
        { [stylesheet.center]: centered },
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Component>
  );
});

export default FullHeightLayout;
