import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import chainHandlers from '@mc/fn/chainHandlers';
import FormContext from './FormContext';
import stylesheet from './ResetButton.less';

/**
 * Wrapper around the `Button` component. Clicking this button triggers the
 * `Form`'s `resetForm` hook.
 *
 * Props passed to `ResetButton` are applied to the inner `Button` component.
 */
function ResetButton({ onClick, valuesOnReset, ...props }) {
  const { resetForm } = useContext(FormContext);
  const handleClick = chainHandlers(onClick, () => resetForm(valuesOnReset));
  return (
    <Button
      {...props}
      className={stylesheet.button}
      onClick={handleClick}
      htmlType="reset"
    />
  );
}

ResetButton.propTypes = {
  onClick: PropTypes.func,
  valuesOnReset: PropTypes.object,
};

export default ResetButton;
