import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { RgbaColorPicker } from 'react-colorful';
import { colord } from 'colord';

import convertColors from '@mc/fn/convertColors';
import StackLayout from '../StackLayout';
import Input from '../Input';
import InputNumber from '../InputNumber';
import SelectInline from '../Select/SelectInline';
import Option from '../Select/Option';
import Swatches from './Swatches';
import { TranslateColorPicker } from './TranslateColorPicker';

import stylesheet from './ColorPicker.less';
// react-colorful style overrides
import './CustomPicker.less';

const VALUE_TYPE = {
  HEX: 'hex',
  RGB: 'rgb',
};

const HEX_REGEX = /^#?([0-9a-zA-Z]{3}(?:[0-9a-zA-Z]{3})?)$/;

const isInRange = (value, min, max) => {
  const num = parseInt(value, 10);
  if (isNaN(num)) {
    return false;
  }

  return num >= min && num <= max;
};

const foldNaN = (value, fallback) => {
  if (isNaN(parseInt(value, 10))) {
    return fallback;
  }
  return value;
};

const clamp = (value, min, max) => {
  const num = parseInt(value, 10);
  if (isNaN(num)) {
    return min;
  }
  return Math.min(max, Math.max(num, min));
};

const ColorPicker = React.forwardRef(function ColorPicker(
  {
    value = '#000000',
    swatches = [],
    showSelect = false,
    onChange,
    onSwatchesChange,
    valueType = 'hex',
    onValueTypeChange,
    hideAlpha = false,
    hideSwatches = false,
    handleTransparent = false,
  },
  forwardedRef,
) {
  // Convert color to object with all available format
  const color = convertColors(value.hex ?? value);
  const [colorValueType, setColorValueType] = useState(valueType);
  const {
    redValueLabelText,
    blueValueLabelText,
    greenValueLabelText,
    hexColorCodeLabelText,
    alphaLabelText,
    defaultColorValueTypeText,
    colorValueTypeLabelText,
    hexText,
    rgbText,
    rgbaText,
  } = TranslateColorPicker();
  // Extract RGBA/Hex values
  const rawHexValue = color.hex;
  const currentHexValue = rawHexValue.replace(/^#/, '');
  const currentRgbaValue = color.rgb;

  // Individual RGBA values
  const {
    r: currentRedValue,
    g: currentGreenValue,
    b: currentBlueValue,
    a: currentAlphaValue,
  } = currentRgbaValue;

  const [hexValue, setHexValue] = useState(currentHexValue);
  const [redValue, setRedValue] = useState(currentRedValue);
  const [greenValue, setGreenValue] = useState(currentGreenValue);
  const [blueValue, setBlueValue] = useState(currentBlueValue);
  const [alphaValue, setAlphaValue] = useState(
    (currentAlphaValue * 100).toFixed(0),
  );

  const hexInputRef = useRef();
  const redInputRef = useRef();
  const greenInputRef = useRef();
  const blueInputRef = useRef();
  const alphaInputRef = useRef();

  // Updates hex or rgb state when input is not focused.
  useEffect(() => {
    if (document.activeElement !== hexInputRef.current) {
      setHexValue(currentHexValue);
    }
    if (document.activeElement !== redInputRef.current) {
      setRedValue(currentRedValue.toString());
    }
    if (document.activeElement !== greenInputRef.current) {
      setGreenValue(currentGreenValue.toString());
    }
    if (document.activeElement !== blueInputRef.current) {
      setBlueValue(currentBlueValue.toString());
    }
    if (document.activeElement !== alphaInputRef.current) {
      setAlphaValue((currentAlphaValue * 100).toFixed(0));
    }
  }, [
    currentGreenValue,
    currentBlueValue,
    currentRedValue,
    currentAlphaValue,
    currentHexValue,
  ]);

  // Return object of all available formats for onChange color
  const handleChange = (data) => {
    onChange(convertColors(data));
  };

  const handleRedValue = (newRedVal) => {
    setRedValue(newRedVal);
    if (isInRange(newRedVal, 0, 255)) {
      handleChange({
        ...currentRgbaValue,
        r: newRedVal,
      });
    }
  };

  const handleGreenValue = (newGreenVal) => {
    setGreenValue(newGreenVal);
    if (isInRange(newGreenVal, 0, 255)) {
      handleChange({
        ...currentRgbaValue,
        g: newGreenVal,
      });
    }
  };

  const handleBlueValue = (newBlueValue) => {
    setBlueValue(newBlueValue);
    if (isInRange(newBlueValue, 0, 255)) {
      handleChange({
        ...currentRgbaValue,
        b: newBlueValue,
      });
    }
  };

  const handleAlphaValue = (newAlpha) => {
    setAlphaValue(newAlpha);
    if (isInRange(newAlpha, 0, 100)) {
      handleChange({
        ...currentRgbaValue,
        a: newAlpha / 100,
      });
    }
  };

  const handleHexValue = (newHex) => {
    setHexValue(newHex);
    if (HEX_REGEX.test(newHex)) {
      handleChange({
        ...colord(`#${newHex}`).toRgb(),
        ...(!handleTransparent && { a: currentAlphaValue }),
      });
    }
  };

  const handleValueTypeChange = (value_type) => {
    if (onValueTypeChange) {
      onValueTypeChange(value_type);
    }
    setColorValueType(value_type);
  };

  return (
    <div className={stylesheet.root} ref={forwardedRef}>
      <div className={cx('colorSpace', { hideAlpha: hideAlpha })}>
        <RgbaColorPicker
          color={{
            ...color.rgb,
            /**
             * If alpha selection is hidden and the selected color is 100%
             * transparent, override the alpha value.
             *
             * This is needed by the email editor to support an always-present
             * transparent background option.
             */
            a: hideAlpha && color.rgb.a === 0 ? 1 : color.rgb.a,
          }}
          onChange={handleChange}
        />
      </div>

      <StackLayout gap={2} className={stylesheet.colorTypeSwitcher}>
        <div>
          {showSelect ? (
            <SelectInline
              className={stylesheet.label}
              value={colorValueType}
              placement="bottom-start"
              type="secondary"
              onChange={handleValueTypeChange}
              label={colorValueTypeLabelText}
              hideLabel
            >
              <Option value={VALUE_TYPE.HEX}>{hexText}</Option>
              <Option value={VALUE_TYPE.RGB}>
                {!hideAlpha ? rgbaText : rgbText}
              </Option>
            </SelectInline>
          ) : (
            <span
              className="mcds-label-default"
              onClick={() => hexInputRef.current.focus()}
            >
              {defaultColorValueTypeText}:
            </span>
          )}
        </div>
        <div className={cx(stylesheet.inputGroup)}>
          <div className={stylesheet.checkerBg}>
            <div
              className={stylesheet.colorPreview}
              style={{
                backgroundColor: color.rgbString,
              }}
            ></div>
          </div>
          {colorValueType === VALUE_TYPE.RGB ? (
            <div className={stylesheet.rgbContainer}>
              <InputNumber
                value={redValue.toString()}
                ref={redInputRef}
                onChange={handleRedValue}
                onBlur={() => {
                  // Clamp red value between 0 and 255.
                  const val = foldNaN(redValue, currentRedValue);
                  handleRedValue(String(clamp(val, 0, 255)));
                }}
                className={stylesheet.input}
                maxLength={3}
                hideLabel={true}
                label={redValueLabelText}
              />
              <InputNumber
                value={greenValue.toString()}
                ref={greenInputRef}
                onChange={handleGreenValue}
                onBlur={() => {
                  // Clamp green value between 0 and 255.
                  const val = foldNaN(greenValue, currentGreenValue);
                  handleGreenValue(clamp(val, 0, 255));
                }}
                className={stylesheet.input}
                maxLength={3}
                hideLabel={true}
                label={greenValueLabelText}
              />
              <InputNumber
                value={blueValue.toString()}
                ref={blueInputRef}
                onChange={handleBlueValue}
                onBlur={() => {
                  // Clamp blue value between 0 and 255.
                  const val = foldNaN(blueValue, currentBlueValue);
                  handleBlueValue(clamp(val, 0, 255));
                }}
                className={stylesheet.input}
                maxLength={3}
                hideLabel={true}
                label={blueValueLabelText}
              />
            </div>
          ) : (
            <Input
              // Outputs in #rrggbbaa. This removes alpha values.
              value={hexValue.substring(0, 6)}
              prefixText="#"
              ref={hexInputRef}
              onChange={handleHexValue}
              onBlur={() => {
                // Changes hex to last-known value if input is invalid
                setHexValue(currentHexValue);
              }}
              onPaste={(event) => {
                const paste = event.clipboardData?.getData?.('text') || '';
                // Strip hash from the start of the pasted text if applicable.
                // Ensure the hex (minus the hash) matches the input maxLength.
                if (
                  paste.startsWith('#') &&
                  paste.length - 1 <= hexInputRef.current.maxLength
                ) {
                  event.preventDefault();
                  handleHexValue(paste.replace(/^#/, ''));
                }
              }}
              className={cx(stylesheet.input, stylesheet.inputHex)}
              maxLength={6}
              label={hexColorCodeLabelText}
              hideLabel={true}
            />
          )}
          {!hideAlpha && (
            <InputNumber
              value={alphaValue}
              suffixText="%"
              ref={alphaInputRef}
              onChange={handleAlphaValue}
              onBlur={() => {
                // Clamp alpha value between 0 and 100.
                const val = foldNaN(
                  alphaValue,
                  (currentAlphaValue * 100).toFixed(0),
                );
                handleAlphaValue(String(clamp(val, 0, 100)));
              }}
              className={cx(stylesheet.input, stylesheet.inputAlpha)}
              maxLength={3}
              label={alphaLabelText}
              hideLabel={true}
            />
          )}
        </div>
      </StackLayout>

      {!hideSwatches && (
        <Swatches
          onChange={handleChange}
          onSwatchesChange={onSwatchesChange}
          swatches={swatches}
          colorValueType={colorValueType}
          rawHexValue={rawHexValue}
          currentRgbaValue={currentRgbaValue}
        />
      )}
    </div>
  );
});

ColorPicker.propTypes = {
  /** Handle transparent swatch value */
  handleTransparent: PropTypes.bool,
  /** If true, the alpha input will be hidden. */
  hideAlpha: PropTypes.bool,
  /** If true, the list of swatches will be hidden. */
  hideSwatches: PropTypes.bool,
  /**  Called when the color changes. The first argument is an object of `{r, g, b, a}`. The RGB values are integers ranging from 0-255 and A is a float ranging from 0-1. */
  onChange: PropTypes.func.isRequired,
  /** Called when swatches are added or removed. The first argument is the updated array of swatches. */
  onSwatchesChange: PropTypes.func,
  /** Called whenever the color format is changed */
  onValueTypeChange: PropTypes.func,
  /** If true, a control to change the color format (Hex or RGB) will be shown. */
  showSelect: PropTypes.bool,
  /** The array of swatches to display inside the Color Picker. The format is the same as `value`'s. */
  swatches: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  /** The color picker's current color value. This value will get normalized by `onChange`. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** The default color format to display. */
  valueType: PropTypes.oneOf(['hex', 'rgb']),
};

export default ColorPicker;
