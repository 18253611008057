import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';
import useId from '@mc/hooks/useId';
import chainHandlers from '@mc/fn/chainHandlers';
import Popup from '../Popup';
import ButtonOrLink from '../ButtonOrLink';
import Option from './Option';
import stylesheet from './Listbox.less';
import { TranslateListbox } from './TranslateListbox';

const ListboxModeContext = createContext(false);
const ListboxOptgroup = ({ children, disabled, label }) => {
  const id = useId();
  return (
    <div
      role="group"
      aria-labelledby={id}
      aria-disabled={disabled}
      className={stylesheet.optgroupWrapper}
    >
      <div id={id} className={stylesheet.optgroup}>
        {label}
      </div>
      {children}
    </div>
  );
};

ListboxOptgroup.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

const defaultRenderSelectedValue = (selected, placeholder) => {
  return selected.length > 1
    ? `${selected.length} selected`
    : selected.length === 1
    ? selected.map((v) => v.children || v.value)
    : placeholder;
};

const defaultOptionsFilter = (value, option) => {
  return !!value && !option.toLowerCase().includes(value.toLowerCase());
};

/**
 * Listbox is a primitive component used to power other design system components.
 * It should *NEVER* be used directly in app code.
 */
const Listbox = React.forwardRef(function Listbox(
  {
    callToActionOnClick,
    callToActionHref,
    callToActionLabel,
    value,
    onChange,
    trigger: Trigger,
    emptyOption,
    placeholder,
    multiple = false,
    matchTargetWidth = false,
    renderSelectedValue = defaultRenderSelectedValue,
    className,
    onSearch = () => {},
    onOpen = () => {},
    optionsFilter = defaultOptionsFilter,
    children,
    disabled = false,
    isPopupFixed,
    ...props
  },
  forwardedRef,
) {
  // Translate default values
  const { noOptionsText, placeholderText } = TranslateListbox();
  emptyOption = emptyOption || noOptionsText;
  placeholder = placeholder || placeholderText;

  const [isExpanded, setIsExpanded] = useState(false);
  const [filter, setFilter] = useState();
  const [highlightedValue, setHighlightedValue] = useState(
    multiple && value && value.length > 0 ? value[0] : value,
  );
  const id = useId();
  const listboxRef = useRef();
  const containerRef = useRef();
  const ctaRef = useRef();
  const onOpenCalled = useRef();
  const allOptions = [];
  const enabledOptions = [];
  const cloned = [];

  const _onSearch = useCallback(onSearch, []);

  useEffect(() => {
    if (!onOpenCalled.current && isExpanded) {
      onOpen();
      onOpenCalled.current = true;
    }
    if (!isExpanded) {
      onOpenCalled.current = false;
    }
  }, [isExpanded, onOpen]);

  useEffect(() => {
    if (!disabled) {
      if (filter && filter.length > 0 && !isExpanded) {
        setIsExpanded(true);
      }

      if (_onSearch) {
        _onSearch(filter);
      }
    } else if (isExpanded) {
      setIsExpanded(false);
    }
  }, [filter, isExpanded, disabled, _onSearch]);
  const handleSelect = (selectedValue) => {
    if (multiple) {
      // Preserve the highlighted value after the selection
      setHighlightedValue(selectedValue);

      if (!value) {
        onChange([selectedValue]);
      } else {
        const newValues = allOptions
          .filter((option) => {
            return (
              // adds and removes the clicked on child
              (option.value === selectedValue &&
                !value.includes(selectedValue)) ||
              // keep elements that already been selected
              (option.value !== selectedValue && option.isSelected)
            );
          })
          .map((option) => option.value);
        onChange(newValues);
      }
    } else {
      onChange(selectedValue);
      setIsExpanded(false);
    }

    setFilter();
  };

  // This loop determines a few things in a single iteration of each option:
  //
  // 1. create array to manage traversing/navigating the options
  // 2. the selected item (if one is selected)
  // 3. the highlighted item (used when navigating the options with keyboard)
  // 4. enabled/disabled logic
  // 5. clone children for display
  let index = 0;
  React.Children.forEach(children, (child) => {
    if (!child) {
      return;
    }
    const options =
      child.type === 'optgroup'
        ? React.Children.toArray(child.props.children)
        : [child];

    let hasUnfilteredOptions = child.type !== 'optgroup' || !filter;

    const currentIndex = index;
    // 1. create array to manage traversing/navigating the options
    options.forEach((option) => {
      // 2. the selected item
      const isSelected = multiple
        ? !value
          ? false
          : value.includes(option.props.value)
        : option.props.value === value
        ? true
        : // Do not output aria-selected="false" for single-select comboboxes.
          undefined;

      let filterText;
      if (option.props.label) {
        filterText = option.props.label;
      } else if (typeof option.props.children === 'string') {
        filterText = option.props.children;
      } else if (option.type !== ListboxOptgroup) {
        throw new Error(
          'Options of a searchable listbox must have string children or a label prop',
        );
      }

      const isFiltered = optionsFilter(filter, filterText);
      // 3. the highlighted item
      const isHighlighted =
        !isFiltered && option.props.value === highlightedValue;
      // 4. enabled/disabled logic
      const isDisabled =
        (child.type === 'optgroup' && child.props.disabled) ||
        option.props.disabled;
      const isEnabled =
        option.type !== ListboxOptgroup && !isDisabled && !isFiltered;

      if (!isFiltered && child.type === 'optgroup') {
        hasUnfilteredOptions = true;
      }

      const optionProps = {
        ...option.props,
        key: option.props.value,
        id: id + '-' + option.props.value,
        disabled: isDisabled,
        isSelected,
        isHighlighted,
        isFiltered,
        onHighlight: setHighlightedValue,
        onClick: () => {
          if (isEnabled) {
            handleSelect(option.props.value);
            setIsExpanded(multiple);
          }
        },
      };

      allOptions.push(optionProps);
      if (isEnabled) {
        enabledOptions.push(optionProps);
      }

      index += 1;
    });

    // 5. Clone children for display
    if (child.type === 'optgroup') {
      if (hasUnfilteredOptions) {
        const { children: childChildren, ...childProps } = child.props;
        cloned.push(
          <ListboxOptgroup {...childProps} key={childProps.label}>
            {React.Children.map(childChildren, (childChild, childIndex) => {
              return React.cloneElement(
                childChild,
                allOptions[currentIndex + childIndex],
              );
            })}
          </ListboxOptgroup>,
        );
      }
    } else {
      cloned.push(React.cloneElement(child, allOptions[currentIndex]));
    }
  });

  const handleKeyDown = (event) => {
    if (disabled) {
      return;
    }

    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (isExpanded) {
          // If using filtered options, select either the highlighted value or the originally selected value
          if (filter) {
            const selectedByFilter =
              enabledOptions.find(
                (option) => option.value === highlightedValue,
              ) || enabledOptions[0];

            if (selectedByFilter) {
              handleSelect(selectedByFilter.value);
            }
          } else {
            handleSelect(highlightedValue);
          }
        } else {
          setIsExpanded(true);
        }
        break;

      case 'Escape':
        if (isExpanded) {
          event.preventDefault();
          setIsExpanded(false);
        }
        break;

      case 'ArrowUp':
        event.preventDefault();
        if (isExpanded) {
          const highlightedIndex = enabledOptions.findIndex(
            (option) => option.isHighlighted,
          );
          const prev =
            (highlightedIndex - 1 + enabledOptions.length) %
            enabledOptions.length;
          setHighlightedValue(enabledOptions[prev].value);
        } else {
          setIsExpanded(true);
        }
        break;

      case 'ArrowDown':
        event.preventDefault();
        if (isExpanded) {
          const highlightedIndex = enabledOptions.findIndex(
            (option) => option.isHighlighted,
          );
          const next = (highlightedIndex + 1) % enabledOptions.length;
          setHighlightedValue(enabledOptions[next].value);
        } else {
          setIsExpanded(true);
        }
        break;

      case 'Home':
        if (isExpanded) {
          event.preventDefault();
          setHighlightedValue(enabledOptions[0].value);
        }
        break;

      case 'End':
        if (isExpanded) {
          event.preventDefault();
          setHighlightedValue(enabledOptions[enabledOptions.length - 1].value);
        }
        break;

      // Emulating native select behavior
      // Options being open prevent tabs from changing focus
      case 'Tab':
        if (isExpanded && filter === undefined) {
          if (callToActionLabel && !event.shiftKey) {
            event.preventDefault();
            ctaRef.current.focus();
            setIsExpanded(true);
          }
        }
        break;

      default:
        break;
    }
  };

  const handleCtaKeyDown = (event) => {
    if (event.key === 'Tab' && !event.shiftKey) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    if (isExpanded && listboxRef.current) {
      const firstSelectedValue = listboxRef.current.querySelector(
        '[aria-selected=true]',
      );
      if (firstSelectedValue) {
        scrollIntoView(firstSelectedValue, {
          block: 'nearest',
          scrollMode: 'if-needed',
          boundary: listboxRef.current,
        });
      }
    }
  }, [isExpanded]);

  return (
    <div className={cx(stylesheet.container, className)} ref={containerRef}>
      <Trigger
        {...props}
        disabled={disabled}
        isExpanded={isExpanded}
        placeholder={placeholder}
        renderSelectedValue={renderSelectedValue}
        filter={filter}
        onFilterChange={setFilter}
        options={enabledOptions}
        selected={enabledOptions.filter((option) => option.isSelected)}
        id={id + '-trigger'}
        role="combobox"
        aria-autocomplete={filter ? 'list' : 'none'}
        aria-haspopup="listbox"
        aria-controls={isExpanded ? id : undefined}
        aria-expanded={isExpanded}
        aria-activedescendant={
          isExpanded ? `${id}-${highlightedValue}` : undefined
        }
        onHighlight={setHighlightedValue}
        onSelect={handleSelect}
        onToggle={() => setIsExpanded((prev) => (disabled ? false : !prev))}
        onKeyDown={chainHandlers(props.onKeyDown, handleKeyDown)}
        onBlur={chainHandlers(props.onBlur, () => setIsExpanded(false))}
        ref={forwardedRef}
      />
      {isExpanded && (
        <Popup
          matchTargetWidth={matchTargetWidth}
          targetRef={containerRef}
          className={stylesheet.popup}
          fixed={isPopupFixed}
        >
          <div
            className={stylesheet.listbox}
            // preventDefault stops the trigger from losing focus. It also stops
            // an optgroup from collapsing the listbox.
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            <div
              className={stylesheet.options}
              ref={listboxRef}
              role="listbox"
              id={id}
            >
              <ListboxModeContext.Provider value={true}>
                {!!filter && enabledOptions.length === 0 ? (
                  <Option disabled>{emptyOption}</Option>
                ) : (
                  cloned
                )}
              </ListboxModeContext.Provider>
            </div>
            {callToActionLabel && (
              <div className={stylesheet.callToAction}>
                <ButtonOrLink
                  ref={ctaRef}
                  href={callToActionHref}
                  onClick={callToActionOnClick}
                  onKeyDown={handleCtaKeyDown}
                >
                  {callToActionLabel}
                </ButtonOrLink>
              </div>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
});

Listbox.propTypes = {
  'aria-labelledby': PropTypes.string,
  'aria-describedby': PropTypes.string,
  callToActionHref: PropTypes.string,
  callToActionLabel: PropTypes.string,
  callToActionOnClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  emptyOption: PropTypes.string,
  isPopupFixed: PropTypes.bool,
  matchTargetWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  onOpen: PropTypes.func,
  onSearch: PropTypes.func,
  optionsFilter: PropTypes.func,
  placeholder: PropTypes.node,
  renderSelectedValue: PropTypes.func,
  trigger: PropTypes.elementType,
  value: PropTypes.any,
};

export { ListboxModeContext };

export default Listbox;
