import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  NotAllowedIcon,
  TickCircleIcon,
  WarningCircleIcon,
  InfoCircleIcon,
} from '@mc/wink-icons';
import ClusterLayout from '../ClusterLayout';
import Text from '../Text';

import stylesheet from './InlineFeedback.less';

/**
 * @deprecated Use the FeedbackBlock component from mc/wink instead.
 *
 */
const InlineFeedback = React.forwardRef(function InlineFeedback(
  { children, headline, type, className },
  forwardedRef,
) {
  const feedbackStyles = cx(
    stylesheet.inlineFeedback,
    stylesheet[type],
    className,
  );

  return (
    <ClusterLayout ref={forwardedRef} nowrap={true} className={feedbackStyles}>
      <div className={stylesheet.icon}>
        {type === 'success' && <TickCircleIcon />}
        {type === 'warning' && <WarningCircleIcon />}
        {type === 'error' && <NotAllowedIcon />}
        {type === 'info' && <InfoCircleIcon />}
      </div>

      <div>
        <Text appearance="medium-bold">{headline}</Text>
        {children && <Text appearance="medium">{children}</Text>}
      </div>
    </ClusterLayout>
  );
});

InlineFeedback.propTypes = {
  /** Body copy */
  children: PropTypes.node,
  /** optional class name */
  className: PropTypes.string,
  /** Title copy */
  headline: PropTypes.string.isRequired,
  /** Feedback variants that also alter background color */
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default InlineFeedback;
