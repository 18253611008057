import React, { useCallback, useRef } from 'react';
import ClickArea from './ClickArea';

function useClickArea() {
  const targetRef = useRef();
  const Component = useCallback(
    (props) => <ClickArea targetRef={targetRef} {...props} />,
    [targetRef],
  );
  return [Component, targetRef];
}

export default useClickArea;
