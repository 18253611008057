import React from 'react';
import PropTypes from 'prop-types';
import stylesheet from './ToastTunnel.less';

/** Toast wrapper for accessibility purposes.
 * This component is used to create a portal for the Toast component.
 * ToastTunnel will render an ARIA live region to announce messages that dynamically change to screen readers.
 * Ref: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
 */
const ToastTunnel = React.forwardRef(function ToastTunnel(
  { id = 'portal-notification', role = 'status' },
  forwardedRef,
) {
  return (
    <div
      className={stylesheet.root}
      id={id}
      role={role}
      aria-live={role === 'alert' ? 'assertive' : 'polite'}
      ref={forwardedRef}
    />
  );
});

ToastTunnel.propTypes = {
  /** Default `id` is configured and used within `Main/core/Shell.js`.
   * If you are not using the classic or redesign shell, you may create a
   * custom portal by importing this component and setting your own custom id. */
  id: PropTypes.string,
  /** Default `role` is `status`. If the information passed is time sensitive, use the 'alert' role. */
  role: PropTypes.oneOf(['alert', 'status']),
};

export default ToastTunnel;
