import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ContentArea from '../ContentArea';
import Heading from '../Heading';
import SwitcherLayout from '../SwitcherLayout';
import StackLayout from '../StackLayout';
import stylesheet from './Hero.less';

const DefaultLayout = (props) => {
  return <SwitcherLayout gap={8} {...props} />;
};

const Hero = React.forwardRef(function Hero(
  {
    variant,
    title,
    size,
    secondary,
    children,
    layout: Layout = DefaultLayout,
    textWrap,
  },
  forwardedRef,
) {
  return (
    <div
      className={cx(
        stylesheet.root,
        secondary && stylesheet.withSecondary,
        variant && stylesheet[variant],
      )}
      ref={forwardedRef}
    >
      <ContentArea size={size} className={stylesheet.content}>
        <Layout>
          <StackLayout gap={6} style={{ flexGrow: 2, alignSelf: 'center' }}>
            <Heading level={2} appearance="heading-1" textWrap={textWrap}>
              {title}
            </Heading>
            {children}
          </StackLayout>
          {secondary && (
            <div className={stylesheet.secondaryContent} style={{ order: 1 }}>
              {secondary}
            </div>
          )}
        </Layout>
      </ContentArea>
    </div>
  );
});

Hero.propTypes = {
  /** The content of the hero. Most commonly it should be one or more `Text` components and a row of `Button`
   * components for calls to action. */
  children: PropTypes.node.isRequired,
  /** Use this to customize the SwitcherLayout */
  layout: PropTypes.elementType,
  /** This will normally be an image */
  secondary: PropTypes.node,
  /** Most pages should use standard (which is the default). Use wide for full width sizes, use narrow for tightly constrained content.  */
  size: PropTypes.oneOf(['standard', 'narrow', 'wide']),
  /** Wrap formatting for the heading */
  textWrap: PropTypes.oneOf(['wrap', 'nowrap', 'balance', 'pretty']),
  /** This will be an h2 that has an h1 appearance. */
  title: PropTypes.node.isRequired,
  /** A background color matching one of the allowed brand colors. */
  variant: PropTypes.oneOf([
    'coconut',
    'cornflower',
    'cucumber',
    'radish',
    'squash',
    'yam',
    'jasmine',
  ]),
};

export default Hero;
