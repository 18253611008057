import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useId from '@mc/hooks/useId';
import { TranslateForm } from '../Form/TranslateForm';
import { ariaDescribedByIds } from '../utils';

import stylesheet from './Fieldset.less';

/** Wrapper for the `<fieldset>` element along with data handling mechanisms. */
const Fieldset = React.forwardRef(function Fieldset(
  {
    'aria-describedby': ariaDescribedBy,
    className,
    children,
    helpText,
    legend,
    required = false,
    ...props
  },
  forwardedRef,
) {
  const helpTextId = useId();
  const requiredId = useId();

  // Translate default text
  const { requiredText } = TranslateForm();

  return (
    <fieldset
      ref={forwardedRef}
      {...props}
      aria-describedby={ariaDescribedByIds(
        ariaDescribedBy,
        helpText && helpTextId,
        required && requiredId,
      )}
      className={cx(stylesheet.fieldset, className)}
    >
      <legend className="mcds-label-default">{legend}</legend>
      {helpText && <div id={helpTextId}>{helpText}</div>}
      {required && (
        <div id={requiredId} className={stylesheet.required}>
          {requiredText}
        </div>
      )}

      {children}
    </fieldset>
  );
});

Fieldset.propTypes = {
  /** Pass an element's ID to include its text content as part of this component's accessible description, alongside helpText. */
  'aria-describedby': PropTypes.string,
  /** Should contain one or more Radios. */
  children: PropTypes.node.isRequired,
  /** Class name applied to the fieldset attribute */
  className: PropTypes.string,
  /** Optional help text; becomes the fieldset's `aria-describedby`. */
  helpText: PropTypes.node,
  /** Passed to the legend of the fieldset */
  legend: PropTypes.node.isRequired,
  /** Required label for groups of inputs like Radio or Checkbox */
  required: PropTypes.bool,
};

export default Fieldset;
