import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';

import stylesheet from './StackLayout.less';

export type StackLayoutProps = PropsWithChildren<{
  as?: React.ElementType;
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
}> & AllHTMLAttributes<unknown>;
//We are not sure what value of `as` will be passed in here, 
//so I decided to use AllHTMLAttributes<unknown> here. 

/**
 * `StackLayout` stacks **block-level** elements.
 *
 * All children are stacked in a single column. Inline elements get stretched
 * and become blocks. If you wish to avoid this, wrap inline elements with
 * another element, like a `<div>`.
 */
const StackLayout = React.forwardRef<HTMLElement, StackLayoutProps>(
  function StackLayout(
    { as: Component = 'div', children, className, gap = 4, ...props },
    forwardedRef,
  ) {
    // Implements the `StackLayout` on every-layout.dev:
    // https://every-layout.dev/layouts/stack
    //
    // Note that there is a bug in every-layout's version, which uses CSS
    // variables: Nested `StackLayout`s override the CSS variable of its parent,
    // causing unwanted `gap` values.
    return (
      <Component
        className={cx(
          stylesheet.stack,
          gap && stylesheet[`spacing${gap}`],
          className,
        )}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </Component>
    );
  },
);

export default StackLayout;
