import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CrossIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

import stylesheet from './Tag.less';
import ButtonOrLink from '../ButtonOrLink';

const Tag = React.forwardRef(function Tag(
  { appearance, className, children, href, onClick, size = 'large', ...props },
  ref,
) {
  const isInteractive = onClick || href;
  const TagType = isInteractive ? ButtonOrLink : 'span';
  const removeId = useId();

  // Translation for default text
  const removeTagText = useDsTranslateMessage({
    id: 'mcds.tag.remove_tag_text',
    defaultMessage: 'Remove Tag',
  });

  return (
    <TagType
      ref={ref}
      className={cx(
        stylesheet.root,
        appearance === 'dismissible' && stylesheet.dismissible,
        isInteractive && stylesheet.interactive,
        stylesheet[size],
        className,
      )}
      onClick={onClick}
      href={href}
      aria-describedby={appearance === 'dismissible' ? removeId : undefined}
      {...props}
    >
      {children}
      {appearance === 'dismissible' && (
        <React.Fragment>
          <CrossIcon className={stylesheet.crossIcon} />
          <span hidden id={removeId}>
            {removeTagText}
          </span>
        </React.Fragment>
      )}
    </TagType>
  );
});

Tag.propTypes = {
  /** Changes the Tag's appearance (if needed) */
  appearance: PropTypes.oneOf(['dismissible']),
  /** The text contents of the tags */
  children: PropTypes.node.isRequired,
  /** If defined, the tag will be a link */
  href: PropTypes.string,
  /** If defined, and no href is defined, the tag will be a button */
  onClick: PropTypes.func,
  /** The size of the tags */
  size: PropTypes.oneOf(['small', 'large', 'x-large']),
};

export default Tag;
