/**
 * When the Dialog is opened, most HTML elements on the page have the 'inert' attribute added to them, making them
 * noninteractive while the Dialog/Modal is opened. The array of selectors in this file targets elements that should be
 * omitted from becoming inert. In general, we should be stringent about how many elements we want to include here
 * as this tends to fly in the face of accessibility best practices, but if you find a legitimate use case for an element
 * that you feel the user should be able to interact with while a Dialog/Modal is opened, add the selector below
 */

// Include CSS-queryable IDs, class names, etc. of elements to exclude from becoming inert when Dialog/Modal is opened;
export const inertExcludeSelectors = ['#ada-entry'];
