import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Card, { CardImage } from '../Card';
import StackLayout from '../StackLayout';
import Text from '../Text';

import stylesheet from './ActionCard.less';

const ActionCard = React.forwardRef(function ActionCard(
  {
    alt,
    src,
    title,
    description,
    cta,
    position,
    isVertical,
    variant = 'cornflower',
  },
  forwardedRef,
) {
  const linkRef = useRef();

  return (
    <Card
      ref={forwardedRef}
      className={cx(stylesheet.root, { [stylesheet.isVertical]: isVertical })}
    >
      <CardImage
        appearance="basic"
        src={src}
        variant={variant}
        className={stylesheet.imgWrapper}
        position={position}
        alt={alt}
      />
      <div className={stylesheet.content}>
        <StackLayout gap={0}>
          <Text appearance="heading-3">{title}</Text>
          <Text
            appearance="medium-secondary"
            className={stylesheet.description}
          >
            {description}
          </Text>
          {React.cloneElement(cta, {
            ref: linkRef,
            className: cx(
              { [stylesheet.targetButton]: cta?.type === 'button' },
              stylesheet.targetLink,
            ),
          })}
        </StackLayout>
      </div>
    </Card>
  );
});

ActionCard.propTypes = {
  /** Card image alt text. */
  alt: PropTypes.string,
  /** Call-to-action text for Card. */
  cta: PropTypes.node,
  /** Description for Card. */
  description: PropTypes.string,
  /** Vertical orientation for action card. */
  isVertical: PropTypes.bool,
  /** Position placement for image. */
  position: PropTypes.shape({
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
  }),
  /** Source for card image. */
  src: PropTypes.string,
  /** Title for card. */
  title: PropTypes.string,
  /** A background color matching one of the allowed brand colors. */
  variant: PropTypes.oneOf([
    'coconut',
    'cornflower',
    'cucumber',
    'radish',
    'squash',
    'yam',
    'jasmine',
  ]),
};

export default ActionCard;
