import React from 'react';
import PropTypes from 'prop-types';

function TabPanels({ children }) {
  return (
    // This React.Fragment forces react-docgen to scrape this component
    // https://github.com/reactjs/react-docgen/issues/70
    <React.Fragment>
      {React.Children.map(children, (child, index) => {
        if (child === null) {
          return null;
        }
        return React.cloneElement(child, { index });
      })}
    </React.Fragment>
  );
}

TabPanels.propTypes = {
  children: PropTypes.node,
};

export default TabPanels;
