/**
 * Returns a formatted error message for a propType.
 *
 * @param {String} format A string to be formatted. Use {0}, {1}, etc.
 * @param  {...String} args Positional strings to be injected into the format string.
 */
export function formatError(format, ...args) {
  return format.replace(/{(\d+)}/g, function(match, number) {
    return args[number] ?? match;
  });
}

export const ERROR_MUST_PROVIDE_LABEL =
  '[Wink] `{0}` must have a label for users of assistive technology. The' +
  " label describes a control. Without a label, screen reader users don't" +
  ' know what the control operates and voice navigation users cannot interact' +
  ' with the control. This is a major accessibility violation. You can:\n\n' +
  '  1. Pass a `label`. Use `hideLabel` to visually hide the label.\n' +
  '  2. Or pass an `aria-labelledby`.\n\n' +
  'Talk to a content strategist or #accessibility if you have any questions.\n';

/**
 * Given an array of IDs to DOM elements, constructs a value for an
 * `aria-labelledby` attribute.
 *
 * @param  {...String} ids
 * @returns {String|undefined} A space-separated string of IDs or undefined if
 * none.
 */
export const ariaLabelledByIds = (...ids) => {
  // Return `undefined` instead of `''`.
  return ids.filter(Boolean).join(' ') || undefined;
};

/**
 * Given an array of IDs to DOM elements, constructs a value for an
 * `aria-describedby` attribute.
 *
 * @param  {...String} ids
 * @returns {string|undefined} A space-separated string of IDs or undefined if
 * none.
 */
export const ariaDescribedByIds = (...ids) => {
  // Return `undefined` instead of `''`.
  return ids.filter(Boolean).join(' ') || undefined;
};
