import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for Listbox default values
export const TranslateListbox = () => {
  return {
    noOptionsText: useDsTranslateMessage({
      id: 'mcds.listbox.no_options_text',
      defaultMessage: 'No options found',
    }),
    placeholderText: useDsTranslateMessage({
      id: 'mcds.listbox.placeholder_text',
      defaultMessage: 'Choose...',
    }),
  };
};
