import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Fieldset from '../Fieldset';
import useId from '@mc/hooks/useId';

import stylesheet from './Radio.less';

const NO_PROVIDER = Symbol('NO_PROVIDER');

const OnChangeContext = createContext(NO_PROVIDER);
const SelectedValueContext = createContext(NO_PROVIDER);
const ErrorContext = createContext(NO_PROVIDER);

/** Wrapper for Radio Buttons */
function RadioGroup({
  children,
  error,
  legend,
  label,
  value,
  onChange,
  // provided by a generic interface. We don't need to account
  // for PropTypes or usage, so we'll just disable linting here.
  // eslint-disable-next-line react/prop-types, no-unused-vars
  miscText,
  ...props
}) {
  const errorId = useId();

  return (
    <OnChangeContext.Provider value={onChange}>
      <SelectedValueContext.Provider value={value}>
        <ErrorContext.Provider value={{ errorId, error }}>
          <Fieldset
            {...props}
            legend={label || legend}
            className={cx({
              [stylesheet.error]: !!error,
            })}
          >
            {children}
            {/* Error message for Radio group */}
            {error && (
              <div id={errorId} className={stylesheet.errorMessage}>
                {error}
              </div>
            )}
          </Fieldset>
        </ErrorContext.Provider>
      </SelectedValueContext.Provider>
    </OnChangeContext.Provider>
  );
}

RadioGroup.propTypes = {
  /** Should contain one or more Radios. */
  children: PropTypes.node.isRequired,
  /** Will show for the entire Radio group and applies invalid style treatment. */
  error: PropTypes.string,
  /** Passed to the legend of the fieldset (interchangeable with legend) */
  label: PropTypes.node,
  /** Passed to the legend of the fieldset */
  legend: PropTypes.node,
  /** Required to enforce that this component should always be controlled. */
  onChange: PropTypes.func.isRequired,
  /** Required to enforce that this component should always be controlled. */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export function useRadioOnChange() {
  const context = useContext(OnChangeContext);
  if (context === NO_PROVIDER) {
    throw new Error('<Radio>s must be wrapped by a <RadioGroup>.');
  }
  return context;
}

export function useRadioSelectedValue() {
  const context = useContext(SelectedValueContext);
  if (context === NO_PROVIDER) {
    throw new Error('<Radio>s must be wrapped by a <RadioGroup>.');
  }
  return context;
}

export function useRadioError() {
  const context = useContext(ErrorContext);
  if (context === NO_PROVIDER) {
    throw new Error('<Radio>s must be wrapped by a <RadioGroup>.');
  }
  return context;
}

export default RadioGroup;
