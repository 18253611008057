import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

export const TranslateSlat = () => {
  return {
    checkBoxLabelText: useDsTranslateMessage({
      id: 'mcds.slat.checkbox_label_text',
      defaultMessage: 'Select item',
    }),
    iconButtonCollapseLabelText: useDsTranslateMessage({
      id: 'mcds.slat.icon_button_collapse_label_text',
      defaultMessage: 'Collapse',
    }),
    iconButtonExpandLabelText: useDsTranslateMessage({
      id: 'mcds.slat.icon_button_expand_label_text',
      defaultMessage: 'Expand',
    }),
  };
};
