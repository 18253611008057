import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonOrLink from '../ButtonOrLink';

import stylesheet from './Button.less';

const Button = React.forwardRef(function Button(
  {
    children,
    className,
    disabled = false,
    htmlType = 'button',
    isLoading = false,
    size = 'large',
    purpose = 'standard',
    type,
    ...rest
  },
  forwardedRef,
) {
  return (
    <ButtonOrLink
      disabled={disabled || isLoading}
      className={cx(
        stylesheet.container,
        stylesheet[type],
        { [stylesheet[size]]: size !== 'large' },
        { [stylesheet[purpose]]: purpose !== 'standard' },
        className,
      )}
      type={htmlType}
      ref={forwardedRef}
      {...rest}
    >
      {isLoading && <span className={stylesheet.loading} />}
      {/* [FP-1214]: Temporary fix. Wrap with <span>s to get around a Google Translate issue. */}
      <span className={stylesheet.temporarySpan}>{children}</span>
    </ButtonOrLink>
  );
});

Button.propTypes = {
  /** The content inside the button */
  children: PropTypes.node.isRequired,
  /** A custom class name that is appended to the button classes. */
  className: PropTypes.string,
  /** Makes the button unclickable */
  disabled: PropTypes.bool,
  /** The same URL string an `<a>` tag would expect. Defining this will convert
   * the internal markup of Button to `<a>` (instead of `<button>`). It is
   * important to note that the acceptable props for a "link"-based button are
   * inherently different when this happens.
   */
  href: PropTypes.string,
  /**
   * Only applies to Buttons without `href`. While most HTML attributes of
   * button are passed along. The "type" property is used to describe the
   * button's variant type (see below). To accomodate this, we rename the 'type'
   * attribute to 'htmlType'.
   */
  htmlType: PropTypes.oneOf(['submit', 'reset', 'button']),
  /** When true, the button is disabled and also shows a loading indicator */
  isLoading: PropTypes.bool,
  /** Mouse click event */
  onClick: PropTypes.func,
  /** Standard is for the most common use cases. Passive is mostly used for binary actions or a list of equally weighted primaries. */
  purpose: PropTypes.oneOf(['standard', 'passive']),
  /** Size of Button */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Only applies to buttons with `href`. Passed to `<a target>` */
  target: PropTypes.string,
  /** The different variants of a Button */
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
};

export default Button;
