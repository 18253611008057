import React from 'react';
import PropTypes from 'prop-types';

import StackLayout from '../StackLayout';
import Text from '../Text';

import stylesheet from './ContentCard.less';
import Card, { CardImage } from '.';

const ContentCard = React.forwardRef(function ContentCard(
  {
    alt,
    variant = 'cornflower',
    src,
    eyebrow,
    title,
    description,
    appearance = 'medium',
  },
  forwardedRef,
) {
  return (
    <Card appearance="none" className={stylesheet.root} ref={forwardedRef}>
      <CardImage
        appearance={appearance}
        src={src}
        variant={variant}
        alt={alt}
      />
      <div className={stylesheet.content}>
        <StackLayout gap={0}>
          <Text appearance="medium-bold" className={stylesheet.eyebrow}>
            {eyebrow}
          </Text>
          {React.cloneElement(title, {
            className: stylesheet.targetLink,
          })}
          <Text>{description}</Text>
        </StackLayout>
      </div>
    </Card>
  );
});

ContentCard.propTypes = {
  /** Card image alt text. */
  alt: PropTypes.string,
  /** Appearance for card. */
  appearance: PropTypes.string,
  /** Description for card. */
  description: PropTypes.string,
  /** Subtitle for card. */
  eyebrow: PropTypes.string,
  /** Source for card image. */
  src: PropTypes.string,
  /** Title for card. */
  title: PropTypes.node,
  /** A background color matching one of the allowed brand colors. */
  variant: PropTypes.oneOf([
    'coconut',
    'cornflower',
    'cucumber',
    'radish',
    'squash',
    'yam',
    'jasmine',
  ]),
};

export default ContentCard;
