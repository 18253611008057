import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';
import stylesheet from './Checkbox.less';

/** Checkboxes are a form element used to make any number of selections from a list. */
const Checkbox = React.forwardRef(function Checkbox(
  {
    'aria-labelledby': ariaLabelledBy,
    children,
    className,
    error,
    disabled = false,
    hideLabel = false,
    label,
    onChange,
    value,
    indeterminate = false,
    // The following prop is not consumed inside `Checkbox`, but
    // provided by a generic interface. We don't need to account
    // for PropTypes or usage, so we'll just disable linting here.
    // eslint-disable-next-line react/prop-types, no-unused-vars
    miscText,
    ...props
  },
  forwardedRef,
) {
  const autoId = useId();
  const labelId = useId();
  const descriptionId = useId();
  const errorId = useId();
  const describedBy = ariaDescribedByIds(
    children && descriptionId,
    error && errorId,
  );
  const id = props.id || autoId;
  const indeterminateAsString = indeterminate?.toString();

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.error]: !!error,
      })}
    >
      <input
        className={cx(stylesheet.input, {
          [stylesheet.noMargin]: (hideLabel || !label) && !describedBy,
        })}
        id={id}
        type="checkbox"
        checked={value}
        // Rendered as string to pass directly to DOM
        indeterminate={indeterminateAsString}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
        // We need to handle three cases:
        //
        // 1. Only pass a `label`. Since we're using a native label element,
        //    pointing `aria-labelledby` to the existing label element is
        //    unnecessary.
        // 2. Only pass an `aria-labelledby`. We don't render a label element.
        // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
        //    `aria-labelledby` attribute.
        aria-labelledby={ariaLabelledByIds(
          ariaLabelledBy,
          ariaLabelledBy && label && labelId,
        )}
        aria-describedby={describedBy}
        aria-checked={indeterminate ? 'mixed' : value ? 'true' : 'false'}
        ref={forwardedRef}
        {...props}
      />
      <div className={stylesheet.text}>
        {label && (
          <label
            id={labelId}
            htmlFor={id}
            className={cx(hideLabel && 'wink-visually-hidden', {
              [stylesheet.labelDisabled]: disabled,
            })}
          >
            {label}
          </label>
        )}
        {children && (
          <div id={descriptionId} className={stylesheet.description}>
            {children}
          </div>
        )}
        {error && (
          <div id={errorId} className={stylesheet.errorMessage}>
            {error}
          </div>
        )}
      </div>
    </div>
  );
});

Checkbox.propTypes = {
  /** @ignore Throwaway propType to do complex checks. */
  // eslint-disable-next-line react/no-unused-prop-types
  _: (props, propName, componentName) => {
    if (!props.label && !props['aria-labelledby']) {
      return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName));
    }
  },
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /** Will provide the checkbox with a description below the label. */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** Makes the input unusable and un-clickable. */
  disabled: PropTypes.bool,
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error: PropTypes.string,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** Id passed to the input, this is automatically created and not required */
  id: PropTypes.string,
  /** Whether properties owned by the box are partially checked. */
  indeterminate: PropTypes.bool,
  /** The label of the checkbox. */
  label: PropTypes.node,
  /** Required to enforce that this component should always be controlled. */
  onChange: PropTypes.func.isRequired,
  /** Required to enforce that this component should always be controlled. */
  value: PropTypes.bool.isRequired,
};

export default Checkbox;
