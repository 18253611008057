import PropTypes from 'prop-types';
import React from 'react';
import { TickIcon } from '@mc/wink-icons';
import ActionList, { ActionListItem } from '../ActionList';
import stylesheet from './PageSwitcher.css';

function PageSwitcher({ children, label, ...props }) {
  let currentPageLabel;

  const filteredChildren = React.Children.toArray(children).filter(Boolean);
  filteredChildren.forEach((child) => {
    if (child.props['aria-current'] || child.props.isCurrent) {
      currentPageLabel = child.props.label;
    }
  });

  return (
    <nav aria-label={label} className={stylesheet.nav}>
      {/* TODO: Should this be a <select>? */}
      <ActionList
        label={currentPageLabel}
        type="inline"
        placement="bottom-start"
        {...props}
      >
        {filteredChildren}
      </ActionList>
    </nav>
  );
}

PageSwitcher.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

export function PageSwitcherItem({ children, label, ...props }) {
  return (
    <ActionListItem
      {...props}
      label={label || children}
      iconEnd={props['aria-current'] ? <TickIcon /> : undefined}
    />
  );
}

PageSwitcherItem.propTypes = {
  'aria-current': PropTypes.oneOf(['page', 'step']),
  children: PropTypes.string,
  href: PropTypes.string,
  isCurrent: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default PageSwitcher;
