import { useReducer, useMemo } from 'react';
import { lastDayOfWeek, startOfWeek } from 'date-fns';

// A function to retrieve the day count of a given year/month.
export function getDaysInMonth(year, month) {
  return 32 - new Date(year, month, 32).getDate();
}

// Date-clamping helper
function clampDate(date, minDate = -Infinity, maxDate = Infinity) {
  if (minDate > date) {
    return minDate;
  } else if (maxDate < date) {
    return maxDate;
  }
  return date;
}

function reducer(state, action) {
  const { focusedDate, calendarMonthInView, value } = state;
  const { min, max } = action;

  switch (action.type) {
    case 'calendarMonth:step': {
      let nextCalendarMonthInView = new Date(calendarMonthInView);

      //Prevents skipping next month if current date does not exist in next month.
      //This creates a new date two months ahead with zero day, which results in next month last day.
      const nextMonth = new Date(
        nextCalendarMonthInView.getFullYear(),
        nextCalendarMonthInView.getMonth() + action.delta + 1,
        0,
      );
      if (
        calendarMonthInView.getDate() >
        getDaysInMonth(nextMonth.getFullYear(), nextMonth.getMonth())
      ) {
        nextCalendarMonthInView = nextMonth;
      } else {
        nextCalendarMonthInView.setMonth(
          calendarMonthInView.getMonth() + action.delta,
        );
      }

      let nextFocusedDate;
      if (focusedDate) {
        if (nextCalendarMonthInView.getMonth() === value.getMonth()) {
          nextFocusedDate = value;
        } else {
          nextFocusedDate = new Date(nextCalendarMonthInView);
          nextFocusedDate.setDate(1);
        }
      }

      return {
        ...state,
        calendarMonthInView: nextCalendarMonthInView,
        focusedDate: nextFocusedDate,
      };
    }

    case 'moveFocus:day': {
      let nextFocusedDate = new Date(focusedDate);
      nextFocusedDate.setDate(focusedDate.getDate() + action.delta);

      // Ensure we don't navigate out of range
      nextFocusedDate = clampDate(nextFocusedDate, min, max);
      return {
        ...state,
        focusedDate: nextFocusedDate,
        // Keep the focus in view
        calendarMonthInView: nextFocusedDate,
      };
    }

    case 'moveFocus:month': {
      let nextFocusedDate = new Date(focusedDate);
      nextFocusedDate.setMonth(focusedDate.getMonth() + action.delta);

      // Handle the edge case of going back a month, but keeping the date
      // at a position that is out of range for the next month.
      if (nextFocusedDate.getMonth() === focusedDate.getMonth()) {
        nextFocusedDate.setDate(1);
        nextFocusedDate.setMonth(focusedDate.getMonth() + action.delta);
        if (focusedDate.getDate() !== 1) {
          nextFocusedDate.setDate(
            getDaysInMonth(
              nextFocusedDate.getFullYear(),
              nextFocusedDate.getMonth(),
            ),
          );
        }
      }

      // Ensure we don't navigate out of range
      nextFocusedDate = clampDate(nextFocusedDate, min, max);

      return {
        ...state,
        focusedDate: nextFocusedDate,
        // Keep the focus in view
        calendarMonthInView: nextFocusedDate,
      };
    }

    case 'moveFocus:firstOfMonth': {
      let nextFocusedDate = new Date(calendarMonthInView);
      nextFocusedDate.setDate(1);

      // Ensure we don't navigate out of range
      nextFocusedDate = clampDate(nextFocusedDate, min, max);

      return {
        ...state,
        focusedDate: nextFocusedDate,
      };
    }

    case 'moveFocus:lastOfMonth': {
      let nextFocusedDate = new Date(calendarMonthInView);
      const daysInMonth = getDaysInMonth(
        focusedDate.getFullYear(),
        focusedDate.getMonth(),
      );
      nextFocusedDate.setDate(daysInMonth);

      // Ensure we don't navigate out of range
      nextFocusedDate = clampDate(nextFocusedDate, min, max);

      return {
        ...state,
        focusedDate: nextFocusedDate,
      };
    }

    case 'moveFocus:firstOfWeek': {
      const nextFocusedDate = clampDate(startOfWeek(focusedDate), min, max);

      return {
        ...state,
        focusedDate: nextFocusedDate,
        calendarMonthInView: nextFocusedDate,
      };
    }

    case 'moveFocus:lastOfWeek': {
      const nextFocusedDate = clampDate(lastDayOfWeek(focusedDate), min, max);

      return {
        ...state,
        focusedDate: nextFocusedDate,
        calendarMonthInView: nextFocusedDate,
      };
    }

    case 'setFocusedDate': {
      if (!action.date) {
        return {
          ...state,
          value: null,
          focusedDate: null,
          calendarMonthInView: new Date(),
        };
      }

      const nextFocusedDate = new Date(action.date);

      return {
        ...state,
        value: nextFocusedDate,
        focusedDate: nextFocusedDate,
        calendarMonthInView: nextFocusedDate,
      };
    }

    default:
      return state;
  }
}

function useCalendar({ initialValue = null, min, max }) {
  const [state, dispatch] = useReducer(reducer, {
    value: initialValue,
    focusedDate: initialValue,
    calendarMonthInView: initialValue || new Date(),
  });

  const api = useMemo(() => {
    return {
      prevMonth: () => {
        dispatch({ type: 'calendarMonth:step', delta: -1 });
      },
      nextMonth: () => {
        dispatch({ type: 'calendarMonth:step', delta: 1 });
      },
      moveFocusByDay: (delta) => {
        dispatch({ type: 'moveFocus:day', delta, min, max });
      },
      moveFocusByMonth: (delta) => {
        dispatch({ type: 'moveFocus:month', delta, min, max });
      },
      setFocusedDate: (date) => {
        dispatch({ type: 'setFocusedDate', date });
      },
      moveFocusToFirstDateOfCurrentMonth: () => {
        dispatch({ type: 'moveFocus:firstOfMonth', min, max });
      },
      moveFocusToLastDateOfCurrentMonth: () => {
        dispatch({ type: 'moveFocus:lastOfMonth', min, max });
      },
      moveFocusToFirstDayOfFocusedWeek: () => {
        dispatch({ type: 'moveFocus:firstOfWeek', min, max });
      },
      moveFocusToLastDayOfFocusedWeek: () => {
        dispatch({ type: 'moveFocus:lastOfWeek', min, max });
      },
    };
  }, [dispatch, min, max]);

  return [state, api];
}

export default useCalendar;
