import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ContentArea from '../ContentArea';
import StackLayout from '../StackLayout';

import stylesheet from './OverviewHero.less';

function OverviewHero({
  isBottomOverlap = true,
  top = null,
  titleWidth = 'normal',
  variant = 'cucumber',
  bottom,
  title,
  secondary,
  actions,
} = {}) {
  return (
    <div
      className={cx(stylesheet.root, {
        [stylesheet[variant]]: variant,
        [stylesheet[titleWidth]]: titleWidth === 'narrow',
        [stylesheet.isBottomOverlap]: isBottomOverlap,
      })}
    >
      <ContentArea>
        <StackLayout gap={4}>
          {top && <div className={stylesheet.topWrapper}>{top}</div>}
          <h2 className={stylesheet.title}>{title}</h2>
          <p className={stylesheet.secondary}>{secondary}</p>
          {actions && <div className={stylesheet.actions}>{actions}</div>}
          {bottom && <div className={stylesheet.bottom}>{bottom}</div>}
        </StackLayout>
      </ContentArea>
    </div>
  );
}

OverviewHero.propTypes = {
  /** Will contain interactive elements like buttons and/or text field. */
  actions: PropTypes.node,
  /** Will normally contain an image or DataWell. */
  bottom: PropTypes.node,
  /** If true, bottom will overlap with the the next sibling element. */
  isBottomOverlap: PropTypes.bool,
  /** Will normally be a small body of text. */
  secondary: PropTypes.string.isRequired,
  /** Will appearance as a h2. */
  title: PropTypes.string.isRequired,
  /** Control the width of the title.*/
  titleWidth: PropTypes.oneOf(['normal', 'narrow']),
  /** Will normally contain a badge. */
  top: PropTypes.node,
  /** A background color matching one of the allowed brand colors. */
  variant: PropTypes.oneOf([
    'cornflower',
    'coconut',
    'cucumber',
    'radish',
    'squash',
    'yam',
  ]),
};

export default OverviewHero;
