import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Fieldset from '../Fieldset';
import useId from '@mc/hooks/useId';

import stylesheet from './ChoiceCard.less';
import stylesheetSelection from './SelectionChoiceCard.less';

const NO_PROVIDER = Symbol('NO_PROVIDER');

const OnChangeContext = createContext(NO_PROVIDER);
const SelectedValuesContext = createContext(NO_PROVIDER);
const InputTypeContext = createContext(NO_PROVIDER);

/** Wrapper for Choice Card Buttons */
function ChoiceCardGroup({
  children,
  legend,
  label,
  error,
  horizontal = false,
  multiple = false,
  simple = false,
  value,
  onChange,
  // provided by a generic interface. We don't need to account
  // for PropTypes or usage, so we'll just disable linting here.
  // eslint-disable-next-line react/prop-types, no-unused-vars
  miscText,
  ...props
}) {
  const errorId = useId();
  let inputType = multiple ? 'checkbox' : 'radio';
  if (simple) {
    inputType = inputType + '-hidden';
  }
  // For SelectionChoiceCard
  if (horizontal) {
    inputType = inputType + '-horizontal';
  }

  return (
    <OnChangeContext.Provider value={onChange}>
      <SelectedValuesContext.Provider value={value}>
        <InputTypeContext.Provider value={{ inputType, errorId, error }}>
          <Fieldset
            {...props}
            legend={label || legend}
            className={cx({
              [stylesheet.error]: !!error,
              [stylesheetSelection.error]: !!error,
            })}
          >
            {children}
            {/* Error message for ChoiceCard group */}
            {error && (
              <div id={errorId} className={stylesheet.errorMessage}>
                {error}
              </div>
            )}
          </Fieldset>
        </InputTypeContext.Provider>
      </SelectedValuesContext.Provider>
    </OnChangeContext.Provider>
  );
}

ChoiceCardGroup.propTypes = {
  /** Should contain one or more Choices. */
  children: PropTypes.node.isRequired,
  /** Will show for the entire ChoiceCard group and applies invalid style treatment. */
  error: PropTypes.string,
  /** For SelectionChoiceCard only. Change card to a horizontal view. */
  horizontal: PropTypes.bool,
  /** Passed to the legend of the fieldset (interchangeable with legend) */
  label: PropTypes.node,
  /** Passed to the legend of the fieldset */
  legend: PropTypes.node,
  /** Optional: Used to create checkbox group */
  multiple: PropTypes.bool,
  /** Required to enforce that this component should always be controlled. */
  onChange: PropTypes.func.isRequired,
  /** When enabled, the radio/checkbox won't be shown and the padding of the choice card will be
   * reduced from `@spacing-6` to `@spacing-3`.
   */
  simple: PropTypes.bool,
  /** Required to enforce that this component should always be controlled. */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
};

export function useChoiceOnChange() {
  const context = useContext(OnChangeContext);
  if (context === NO_PROVIDER) {
    throw new Error('<ChoiceCard>s must be wrapped by a <ChoiceCardGroup>.');
  }
  return context;
}

export function useChoiceSelectedValues() {
  const context = useContext(SelectedValuesContext);
  if (context === NO_PROVIDER) {
    throw new Error('<ChoiceCard>s must be wrapped by a <ChoiceCardGroup>.');
  }
  return context;
}

export function useChoiceInputTypeValue() {
  const context = useContext(InputTypeContext);
  if (context === NO_PROVIDER) {
    throw new Error('<ChoiceCard>s must be wrapped by a <ChoiceCardGroup>.');
  }
  return context;
}

export default ChoiceCardGroup;
