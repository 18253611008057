const nonMarkupTagNames = ['SCRIPT', 'STYLE'];

/**
 * Adds `aria-hidden="true"` and `inert` to all direct descendants of
 * document.body. Returns a function to revert the properties to their previous
 * values (or non-values) for those descendants.
 *
 * @param {Array} except If provided, ignores direct descendants that have
 * any element of `except` as a child.
 *
 * @return {Function}
 */
export default function hideApplicationLevelElements(except) {
  const elementList = Array.from(document.body.children)
    .filter((element) => {
      return (
        except.every((exclude) => !element.contains(exclude)) &&
        !nonMarkupTagNames.includes(element.tagName)
      );
    })
    .map((element) => {
      return [element, element.inert];
    });

  elementList.forEach(([element]) => {
    element.inert = true;
  });

  return function resetAriaHidden() {
    elementList.forEach(([element, previousInert]) => {
      // catch null and undefined
      if (previousInert === null || previousInert === undefined) {
        element.removeAttribute('inert');
      } else {
        element.inert = previousInert;
      }
    });
  };
}
