import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InfoCircleFillIcon } from '@mc/wink-icons';
import Animate from '../Animate';
import IconButton from '../IconButton';
import Popup from '../Popup';
import useId from '@mc/hooks/useId';
import chainHandlers from '@mc/fn/chainHandlers';
import stylesheet from './Toggletip.less';

/**
 * A toggletip's child must support ref forwarding. Without it, the Toggletip will not know
 * how to position itself.
 */
function Toggletip({
  children,
  className,
  label,
  buttonLabel,
  direction = 'bottom',
  forceVisible = false,
  unsafe_isHoverable = false,
  ...props
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [announcement, setAnnouncement] = useState();
  const triggerRef = useRef();
  const id = useId();

  if (!children) {
    if (__DEV__ || __TEST__) {
      if (!buttonLabel) {
        console.error(
          'Toggletips must have a buttonLabel prop or a children prop.',
        );
      }
    }
    children = <IconButton label={buttonLabel} icon={<InfoCircleFillIcon />} />;
  }

  React.Children.only(children);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);
  const shouldShow = forceVisible || isVisible || isHovered;

  useEffect(() => {
    let timeout;
    if (isVisible && !announcement) {
      timeout = setTimeout(() => {
        setAnnouncement(label);
      }, 100);
    }

    return () => clearTimeout(timeout);
  });

  return (
    <React.Fragment>
      {React.cloneElement(children, {
        onClick: chainHandlers(children.props.onClick, () => {
          show();
          setAnnouncement();
          // Safari/Firefox don't focus buttons on click
          triggerRef.current.focus();
        }),
        onMouseEnter: chainHandlers(children.props.onMouseEnter, () => {
          if (unsafe_isHoverable) {
            setIsHovered(true);
          }
        }),
        onMouseLeave: chainHandlers(children.props.onMouseLeave, () => {
          if (unsafe_isHoverable) {
            setIsHovered(false);
          }
        }),
        onBlur: chainHandlers(children.props.onBlur, hide),
        ref: triggerRef,
      })}
      <Animate
        component={Popup}
        toggle={shouldShow}
        // Popup props
        arrow={<div className={stylesheet.arrow} />}
        offset={12}
        placement={direction}
        targetRef={triggerRef}
        // Underlying props
        className={cx(stylesheet.toggletip, className)}
        id={id}
        {...props}
      >
        {label}
      </Animate>
      <span role="status" aria-atomic="true">
        <span className="wink-visually-hidden">
          {isVisible ? announcement : ''}
        </span>
      </span>
    </React.Fragment>
  );
}

Toggletip.propTypes = {
  /** The label for the default icon button */
  buttonLabel: PropTypes.string,
  /** This should always be one element. This will be the element the toggletip appears when hovered. */
  children: PropTypes.node,
  /** A className that is added to the toggletip popup. */
  className: PropTypes.string,
  /** The default direction. A different position will be used if this choice wuld render off screen. */
  direction: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /** When true, internal hovering logic is ignored and the Toggletip is permanently visible. Please only use this sparingly. */
  forceVisible: PropTypes.bool,
  /** The content to show in the toggletip. */
  label: PropTypes.node.isRequired,
  /** @ignore */
  unsafe_isHoverable: PropTypes.bool,
};

export default Toggletip;
