import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useId from '@mc/hooks/useId';

import { ariaDescribedByIds } from '../utils';
import RadioGroup, {
  useRadioOnChange,
  useRadioSelectedValue,
  useRadioError,
} from './RadioGroup';

import stylesheet from './Radio.less';

const Radio = React.forwardRef(function Radio(
  { children, id, label, value, disabled = false, ...props },
  forwardedRef,
) {
  const selectedValue = useRadioSelectedValue();
  /* For accessibility, error messages have better support when associated to form controls vs fieldset/legends.
   * Pulling in error id to associate it with aria-describedby.
   * Reference: https://russmaxdesign.github.io/accessible-error-fieldset/
   */
  const { errorId, error } = useRadioError();
  const onChange = useRadioOnChange();
  const inputId = useId();
  const descriptionId = useId();
  const describedBy = ariaDescribedByIds(
    children && descriptionId,
    error && errorId,
  );

  id = id || inputId;

  return (
    <div className={stylesheet.root}>
      <input
        className={stylesheet.input}
        id={id}
        type="radio"
        value={value}
        onChange={() => onChange(value)}
        checked={selectedValue === value}
        {...props}
        ref={forwardedRef}
        aria-describedby={describedBy}
        disabled={disabled}
      />
      <div className={stylesheet.text}>
        <label
          htmlFor={id}
          className={cx({ [stylesheet.labelDisabled]: disabled })}
        >
          {label}
        </label>
        {children && (
          <div id={descriptionId} className={stylesheet.description}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
});

Radio.propTypes = {
  /** Optional. Becomes the description of the Radio */
  children: PropTypes.node,
  /** Whether or not the input is disabled */
  disabled: PropTypes.bool,
  /** The id of the radio input. If not provided, it is generated. */
  id: PropTypes.string,
  /** The Radio label. */
  label: PropTypes.node.isRequired,
  /** The value that reflects the changed value when this radio is toggled */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

// Ensures the name is properly shown in storybook
Radio.displayName = 'Radio';

export { Radio as default, RadioGroup };
