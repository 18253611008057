import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { useTransition, animated, Globals } from 'react-spring';
import useId from '@mc/hooks/useId';
import useBodyClass from '@mc/hooks/useBodyClass';
import usePrefersReducedMotion from '@mc/hooks/usePrefersReducedMotion';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

import Dialog from '../Dialog';
import ClusterLayout from '../ClusterLayout';
import Heading from '../Heading';
import Portal from '../Portal';
import TextButton from '../TextButton';

import stylesheet from './BottomSheet.less';

const SNAP_THRESHOLD = 0.4;

const AnimatedDialog = animated(Dialog);

const BottomSheetOverlay = React.forwardRef(function BottomSheetOverlay(
  { isOpen, isCloseable, onRequestClose },
  ref,
) {
  const transitions = useTransition(isOpen, {
    from: { opacity: 0, scale: 1 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 1 },
  });

  return transitions((style, item) => {
    return (
      item && (
        <animated.div
          ref={ref}
          className={stylesheet.overlay}
          onClick={isCloseable ? onRequestClose : undefined}
          style={style}
        />
      )
    );
  });
});

BottomSheetOverlay.propTypes = {
  isCloseable: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

/**
 * Modals act as a focused dialog with our users.
 * Typically, they should only be used to present inconsequential information,
 * act as a confirmation, or perform a singular task or action (such as fill out a form).
 */

const BottomSheet = React.forwardRef(function Modal(
  {
    children,
    className,
    closeButtonText,
    isCloseable = true,
    isOpen,
    title,
    breadcrumbs,
    onRequestClose,
    isExpandable,
    isExpandedOnOpen = false,
    ...rest
  },
  forwardedRef,
) {
  const id = useId();
  useBodyClass(isOpen, stylesheet.lockScroll);

  const sheetRef = useRef();
  const contentRef = useRef();
  const tapOffset = useRef(0);
  const tapPosition = useRef(0);
  const isDragging = useRef(false);
  const [presentation, setPresentation] = useState(
    isExpandedOnOpen ? 'expanded' : 'open',
  );
  const prefersReducedMotion = usePrefersReducedMotion();

  const transitions = useTransition(isOpen, {
    from: { transform: 'translateY(100%)', opacity: 0.75 },
    enter: { transform: 'translateY(0%)', opacity: 1 },
    leave: { transform: 'translateY(100%)', opacity: 0.75 },
    config: { mass: 1, tension: 248, friction: 26 },
  });

  // Translation for default text
  const defaultCloseButtonText = useRef(
    useDsTranslateMessage({
      id: 'mcds.bottom_sheet.close_button_text',
      defaultMessage: 'Close',
    }),
  );

  closeButtonText = closeButtonText || defaultCloseButtonText.current;

  // Remove animation when preferesReducedMotion is enabled
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    });
  }, [prefersReducedMotion]);

  useEffect(() => {
    const contentElement = document.querySelector(
      '.wink-bottom-sheet-receding-content',
    );

    if (contentElement) {
      contentElement.classList.toggle(
        'wink-bottom-sheet-receding-content-active',
        isOpen,
      );
    }
  }, [isOpen]);

  // update presentation on open/close
  useEffect(() => {
    if (isOpen) {
      // ensure presentation matches isExpandedOnOpen preference
      setPresentation(isExpandedOnOpen ? 'expanded' : 'open');
    } else {
      setPresentation('closed');
    }
  }, [isExpandedOnOpen, isOpen]);

  function startDrag(e) {
    tapPosition.current = e.targetTouches[0].clientY;
  }

  function onDrag(e) {
    tapOffset.current = tapPosition.current - e.targetTouches[0].clientY;
    tapPosition.current = e.targetTouches[0].clientY;

    // allow user to scroll inner content without dragging
    if (
      (tapOffset.current > 0 || contentRef.current.scrollTop > 0) &&
      presentation === 'expanded'
    ) {
      return;
    }

    if (!isDragging.current) {
      setPresentation('dragging');
    }
    isDragging.current = Math.abs(tapOffset.current) > 0;

    // stop user from being able to drag up if at min margin
    if (
      sheetRef.current.offsetTop - tapOffset.current <
      window.innerHeight * 0.05
    ) {
      return;
    }

    sheetRef.current.style.top =
      sheetRef.current.offsetTop - tapOffset.current + 'px';
  }

  function stopDragging() {
    // make sure we don't mess up small swipes with an actual drag
    if (!isDragging.current) {
      return;
    }

    const top = parseInt(sheetRef.current.style.top, 10);

    if (top > window.innerHeight * 0.9) {
      // swipe down to close
      onRequestClose();
    } else if (top < window.innerHeight * SNAP_THRESHOLD) {
      setPresentation('expanded');
    } else if (top >= window.innerHeight * SNAP_THRESHOLD) {
      setPresentation('open');
    }

    isDragging.current = false;
  }

  return transitions((style, item) => {
    return (
      item && (
        <React.Fragment>
          <Portal className="wink-bottom-sheet-overlay">
            <BottomSheetOverlay
              onRequestClose={onRequestClose}
              isCloseable={isCloseable}
              isOpen={isOpen}
            />
          </Portal>

          <AnimatedDialog
            className={cx(stylesheet.container, stylesheet[presentation], {
              [stylesheet.expandable]: isExpandable,
            })}
            onRequestClose={onRequestClose}
            aria-labelledby={id}
            onTouchStart={isExpandable ? startDrag : null}
            onTouchMove={isExpandable ? onDrag : null}
            onTouchEnd={isExpandable ? stopDragging : null}
            ref={sheetRef}
            style={style}
          >
            <div
              className={cx(stylesheet.root, className)}
              ref={forwardedRef}
              {...rest}
            >
              {isExpandable && <div className={stylesheet.dragIndicator} />}
              <ClusterLayout
                as="header"
                gap={0}
                justifyContent="space-between"
                className={stylesheet.header}
              >
                <div>
                  <Heading
                    id={id}
                    level={1}
                    appearance={breadcrumbs ? 'heading-4' : 'heading-3'}
                  >
                    {title}
                  </Heading>
                  {breadcrumbs}
                </div>
                <TextButton onClick={onRequestClose}>
                  {closeButtonText}
                </TextButton>
              </ClusterLayout>

              <main className={stylesheet.body} ref={contentRef}>
                {children}
              </main>
            </div>
          </AnimatedDialog>
        </React.Fragment>
      )
    );
  });
});

BottomSheet.propTypes = {
  /**
   * The wizard breadcrumbs element to include in the header. This will only work if the Bottom Sheet
   * is wrapped by a Wizard component.
   */
  breadcrumbs: PropTypes.node,
  /** The main content inside the modal.  */
  children: PropTypes.node,
  /** Custom class that is appended to the modal classes. */
  className: PropTypes.string,
  /** Customize close text button. Defaults to "Close". */
  closeButtonText: PropTypes.string,
  /** Allows the modal to be closable if the user clicks outside the modal */
  isCloseable: PropTypes.bool,
  /** Allows the sheet to be expandable via dragging on mobile devices. */
  isExpandable: PropTypes.bool,
  /** Set if an expandable bottom sheet is fully expanded when opening */
  isExpandedOnOpen: PropTypes.bool,
  /** Set the visibility of the modal. */
  isOpen: PropTypes.bool.isRequired,
  /** Callback function that should be ran when trigging a close action.  */
  onRequestClose: PropTypes.func.isRequired,
  /** The title of the bottom sheet. Will appear as an h3 if there's no breadcrumb, or an h4 if there is. */
  title: PropTypes.node.isRequired,
};

export default BottomSheet;
