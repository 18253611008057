import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Input from '../Input';
import stylesheet from './InputNumber.less';

const InputNumber = React.forwardRef(function InputNumber(
  {
    className,
    inputMode = 'numeric',
    pattern = '[0-9]*',
    maxLength,
    minLength,
    type = 'text',
    ...rest
  },
  forwardedRef,
) {
  return (
    <Input
      {...rest}
      className={cx(stylesheet.inputNumber, className)}
      ref={forwardedRef}
      type={type}
      inputMode={inputMode}
      pattern={pattern}
      maxLength={maxLength}
      minLength={minLength}
    />
  );
});

InputNumber.propTypes = {
  /** Text that appears below the input */
  helpText: PropTypes.node,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** Set onscreen keyboard displays.  */
  inputMode: PropTypes.oneOf(['numeric', 'decimal', 'tel']),
  /** The label of the input. */
  label: PropTypes.node,
  /** Set max length on input value */
  maxLength: PropTypes.number,
  /** Set min length on input value */
  minLength: PropTypes.number,
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: PropTypes.func.isRequired,
  /** Regex to set value constraints */
  pattern: PropTypes.string,
  /** Defaults to `text`. It is recommended to use `type='text'` with an `inputmode` value of `numeric` instead of `type='number'` for accessibility and better onscreen keyboard displays. */
  type: PropTypes.oneOf(['text', 'number']),
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: PropTypes.string.isRequired,
};

export default InputNumber;
