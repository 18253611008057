import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ClusterLayout from '../ClusterLayout';
import Heading from '../Heading';
import Text from '../Text';
import stylesheet from './SectionHeader.less';

/**
 * `SectionHeader` should  be used as a header within a section of the page. It is one level below from Pageheader.
 */
const SectionHeader = React.forwardRef(function SectionHeader(
  { children, title, actions, description, className },
  ref,
) {
  return (
    <React.Fragment>
      <div className={cx(stylesheet.header, className)} ref={ref}>
        <Heading level={2} appearance="heading-3">
          {title}
        </Heading>
        {children && <div>{children}</div>}

        {actions && (
          <ClusterLayout className={stylesheet.actions}>
            {actions}
          </ClusterLayout>
        )}
      </div>
      {description && (
        <Text appearance="medium-secondary" className={stylesheet.description}>
          {description}
        </Text>
      )}
    </React.Fragment>
  );
});

SectionHeader.propTypes = {
  /** Should be a button or link */
  actions: PropTypes.node,
  /** Any custom content that will appear in the center of the bar. Please note that you will be responsible for handling any
   * mobile styles for these children.
   */
  children: PropTypes.node,
  /**
   * Optional subtitle or description that appears below the horizontal line of the main title
   * */
  description: PropTypes.string,
  /** The main text of the Header. The string or node passed in here will be wrapped with a Heading component. */
  title: PropTypes.node.isRequired,
};

export default SectionHeader;
