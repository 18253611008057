import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './Card.less';

const Card = React.forwardRef(function Card(
  { appearance = 'default', className, children },
  forwardedRef,
) {
  return (
    <div
      className={cx(stylesheet.root, className, stylesheet[appearance])}
      ref={forwardedRef}
    >
      {children}
    </div>
  );
});

const CardImage = React.forwardRef(function CardImage(
  {
    src,
    variant,
    alt = '',
    sizing = 'fit',
    className,
    appearance = 'default',
    position,
  },
  forwardedRef,
) {
  return (
    // "fill" sizing will make the src a background image
    // TODO : Explore using img tag - https://jira.mailchimp.com/browse/DS-2340

    <>
      {sizing === 'fill' ? (
        <div
          role="img"
          aria-label={alt}
          style={{ backgroundImage: `url(${src})` }}
          className={cx(stylesheet.rootBgImage, className)}
          ref={forwardedRef}
        ></div>
      ) : (
        <figure
          className={cx(
            stylesheet.rootImage,
            className,
            stylesheet[variant],
            stylesheet[appearance],
          )}
          ref={forwardedRef}
        >
          <img
            src={src}
            alt={alt}
            className={cx(stylesheet.img)}
            style={{
              position: position ? 'absolute' : 'relative',
              ...position,
            }}
          />
        </figure>
      )}
    </>
  );
});

Card.propTypes = {
  /** Override the card's default border styles. */
  appearance: PropTypes.oneOf(['default', 'none']),
  /** Children of card. */
  children: PropTypes.node.isRequired,
  /** Optional classes set for card root element. */
  className: PropTypes.string,
  /** Reference for card link. */
  targetRef: PropTypes.shape({ current: PropTypes.any }),
};

CardImage.propTypes = {
  /** Card image alt text. */
  alt: PropTypes.string,
  /** Override the card image's default padding styles. */
  appearance: PropTypes.oneOf(['basic', 'default', 'medium']),
  /** Optional classes set for card image root element. */
  className: PropTypes.string,
  /** Position placement for image. */
  position: PropTypes.shape({
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
  }),
  /** Handles sizing for image. */
  sizing: PropTypes.oneOf(['fit', 'fill']),
  /** Source for card image. */
  src: PropTypes.string,
  /** A background color matching one of the allowed brand colors. */
  variant: PropTypes.oneOf([
    'coconut',
    'cornflower',
    'cucumber',
    'radish',
    'squash',
    'yam',
    'jasmine',
  ]),
};

export default Card;
export { CardImage };
