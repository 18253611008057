import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonOrLink from '../ButtonOrLink';

import stylesheet from './TextButton.less';

/**
 * A `TextButton` is a button that looks like an inline link but has a slightly
 * different hover style. If your link is part of a larger text block, use
 * [&lt;Link&gt;](https://wiki.intuit.com/display/MCEN/How+to+Create+a+React+Route#HowtoCreateaReactRoute-LinkingtoPages)
 * instead.
 *
 * `TextButton` use is distinct from a tertiary `Button` in that tertiary buttons
 * _must_ be used in concert with primary or secondary buttons. Only use a `TextButton`
 * alone or with other `TextButton`s. The added friction is intentional.
 */
const TextButton = React.forwardRef(function TextButton(
  { className, children, onClick, ...props },
  forwardedRef,
) {
  return (
    <ButtonOrLink
      className={cx(stylesheet.root, className)}
      onClick={onClick}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </ButtonOrLink>
  );
});

TextButton.propTypes = {
  children: PropTypes.node,
  /** The same URL string an `<a>` tag would expect. Defining this will convert
   * the internal markup of TextButton to `<a>` (instead of `<button>`). It is
   * important to note that the acceptable props for a "link"-based button are
   * inherently different when this happens.
   */
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export default TextButton;
