import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TabContext from './TabContext';
import getTabId from './getTabId';
import stylesheet from './TabPanel.less';

function TabPanel({ index, ...props }) {
  const { currentIndex, id } = useContext(TabContext);
  const tabId = getTabId(id, index);
  return (
    <div
      {...props}
      className={stylesheet.tabPanel}
      tabIndex={-1}
      hidden={currentIndex !== index}
      role="tabpanel"
      id={tabId && `${tabId}:panel`}
      aria-labelledby={tabId}
    />
  );
}

TabPanel.propTypes = {
  index: PropTypes.number,
};

export default TabPanel;
