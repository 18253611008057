import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isValidElementType from '@mc/fn/isValidElementType';
import chainHandlers from '@mc/fn/chainHandlers';
import useFormField from './useFormField';
import { TranslateForm } from './TranslateForm';

/**
 * Takes a `component` prop and a `name` prop and uses it to map the form state
 * to the given component. The `component` should be a form input of some sort
 * (see `@mc/wink/components/Input` for most cases) but will allow any
 * controlled component (a component that takes both an `onChange` callback and
 * `value` prop). FormField will handle labels, ARIA roles, descriptions, and
 * error messages from the Form and pass them into the given input component.
 */
const FormField = React.forwardRef(function FormField(
  {
    name,
    component: InputComponent,
    children,
    required = false,
    onBlur,
    onNoErrorBlur,
    ...props
  },
  ref,
) {
  const { value, onChange, error, formContext } = useFormField(name);
  const [isTouched, setIsTouched] = useState(false);
  const shouldShowError = isTouched || formContext.hasSubmitted;

  // Translation for default text
  const { optionalText, requiredText } = TranslateForm();
  const requiredMiscText = formContext.requiredFieldIndicator
    ? requiredText
    : '';
  const optionalMiscText = formContext.optionalFieldIndicator
    ? optionalText
    : '';
  const miscText = required ? requiredMiscText : optionalMiscText;

  const inputProps = {
    children,
    required,
    ref,
    miscText,
    error: shouldShowError && !!error ? error : undefined,
    ...props,
    name,
    value,
    onBlur: chainHandlers(
      onBlur,
      () => onNoErrorBlur && !error && onNoErrorBlur(name, value),
      () => setIsTouched(true),
    ),
    onChange,
  };

  return isValidElementType(InputComponent) ? (
    <InputComponent {...inputProps} />
  ) : (
    InputComponent(inputProps)
  );
});

FormField.propTypes = {
  children: PropTypes.node,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  /** @ignore */
  onBlur: PropTypes.func,
  /** @ignore */
  onChange: (props, propName, componentName) => {
    if (props[propName] !== undefined) {
      return new Error(
        '`' +
          componentName +
          '` does not support the `' +
          propName +
          '` prop. Ask #mc-design-system-help for assistance.',
      );
    }
  },
  /** @ignore */
  onNoErrorBlur: PropTypes.func,
  required: PropTypes.bool,
  /** @ignore */
  value: (props, propName, componentName) => {
    if (props[propName] !== undefined) {
      return new Error(
        '`' +
          componentName +
          '` does not support the `' +
          propName +
          '` prop. Ask #mc-design-system-help for assistance.',
      );
    }
  },
};

export default FormField;
