import PropTypes from 'prop-types';
import React from 'react';
import useWinkBreakpoints from '@mc/hooks/useWinkBreakpoints';
import Button from '../Button';
import ActionList, { ActionListItem } from '../ActionList';
import IconButton from '../IconButton';
import { TranslatePage } from '../Page/TranslatePage';

// Sort children by the type, so that primary types are the first action
function sortChildrenByType(a, b) {
  if (a.props.type === b.props.type) {
    return 0;
  }

  if (a.props.type === 'primary') {
    return -1;
  }

  if (b.props.type === 'primary') {
    return 1;
  }

  if (a.props.type === 'secondary') {
    return -1;
  }

  if (b.props.type === 'secondary') {
    return 1;
  }

  return 0;
}

function PageHeaderActions({ children, label, ...props }) {
  const { isPhone } = useWinkBreakpoints();

  // Translate default text
  const { actionsText } = TranslatePage();
  label = label || actionsText;
  return isPhone ? (
    <ActionList label={label} {...props} type="inline">
      {React.Children.toArray(children).sort(sortChildrenByType)}
    </ActionList>
  ) : (
    children
  );
}

PageHeaderActions.propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.string,
  label: PropTypes.string,
};

export const PageHeaderActionsItem = React.forwardRef(
  function PageHeaderActionsItem(props, forwardedRef) {
    const { isPhone } = useWinkBreakpoints();

    if (isPhone) {
      return <ActionListItem {...props} type={undefined} ref={forwardedRef} />;
    }

    if (props.icon) {
      return (
        <IconButton {...props} label={props.children} ref={forwardedRef} />
      );
    }

    return <Button {...props} ref={forwardedRef} />;
  },
);

PageHeaderActionsItem.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
  isCurrent: PropTypes.bool,
};

export default PageHeaderActions;
