import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TickCircleFillIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import { ariaDescribedByIds } from '../utils';

import ChoiceCardGroup, {
  useChoiceOnChange,
  useChoiceSelectedValues,
  useChoiceInputTypeValue,
} from './ChoiceCardGroup';
import Text from '../Text';
import Badge from '../Badge';

import stylesheet from './SelectionChoiceCard.less';
import StackLayout from '../StackLayout';

const SelectionChoiceCard = React.forwardRef(function SelectionChoiceCard(
  {
    className,
    badge,
    badgeType,
    children,
    id,
    image,
    label,
    value,
    disabled = false,
    additionalCopy,
    ...props
  },
  forwardedRef,
) {
  const selectedValues = useChoiceSelectedValues();
  const onChange = useChoiceOnChange();
  /* For accessibility, error messages have better support when associated to form controls vs fieldset/legends.
   * Pulling in error id to associate it with aria-describedby.
   * Reference: https://www.last-child.com/legend-aria-describedby/
   */
  const { inputType, errorId, error } = useChoiceInputTypeValue();
  const inputId = useId();
  const labelId = useId();
  const additionalId = useId();
  const descriptionId = useId();
  const describedBy = ariaDescribedByIds(
    children && descriptionId,
    error && errorId,
    additionalCopy && additionalId,
  );
  const [type, variant] = inputType.split('-');
  id = id || inputId;
  const isSimple = variant === 'hidden';
  const isHorizontal = variant === 'horizontal';
  const isIcon = typeof image !== 'string';

  const isSelected = useCallback(() => {
    return Array.isArray(selectedValues)
      ? selectedValues.includes(value)
      : selectedValues === value;
  }, [selectedValues, value]);

  // Set specific wrapper if an image or icon is passed based on prop type.
  const cardImage =
    image &&
    (!isIcon ? (
      <div className={stylesheet.imageContainer}>
        <img src={image} alt="" className={stylesheet.image} />
      </div>
    ) : (
      <div className={stylesheet.icon}>{image}</div>
    ));

  return (
    <StackLayout
      className={cx(stylesheet.wrapper, className, {
        [stylesheet.checked]: isSelected(),
        [stylesheet.disabled]: disabled,
        [stylesheet.simple]: isSimple,
        [stylesheet.horizontal]: isHorizontal,
      })}
      gap={0}
    >
      <div className={cx(stylesheet.selection, className)}>
        <label htmlFor={id} className={stylesheet.card} {...props}>
          <div className="wink-visually-hidden">
            <input
              id={id}
              className={stylesheet.input}
              type={type}
              value={value}
              checked={isSelected()}
              onChange={() => onChange(value)}
              disabled={disabled}
              ref={forwardedRef}
              aria-describedby={describedBy}
              aria-labelledby={labelId}
            />
          </div>

          {isSelected() ? (
            // Display Selected Checkmark
            <div
              className={cx({
                [stylesheet.imageContainer]: !isIcon,
              })}
            >
              <TickCircleFillIcon
                alt="Selected"
                className={cx(stylesheet.checkIcon, {
                  'wink-visually-hidden': isSimple,
                })}
              />
            </div>
          ) : isHorizontal && !cardImage ? (
            // Display unselected circle for horizontal view
            <span className={stylesheet.unselectedCircle} />
          ) : (
            cardImage
          )}

          {/* Badges are only available in vertical default view.  */}
          {badge && !isHorizontal ? (
            <div>
              <Badge className={stylesheet.badge} type={badgeType}>
                {badge}
              </Badge>
            </div>
          ) : null}
          <div className={stylesheet.copy}>
            <div id={labelId} className={stylesheet.label}>
              {label}
            </div>
            {children && (
              <div className={stylesheet.description} id={descriptionId}>
                {children}
              </div>
            )}
          </div>
        </label>
      </div>
      {additionalCopy && (
        <div className={stylesheet.additional}>
          <Text id={additionalId} appearance="small-secondary">
            {additionalCopy}
          </Text>
        </div>
      )}
    </StackLayout>
  );
});

SelectionChoiceCard.propTypes = {
  /** Optional. Includes additional copy section underneath card. */
  additionalCopy: PropTypes.string,
  /** Optional. Include a Badge description. */
  badge: PropTypes.string,
  /** Optional. Pass Badge type. */
  badgeType: PropTypes.string,
  /** Optional. Becomes the description of the Choice Card. */
  children: PropTypes.node,
  /** Optional. Disables input. */
  disabled: PropTypes.bool,
  /** The id of the Choice Card input. If not provided, it is generated. */
  id: PropTypes.string,
  /** Optional. Passing a string will become an img src. You can also pass an icon as a React element. */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** The Choice Card label. */
  label: PropTypes.node.isRequired,
  /** The value that reflects the changed value when this Choice Card is toggled. */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export { SelectionChoiceCard as default, ChoiceCardGroup };
