import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MenuRightIcon, MenuLeftIcon } from '@mc/wink-icons';
import chainHandlers from '@mc/fn/chainHandlers';
import IconButton from '../IconButton';

import TabContext from './TabContext';
import getTabId from './getTabId';
import stylesheet from './TabList.less';
import { TranslateTabs } from './TranslateTabs';

const TabList = ({ children, onKeyDown, className, ...props }) => {
  const {
    currentIndex,
    id,
    setCurrentIndex,
    isManual,
    focusIndex,
    setFocusIndex,
    setShowOutline,
  } = useContext(TabContext);
  const tabListRef = useRef();
  const [isRightOverflow, setRightOverflow] = useState(false);
  const [isLeftOverflow, setLeftOverflow] = useState(false);
  // Translate default text
  const { scrollLeftText, scrollRightText } = TranslateTabs();

  // Handle click of overflow navigation
  const handleOverflowNavClick = (direction, multiplier = 60) => {
    const el = tabListRef.current;

    // Handle scroll on nav click
    el.scrollLeft += direction * multiplier;
  };

  // Show/hide overflow button depending on extended direction
  const setOverflows = () => {
    const el = tabListRef.current;

    if (el.scrollWidth > el.clientWidth) {
      setLeftOverflow(el.scrollLeft > 0);
      setRightOverflow(el.scrollLeft + el.clientWidth < el.scrollWidth);
    } else {
      setLeftOverflow(false);
      setRightOverflow(false);
    }
  };

  useLayoutEffect(() => {
    // Check for overflow on window resize and scroll
    window.addEventListener('resize', setOverflows);

    // And when it loads
    setOverflows();

    // Cleanup
    return () => {
      window.removeEventListener('resize', setOverflows);
    };
  }, []);

  // Checks to see if the tabs are supposed to be controlled by manual activation.
  // If yes, when using the arrow keys to navigate between the tabs we only want to
  // draw focus on the tab current tab, and require the user to either press the Enter key
  // or click on the tab to select it
  const getTrackedIndex = () => {
    return isManual ? focusIndex : currentIndex;
  };

  const handleKeyDown = (event) => {
    const size = children.length;
    const el = tabListRef.current;
    const tabId = getTabId(id, currentIndex);
    const tabEl = document.getElementById(tabId);

    switch (event.key) {
      case 'ArrowLeft': {
        event.preventDefault();
        const previousTabIndex = (getTrackedIndex() - 1 + size) % size;

        if (isManual) {
          setFocusIndex(previousTabIndex);
          setShowOutline(true);
        } else {
          setCurrentIndex(previousTabIndex);
        }

        // Manually set scroll into view. Sets visibilty on selected tab when navigating left
        // Opted out of using scrollIntoView library due to page jumps
        el.scrollLeft =
          tabEl.offsetLeft - (tabEl.clientWidth + el.clientWidth) / 2;
        break;
      }
      case 'ArrowRight': {
        event.preventDefault();
        const nextTabIndex = (getTrackedIndex() + 1) % size;

        if (isManual) {
          setFocusIndex(nextTabIndex);
          setShowOutline(true);
        } else {
          setCurrentIndex(nextTabIndex);
        }

        // Manually set scroll into view. Sets visibilty on selected tab when navigating right
        // Opted out of using scrollIntoView library due to page jumps
        el.scrollLeft = tabEl.offsetLeft + tabEl.clientWidth;
        break;
      }
      case 'Home': {
        event.preventDefault();
        setCurrentIndex(0);
        break;
      }
      case 'End': {
        event.preventDefault();
        setCurrentIndex(children.length - 1);
        break;
      }
      case ' ':
      case 'Enter': {
        if (isManual) {
          setCurrentIndex(focusIndex);
          setShowOutline(false);
        }

        const tabPanel = document.getElementById(`${tabId}:panel`);
        if (tabPanel) {
          event.preventDefault();
          tabPanel.focus();
        }
        break;
      }
      default:
        break;
    }
  };
  const cloned = React.Children.map(children, (child, index) => {
    if (child === null) {
      return null;
    }
    return React.cloneElement(child, { index });
  });

  useEffect(() => {
    const tablist = tabListRef.current;

    // Remove .focus-within class when click is detected
    function removeFocusWithin() {
      tablist.classList.remove(stylesheet['focus-within']);
    }

    // Add .focus-within class when keypress on TabList is detected
    function addFocusWithin() {
      tablist.classList.add(stylesheet['focus-within']);
    }

    document.addEventListener('keydown', addFocusWithin, { capture: true });
    document.addEventListener('click', removeFocusWithin, { capture: true });

    // Cleanup
    return () => {
      document.removeEventListener('keydown', addFocusWithin, {
        capture: true,
      });
      document.removeEventListener('click', removeFocusWithin, {
        capture: true,
      });
    };
  }, []);

  // Used to position the indicator that is animated between tabs
  const [animatedIndicatorPosition, setAnimatedIndicatorPosition] = useState({
    width: 0,
    left: 0,
  });

  useEffect(() => {
    const tabId = getTabId(id, currentIndex);
    const tabEl = document.getElementById(tabId);
    const currentTabRectangle = tabEl.getBoundingClientRect();
    const parentRectangle = tabListRef.current?.getBoundingClientRect();

    setAnimatedIndicatorPosition({
      width: currentTabRectangle.width,
      left: currentTabRectangle.left - parentRectangle.left,
    });
  }, [id, currentIndex]);

  return (
    <div className={stylesheet.wrapper}>
      {isLeftOverflow && (
        <div className={cx(stylesheet.overflowNav, stylesheet.overflowLeft)}>
          <IconButton
            // Tabs are accessible on their own with keyboard navigation (arrow keys are used to navigate/scroll),
            // there is no need to access these buttons but they are visually provided to indicate
            // direction of overflow.
            aria-hidden
            tabIndex="-1"
            label={scrollLeftText}
            icon={<MenuLeftIcon />}
            onClick={() => handleOverflowNavClick(-1)}
          />
        </div>
      )}

      <div
        {...props}
        role="tablist"
        ref={tabListRef}
        className={cx(className, stylesheet.tabList)}
        onKeyDown={chainHandlers(onKeyDown, handleKeyDown)}
        onScroll={setOverflows}
        children={cloned}
      />
      <span
        className={stylesheet.tabIndicator}
        style={{
          width: animatedIndicatorPosition.width + 'px',
          left: animatedIndicatorPosition.left + 'px',
        }}
      />
      {isRightOverflow && (
        <div className={cx(stylesheet.overflowNav, stylesheet.overflowRight)}>
          <IconButton
            aria-hidden
            tabIndex="-1"
            label={scrollRightText}
            icon={<MenuRightIcon />}
            onClick={() => handleOverflowNavClick(1)}
          />
        </div>
      )}
    </div>
  );
};

TabList.propTypes = {
  /** Consumes Tab component. */
  children: PropTypes.node,
  /** Handles key up events. */
  onKeyDown: PropTypes.func,
};

export default TabList;
