import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import stylesheet from './ContentArea.less';

/**
 * Listbox is a primitive component used to power other design system components.
 * It should *NEVER* be used directly in app code.
 *
 * It's job is to abstract the fluid padding specs that define main content spaces in the app.
 */
const ContentArea = React.forwardRef(function ContentArea(
  { as: Component = 'div', size = 'standard', children, className },
  forwardedRef,
) {
  return (
    <Component
      className={cx(stylesheet.root, stylesheet[size], className)}
      ref={forwardedRef}
    >
      {children}
    </Component>
  );
});

ContentArea.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['standard', 'narrow', 'wide']),
};

export default ContentArea;
