import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DeleteIcon, FileIcon } from '@mc/wink-icons';
import Loading from '../Loading';
import ClusterLayout from '../ClusterLayout';
import IconButton from '../IconButton';
import Input from '../Input';
import StackLayout from '../StackLayout';
import Text from '../Text';
import humanizeBytes from '@mc/fn/humanizeBytes';

import { TranslateFileInput } from './TranslateFileInput';
import stylesheet from './File.less';

const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

/**
 * A File represents a single file uploaded through the FileInput input
 */
export const File = React.forwardRef(function File(
  { file, onRemove, isLoading = false, showImagePreviews = false },
  forwardedRef,
) {
  // Translate default text
  const { removeFileMsg } = TranslateFileInput();

  //If the file is an image this provides a nifty little preview
  let thumbnailUrl;
  if (showImagePreviews) {
    thumbnailUrl = file.thumbnail;
    if (!thumbnailUrl && IMAGE_TYPES.includes(file.type)) {
      const urlCreator = window?.URL || window?.webkitURL;
      thumbnailUrl = urlCreator?.createObjectURL(file);
    }
  }

  return (
    <ClusterLayout
      gap={4}
      nowrap={true}
      ref={forwardedRef}
      className={stylesheet.file}
      justifyContent="space-between"
    >
      <div role="status" aria-live="polite" aria-atomic="true">
        {isLoading ? (
          <Loading />
        ) : thumbnailUrl ? (
          <img className={stylesheet.thumbnail} src={thumbnailUrl} alt="" />
        ) : (
          <FileIcon />
        )}
      </div>
      <StackLayout gap={0} style={{ flexGrow: 1 }}>
        <Text className={stylesheet.nameText} appearance="small-bold">
          {file.name}
        </Text>
        {file.size !== undefined && !isLoading ? (
          <Text appearance="small-secondary">{humanizeBytes(file.size)}</Text>
        ) : null}
      </StackLayout>
      {!isLoading ? (
        <IconButton
          className={stylesheet.removeButton}
          label={removeFileMsg}
          onClick={onRemove}
          icon={<DeleteIcon />}
        />
      ) : null}
    </ClusterLayout>
  );
});

File.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    thumbnail: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
  showImagePreviews: PropTypes.bool,
};

export const AltTextFile = React.forwardRef(function AltTextFile(
  {
    value,
    onChange,
    label = 'Alt Text',
    file,
    onRemove,
    isLoading,
    maxAltTextLength,
    altErrorText,
    altHelpText,
    index,
    showImagePreviews = false,
  },
  forwardedRef,
) {
  /**
   * Update the Alttext for the file at the current index in the array
   * @param {string} text
   */
  const updateAltText = (text) => {
    const updatedValueArr = value.slice();
    updatedValueArr[index] = text;
    onChange(updatedValueArr);
  };

  const cleanUpAltTextAndFile = () => {
    const altTextArray = value.slice();
    altTextArray.splice(index, 1);
    // Cleans up alt text
    onChange(altTextArray);
    // Removes the file
    onRemove();
  };

  return (
    <StackLayout
      className={classnames(stylesheet.container, stylesheet.altStyleContainer)}
    >
      <File
        ref={forwardedRef}
        showImagePreviews={showImagePreviews}
        {...{ file, onRemove: cleanUpAltTextAndFile, isLoading }}
      />

      <Input
        value={value[index]}
        onChange={updateAltText}
        className={stylesheet.altText}
        label={label}
        placeholder="Enter some alt text describing your image"
        suggestedMaxLength={maxAltTextLength}
        helpText={altHelpText}
        error={value[index]?.length > maxAltTextLength ? altErrorText : ''}
      />
    </StackLayout>
  );
});

AltTextFile.propTypes = {
  altErrorText: PropTypes.string,
  altHelpText: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  maxAltTextLength: PropTypes.number,
  onChange: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  showImagePreviews: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
};
