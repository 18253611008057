import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import useId from '@mc/hooks/useId';
import TabContext from './TabContext';

const Tabs = function Tabs({
  size = 'large',
  children,
  index,
  defaultIndex,
  onChange,
  isManual = false,
}) {
  const { current: isControlled } = useRef(index !== undefined);
  const [uncontrolledIndex, setUncontrolledIndex] = useState(defaultIndex || 0);
  const [focusIndex, setFocusIndex] = useState(defaultIndex || 0);
  const [showOutline, setShowOutline] = useState(false);
  const id = useId();

  const currentIndex = isControlled ? index : uncontrolledIndex;
  const setCurrentIndex = isControlled ? onChange : setUncontrolledIndex;

  const contextProps = useMemo(
    () => ({
      id,
      currentIndex,
      setCurrentIndex,
      size,
      focusIndex,
      setFocusIndex,
      showOutline,
      setShowOutline,
      isManual,
    }),
    [
      id,
      currentIndex,
      setCurrentIndex,
      size,
      focusIndex,
      showOutline,
      isManual,
    ],
  );

  return (
    <TabContext.Provider value={contextProps}>{children}</TabContext.Provider>
  );
};

Tabs.propTypes = {
  /** Consume TabList and TabPanels component. */
  children: PropTypes.node,
  /** Default index number. */
  defaultIndex: PropTypes.number,
  /** Current index number. */
  index: PropTypes.number,
  /** Tabs require manual selection, after focus on*/
  isManual: PropTypes.bool,
  /** Triggers when the Tab value has changed. */
  onChange: PropTypes.func,
  /** Option to render small or large tabs. */
  size: PropTypes.oneOf(['large', 'small']),
};

export default Tabs;
