import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useJoinedRef from '@mc/hooks/useJoinedRef';
import stylesheet from './GridLayout.less';

/**
 * Given a container, `GridLayout` tries to fill it up with as many columns as
 * it can. No single column width can go under `minColumnWidth`. The number of
 * rows is fluid, dependent on the number of columns and the number of children.
 */
const GridLayout = React.forwardRef(function GridLayout(
  {
    children,
    className,
    fit = true,
    gap = 2,
    minColumnWidth = '250px',
    style,
    ...props
  },
  forwardedRef,
) {
  const [isAboveMinColumnWidth, setIsAboveMinColumnWidth] = useState(false);
  const gridRef = useRef();
  const ref = useJoinedRef(forwardedRef, gridRef);

  useLayoutEffect(() => {
    if (typeof ResizeObserver !== 'undefined') {
      const node = gridRef.current;

      // Create a proxy element to measure and convert the `minColumnWidth` value
      // (which might be em, rem, etc) to `px`
      const test = document.createElement('div');
      test.style.width = minColumnWidth;
      node.appendChild(test);
      const minToPixels = test.offsetWidth;
      node.removeChild(test);

      const observer = new ResizeObserver((entries) => {
        // We're only ever observing one element anyway.
        const entry = entries[0];
        const { width } = entry.contentRect;
        setIsAboveMinColumnWidth(width > minToPixels);
      });
      observer.observe(node);
      return function unobserve() {
        observer.unobserve(node);
      };
    }
  }, [minColumnWidth]);

  return (
    <div
      ref={ref}
      {...props}
      className={cx(stylesheet.root, gap && stylesheet[`gap${gap}`], className)}
      style={{
        ...style,
        gridTemplateColumns: isAboveMinColumnWidth
          ? `repeat(${
              fit ? 'auto-fit' : 'auto-fill'
            }, minmax(${minColumnWidth}, 1fr))`
          : undefined,
      }}
    >
      {children}
    </div>
  );
});

GridLayout.propTypes = {
  children: PropTypes.node,
  /** Optional classes set on the root element of GridLayout. */
  className: PropTypes.string,
  /** When true, utilizes auto-fit to distribute grid columns, otherwise uses auto-fill */
  fit: PropTypes.bool,
  /** Controls spacing between children. Uses component spacing. */
  gap: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  /** The minimum width for a column. */
  minColumnWidth: PropTypes.string,
};

export default GridLayout;
