import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EyeHideIcon, EyeShowIcon } from '@mc/wink-icons';
import Input from '../Input';
import stylesheet from './InputPassword.less';
import { TranslateInput } from '../Input/TranslateInput';

/* A wrapper around the native browser `<input type="password" />`. Includes toggling the password asterisks. */
const InputPassword = React.forwardRef(function InputPassword(
  props,
  forwardedRef,
) {
  const [isHidden, setIsHidden] = useState(true);

  // Translate default text
  const { showMsg, hideMsg } = TranslateInput();
  return (
    <Input
      {...props}
      ref={forwardedRef}
      type={isHidden ? 'password' : 'text'}
      miscText={
        <button type="button" onClick={() => setIsHidden((h) => !h)}>
          <div className={stylesheet.visibilityToggle}>
            {isHidden ? <EyeShowIcon /> : <EyeHideIcon />}
            {isHidden ? showMsg : hideMsg}
          </div>
        </button>
      }
    />
  );
});

InputPassword.propTypes = {
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error: PropTypes.string,
  /** Text that appears below the input */
  helpText: PropTypes.node,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** The label of the input. */
  label: PropTypes.node,
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: PropTypes.func.isRequired,
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: PropTypes.string,
};

export default InputPassword;
