import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import valueTrend from './valueTrend';

import stylesheet from './DataWell.less';

const DataWellItem = React.forwardRef(function DataWell(
  { value, label, miscText, trend },
  forwardedRef,
) {
  return (
    <div
      className={cx(stylesheet.rootItem, {
        [stylesheet.hasMiscText]: miscText,
      })}
      ref={forwardedRef}
    >
      <div className={stylesheet.valueContainer}>
        <div className={stylesheet.value}>{value}</div>
        {miscText && (
          <div className={stylesheet.miscTextContainer}>
            <span
              className={cx(stylesheet.miscText, {
                [stylesheet[trend ?? valueTrend(miscText)]]:
                  trend ?? valueTrend(miscText),
              })}
            >
              {miscText}
            </span>
          </div>
        )}
      </div>
      <div className={stylesheet.label}>{label}</div>
    </div>
  );
});

const DataWell = React.forwardRef(function DataWellGroup(
  {
    align = 'center',
    appearance = 'default',
    children,
    size = 'md',
    className,
    wrapText = false,
  },
  forwardedRef,
) {
  return (
    <div
      className={cx(
        [
          stylesheet.root,
          stylesheet[size],
          stylesheet[align],
          stylesheet[appearance],
          className,
        ],
        {
          [stylesheet.wrapText]: wrapText,
        },
      )}
      ref={forwardedRef}
    >
      {children}
    </div>
  );
});

DataWellItem.propTypes = {
  /** Label for the value. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Text that appears along side of the value. Usually shows if the value has been trending positive or negative. */
  miscText: PropTypes.string,
  /** Manually set the positivity and negativity appearence of the miscText number. */
  trend: PropTypes.oneOf(['positive', 'negative', 'neutral']),
  /** Value for the data well. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

DataWell.propTypes = {
  /** Alignment of the DataWell. */
  align: PropTypes.oneOf(['start', 'center']),
  /** Override the DataWell's default padding and border styles. */
  appearance: PropTypes.oneOf(['default', 'none']),
  /** Children of the DataWellGroup. */
  children: PropTypes.node,
  /** Optional classes set on the root element of DataWell. */
  className: PropTypes.string,
  /** Sets the DataWellItem size. */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  /** Wrap misc text in to rows as opposed to side-by-side */
  wrapText: PropTypes.bool,
};

export default DataWell;
export { DataWellItem };
