import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './ButtonOrLink.less';

/**
 * A component for component library use only. It determines whether to render a
 * link or a button based on the `href` prop.
 */
const ButtonOrLink = React.forwardRef(function ButtonOrLink(
  { children, className, href, type = 'button', onClick, ...rest },
  forwardedRef,
) {
  const element = href ? 'a' : 'button';
  const props = {
    className: cx(stylesheet.root, className),
    onClick: onClick,
    ref: forwardedRef,
    href,
    ...rest,
  };

  if (element === 'button') {
    props.type = type;
  }

  if (element === 'a' && props.target === '_blank') {
    props.rel = 'noreferrer noopener';
  }

  // Disabled links are supposed to have no hrefs, so this is how we disable
  // href while still respecting the existing prop
  if (element === 'a' && props.disabled) {
    props.href = undefined;
    props.disabled = undefined;
    props.onClick = undefined;
  }

  return React.createElement(element, props, children);
});

ButtonOrLink.propTypes = {
  /** The content inside the button */
  children: PropTypes.node.isRequired,
  /** Makes the button unclickable */
  disabled: PropTypes.bool,
  /**
   * The same URL string an `<a>` tag would expect. Defining this will convert
   * the internal markup of Button to `<a>` (instead of `<button>`). It is
   * important to note that the acceptable props for a "link"-based button are
   * inherently different when this happens.
   */
  href: (props, ...rest) => {
    const invalidLinkProps = ['htmlType'];
    const invalidButtonProps = ['rel', 'target'];

    const error = PropTypes.string(props, ...rest);
    if (error) {
      return error;
    }

    if (props.href) {
      const invalidProps = invalidLinkProps.filter((prop) => props[prop]);
      if (invalidProps.length > 0) {
        return new Error(
          'Invalid prop `href` supplied to `Button`. `href` cannot be used with ' +
            invalidProps.join(', ') +
            '. Validation failed.',
        );
      }
    } else {
      const invalidProps = invalidButtonProps.filter((prop) => props[prop]);
      if (invalidProps.length > 0) {
        return new Error(
          'Invalid prop `href` must be used with `Button` if ' +
            invalidProps.join(', ') +
            ' are also used. Validation failed.',
        );
      }
    }
  },
  /** Mouse click event */
  onClick: PropTypes.func,
  /** Only applies to buttons with `href`. Passed to `<a target>` */
  target: PropTypes.string,
  /** Only applies to Buttons without `href`. */
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

export default ButtonOrLink;
