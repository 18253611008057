import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './Popover.less';

function PopoverBody({ children, className, ...props }) {
  return (
    <div className={cx(stylesheet.body, className)} {...props}>
      {children}
    </div>
  );
}

PopoverBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default PopoverBody;
