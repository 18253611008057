import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import pluralize from '@mc/fn/pluralize';
import useId from '@mc/hooks/useId';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';

import { TranslateInput } from '../Input/TranslateInput';
import stylesheet from './Textarea.less';

const Textarea = React.forwardRef(function Textarea(
  {
    'aria-labelledby': ariaLabelledBy,
    className,
    disabled = false,
    error,
    helpText,
    hideLabel = false,
    label,
    onChange,
    miscText,
    readOnly = false,
    resize = 'vertical',
    suggestedMaxLength = 0,
    onFocus = () => {},
    onBlur = () => {},
    ...props
  },
  forwardedRef,
) {
  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const maxLengthId = useId();
  const [hasFocus, setHasFocus] = useState(false);

  const count = (props.value && props.value.length) || 0;
  const remaining = suggestedMaxLength - count;

  const { characterMsg, charactersMsg } = TranslateInput();

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.error]: !!error,
      })}
    >
      <div className={stylesheet.before}>
        {label && (
          <label
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {suggestedMaxLength > 0 && (hasFocus || remaining < 0) ? (
          <span
            role="status"
            id={maxLengthId}
            className={cx(stylesheet.secondary, {
              [stylesheet.errorMessage]: remaining < 0,
            })}
          >
            {count}/{suggestedMaxLength}
            <span className="wink-visually-hidden">
              {' '}
              {pluralize(characterMsg, charactersMsg, count)}
            </span>
          </span>
        ) : miscText ? (
          <span className={stylesheet.secondary}>{miscText}</span>
        ) : null}
      </div>
      <textarea
        style={{ resize }}
        disabled={disabled}
        readOnly={readOnly}
        id={id}
        // We need to handle three cases:
        //
        // 1. Only pass a `label`. Since we're using a native label element,
        //    pointing `aria-labelledby` to the existing label element is
        //    unnecessary.
        // 2. Only pass an `aria-labelledby`. We don't render a label element.
        // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
        //    `aria-labelledby` attribute.
        aria-labelledby={ariaLabelledByIds(
          ariaLabelledBy,
          ariaLabelledBy && label && labelId,
        )}
        aria-describedby={ariaDescribedByIds(
          (error || helpText) && helpTextId,
          suggestedMaxLength && maxLengthId,
        )}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={(e) => {
          setHasFocus(false);
          onBlur(e);
        }}
        onFocus={(e) => {
          setHasFocus(true);
          onFocus(e);
        }}
        ref={forwardedRef}
        {...props}
      />
      {error ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.errorMessage)}
        >
          {error}
        </div>
      ) : helpText ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.secondary)}
        >
          {helpText}
        </div>
      ) : null}
    </div>
  );
});

Textarea.propTypes = {
  /** @ignore Throwaway propType to do complex checks. */
  // eslint-disable-next-line react/no-unused-prop-types
  _: (props, propName, componentName) => {
    if (!props.label && !props['aria-labelledby']) {
      return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName));
    }
  },
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /** Makes the input unusable and un-clickable. */
  disabled: PropTypes.bool,
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error: PropTypes.string,
  /** Text that appears below the input */
  helpText: PropTypes.node,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** The label of the textarea. */
  label: PropTypes.node,
  /** Max character limit. Strongly discouraged due to poor accessibility. Prefer `suggestedMaxLength` plus length validations. */
  maxLength: PropTypes.number,
  /** Text that appears above the input and right of the label. Usually shows Required state of the input. */
  miscText: PropTypes.node,
  /** Triggered when the field no longer contains focus */
  onBlur: PropTypes.func,
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: PropTypes.func.isRequired,
  /** Triggered when the field has focus */
  onFocus: PropTypes.func,
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly: PropTypes.bool,
  /** Control how the field may be resized */
  resize: PropTypes.oneOf(['vertical', 'horizontal', 'both', 'none']),
  /** Shows the character count in place of miscText when focused */
  showCharacterCount: PropTypes.bool,
  /** Shows the character count in place of `miscText` on focus. */
  suggestedMaxLength: PropTypes.number,
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: PropTypes.string.isRequired,
};

export default Textarea;
