import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '../Button';
import FormContext from './FormContext';
import stylesheet from './SubmitButton.less';

/**
 * A wrapper around `@mc/wink/components/Button` that is aware of the form's
 * current validity and will trigger the `onSubmit` callback of `Form`.
 *
 * Props passed to `SubmitButton` are applied to the inner `Button` component.
 */
function SubmitButton(props) {
  const { isSubmitting } = useContext(FormContext);
  return (
    <Button
      {...props}
      className={cx(stylesheet.button, props.className)}
      isLoading={isSubmitting || props.isLoading}
      disabled={isSubmitting || props.disabled}
      htmlType="submit"
    />
  );
}

SubmitButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default SubmitButton;
