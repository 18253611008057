import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import stylesheet from './Badge.css';

export type BadgeProps = PropsWithChildren<{
  className?: string;
  /**
   * The different variants of a badge. The default state should be used for
   * incomplete processes and numbers that aren’t related to notifications. This
   * includes states such as “draft” and “unpublished.”
   */
  type?: 'default' | 'active' | 'new' | 'success' | 'warning' | 'error';
}>;

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(function Badge (
  { className, children, type = 'default' },
  ref,
) {
  return (
    <span
      ref={ref}
      className={cx(stylesheet.root, stylesheet[type], className)}
    >
      {children}
    </span>
  );
});

const BADGE_TYPES = ['default', 'active', 'new', 'success', 'warning', 'error'];

export { BADGE_TYPES };
export default Badge;
