import React, { HTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';

import stylesheet from './Modal.less';

const ModalContent: React.FC<PropsWithChildren & HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={cx(stylesheet.content, className)}
      style={{ flexGrow: 2 }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ModalContent;
