import { useContext } from 'react';
import { getNestedValue } from '@mc/fn/nestedValue';
import FormContext from './FormContext';

export default function useFormField(name) {
  const formContext = useContext(FormContext);
  const { formId, values, errors, setValue } = formContext;

  // Bail on this if no name is defined because propTypes will handle surfacing this error
  if (!name) {
    return {};
  }

  return {
    id: `${formId}-${name}`,
    value: getNestedValue(values, name),
    error: errors[name] || null,
    isValid: !errors[name],
    onChange: setValue(name),
    formContext,
  };
}
