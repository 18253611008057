import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  CrossIcon,
  FeaturedIcon,
  InfoCircleIcon,
  NotAllowedIcon,
  TickCircleIcon,
  WarningCircleIcon,
} from '@mc/wink-icons';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import ClusterLayout from '../ClusterLayout';
import StackLayout from '../StackLayout';
import IconButton from '../IconButton';
import Portal from '../Portal';
import Text from '../Text';
import stylesheet from './FeedbackBlock.less';

const PortalWrapper = ({ condition, children }) => {
  return condition ? children : <Portal prependNode>{children}</Portal>;
};

PortalWrapper.propTypes = {
  children: PropTypes.node,
  /** To set Portal wrapper or not */
  condition: PropTypes.bool,
};

const FeedbackIcon = ({ variant }) => {
  const icon =
    variant === 'success' ? (
      <TickCircleIcon />
    ) : variant === 'warning' ? (
      <WarningCircleIcon />
    ) : variant === 'error' ? (
      <NotAllowedIcon />
    ) : variant === 'info' ? (
      <FeaturedIcon />
    ) : (
      <InfoCircleIcon />
    );
  return icon;
};

const ContentLayout = ({ children }) => {
  const { isDesktop } = useBreakpoints();

  // Stack actions and content for responsive view and break at the same time.
  return isDesktop ? (
    <ClusterLayout
      gap={2}
      justifyContent="space-between"
      className={stylesheet.content}
      nowrap
    >
      {children}
    </ClusterLayout>
  ) : (
    <StackLayout gap={2} className={stylesheet.content}>
      {children}
    </StackLayout>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node,
};

const FeedbackBlock = React.forwardRef(function FeedbackBlock(
  {
    actions,
    className,
    children,
    onClose,
    inline = false,
    title,
    variant = 'default',
    ...rest
  },
  forwardedRef,
) {
  const classList = cx(stylesheet.root, stylesheet[variant], className);
  const { isDesktop } = useBreakpoints();

  // Translation for default icon button label text
  const iconButtonLabelText = useDsTranslateMessage({
    id: 'mcds.feedback_block.icon_button_label_text',
    defaultMessage: 'Dismiss alert',
  });

  return (
    <PortalWrapper condition={inline}>
      <ClusterLayout nowrap className={classList} ref={forwardedRef} {...rest}>
        <span className={stylesheet.icon}>
          <FeedbackIcon variant={variant} />
        </span>
        <ContentLayout>
          <div className={stylesheet.copy}>
            <Text appearance="small-bold">{title}</Text>
            <Text as="span" appearance="small-secondary">
              {children}
            </Text>
          </div>
          <ClusterLayout
            nowrap={isDesktop}
            justifyContent={isDesktop ? 'flex-end' : 'flex-start'}
          >
            {actions}
          </ClusterLayout>
        </ContentLayout>
        {onClose && (
          <div>
            <IconButton
              label={iconButtonLabelText}
              icon={<CrossIcon />}
              onClick={onClose}
            />
          </div>
        )}
      </ClusterLayout>
    </PortalWrapper>
  );
});

FeedbackBlock.propTypes = {
  /** Actions passed through with the TextButton or Link component */
  actions: PropTypes.element,
  /** Copy that follows the bolded title of the feedback */
  children: PropTypes.node,
  /** Display inline if true. By default, the Feedback Block will append to the top of the page. */
  inline: PropTypes.bool,
  /** Function to dismiss FeedbackBlock */
  onClose: PropTypes.func,
  /** Bolded title */
  title: PropTypes.node.isRequired,
  /** The different variants of FeedbackBlock */
  variant: PropTypes.oneOf(['warning', 'default', 'success', 'error']),
};

export default FeedbackBlock;
