import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import stylesheet from './Text.less';

export type TextProps = PropsWithChildren<{
  appearance?:
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'large'
    | 'large-secondary'
    | 'large-bold'
    | 'medium'
    | 'medium-secondary'
    | 'medium-bold'
    | 'small'
    | 'small-secondary'
    | 'small-bold'
    | 'tiny'
    | 'tiny-secondary'
    | 'tiny-bold';
  as?: 'p' | 'span';
  className?: string;
  textWrap?: 'wrap' | 'nowrap' | 'balance' | 'pretty';
}>;

const Text = React.forwardRef<
  HTMLParagraphElement & HTMLSpanElement,
  TextProps
>(function Text (
  {
    appearance = 'medium',
    as: Component = 'p',
    className,
    children,
    textWrap,
    ...props
  },
  forwardedRef,
) {
  return (
    <Component
      className={cx(
        stylesheet.root,
        stylesheet[appearance],
        className,
        textWrap && stylesheet[`text-${textWrap}`],
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Component>
  );
});

export default Text;
