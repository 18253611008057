import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import { ariaDescribedByIds } from '../utils';
import ChoiceCardGroup, {
  useChoiceOnChange,
  useChoiceSelectedValues,
  useChoiceInputTypeValue,
} from './ChoiceCardGroup';

import stylesheet from './ChoiceCard.less';

/** Choice Card is a form element used when only one item can be selected from a group of two or more items in a list of options. */
const ChoiceCard = React.forwardRef(function ChoiceCard(
  { className, children, id, image, label, value, disabled = false, ...props },
  forwardedRef,
) {
  const selectedValues = useChoiceSelectedValues();
  const onChange = useChoiceOnChange();
  /* For accessibility, error messages have better support when associated to form controls vs fieldset/legends.
   * Pulling in error id to associate it with aria-describedby.
   * Reference: https://www.last-child.com/legend-aria-describedby/
   */
  const { inputType, errorId, error } = useChoiceInputTypeValue();
  const inputId = useId();
  const labelId = useId();
  const descriptionId = useId();
  const describedBy = ariaDescribedByIds(
    children && descriptionId,
    error && errorId,
  );
  const [type, hidden] = inputType.split('-');
  id = id || inputId;

  const isSelected = useCallback(() => {
    return Array.isArray(selectedValues)
      ? selectedValues.includes(value)
      : selectedValues === value;
  }, [selectedValues, value]);

  return (
    <div
      className={cx(stylesheet.choice, className, {
        [stylesheet.checked]: isSelected(),
        [stylesheet.disabled]: disabled,
        [stylesheet.simple]: !!hidden,
      })}
    >
      <label htmlFor={id} className={stylesheet.card} {...props}>
        <input
          className={cx(stylesheet.input)}
          id={id}
          type={type}
          value={value}
          checked={isSelected()}
          onChange={() => onChange(value)}
          disabled={disabled}
          ref={forwardedRef}
          aria-describedby={describedBy}
          aria-labelledby={labelId}
        />
        <div>
          {typeof image === 'string' ? (
            <img src={image} alt="" className={stylesheet.image}></img>
          ) : (
            image
          )}
        </div>
        <div
          className={hidden ? stylesheet.simpleLabel : stylesheet.label}
          id={labelId}
        >
          {label}
        </div>
        {children && (
          <div id={descriptionId} className={stylesheet.description}>
            {children}
          </div>
        )}
      </label>
    </div>
  );
});

ChoiceCard.propTypes = {
  /** Optional. Becomes the description of the Choice Card */
  children: PropTypes.node,
  /** Optional. Disables input */
  disabled: PropTypes.bool,
  /** The id of the Choice Card input. If not provided, it is generated. */
  id: PropTypes.string,
  /** Optional. Passing a string will become an img src. You can also pass a Wink icon as a React element. */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** The Choice Card label. */
  label: PropTypes.node.isRequired,
  /** The value that reflects the changed value when this Choice Card is toggled */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export { ChoiceCard as default, ChoiceCardGroup };
