import React, { useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Portal from '../Portal';

import stylesheet from './LegacyPopover.less';
import observeRect from './observeRect';

/**
 * PopoverImpl
 *
 * Popover is conditionally rendered so we can't start measuring until it shows
 * up, so useRect needs to live down here not up in Popover
 */
const PopoverImpl = React.memo(
  ({ position = positionDefault, children, targetRef, ...props }) => {
    const popoverRef = useRef();
    const popoverRect = useRect(popoverRef);
    const targetRect = useRect(targetRef);
    const style = getStyles(position, targetRect, popoverRect);
    return (
      <div
        ref={popoverRef}
        style={style}
        {...props}
        className={cx(stylesheet.popover, props.className || stylesheet.shadow)}
      >
        {children}
      </div>
    );
  },
);

PopoverImpl.propTypes = {
  /** Children for popover */
  children: PropTypes.node,
  /** Define the position of the popover */
  position: PropTypes.oneOf([
    positionDefault,
    positionMatchWidth,
    positionCenter,
  ]),
  /** Ref to trigger */
  targetRef: PropTypes.object,
};

/**
 * A Popover can be used to display content on top of another. This component is
 * for design purposes and doesn't handle any logic around interaction and
 * accessibility.
 *
 * This is largely copied from Reach's implementation.
 * https://github.com/reach/reach-ui/blob/master/packages/popover/src/index.tsx
 */
function Popover(props) {
  return (
    <Portal className="popover-portal-root">
      <PopoverImpl {...props} />
    </Portal>
  );
}

Popover.propTypes = {
  /** Children for popover */
  children: PropTypes.node,
  /** Define the position of the popover */
  position: PropTypes.oneOf([
    positionDefault,
    positionMatchWidth,
    positionCenter,
  ]),
  /** Ref to trigger */
  targetRef: PropTypes.object,
};

/**
 * getStyles
 *
 * Popover is conditionally rendered so we can't start measuring until it shows
 * up, so useRect needs to live down here not up in Popover
 */
function getStyles(position, targetRect, popoverRect) {
  const needToMeasurePopup =
    !popoverRect || !targetRect || popoverRect.width === 0;

  if (needToMeasurePopup) {
    return { visibility: 'hidden' };
  }
  return position(targetRect, popoverRect);
}

function useRect(nodeRef, observe = true, onChange) {
  const initialRectSet = useRef(false);
  const [rect, setRect] = useState(null);
  const observerRef = useRef(null);
  useLayoutEffect(() => {
    const cleanup = () => {
      return observerRef.current && observerRef.current.unobserve();
    };

    if (!nodeRef.current) {
      console.warn('You need to place the ref');
      return cleanup;
    }

    if (!observerRef.current) {
      observerRef.current = observeRect(nodeRef.current, (rectEl) => {
        setRect(rectEl);
        if (onChange) {
          onChange(rectEl);
        }
      });
    }

    if (!initialRectSet.current) {
      initialRectSet.current = true;
      setRect(nodeRef.current.getBoundingClientRect());
    }

    if (observe) {
      observerRef.current.observe();
    }
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observe, onChange]);
  return rect;
}

function positionDefault(targetRect, popoverRect) {
  const { directionUp, directionRight } = getCollisions(
    targetRect,
    popoverRect,
  );
  return {
    left: directionRight
      ? `${targetRect.right - popoverRect.width + window.pageXOffset}px`
      : `${targetRect.left + window.pageXOffset}px`,
    top: directionUp
      ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
      : `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    maxHeight: directionUp
      ? targetRect.top
      : window.innerHeight - targetRect.bottom - 10,
  };
}

function positionMatchWidth(targetRect, popoverRect) {
  const { directionUp } = getCollisions(targetRect, popoverRect);
  return {
    width: targetRect.width,
    left: targetRect.left,
    top: directionUp
      ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
      : `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    maxHeight: directionUp
      ? targetRect.top
      : window.innerHeight - targetRect.bottom - 10,
  };
}

function positionCenter(targetRect, popoverRect) {
  const { directionUp } = getCollisions(targetRect, popoverRect);
  const triggerCenter = targetRect.left + targetRect.width / 2;
  const left = triggerCenter - popoverRect.width / 2;

  return {
    left: left,
    top: directionUp
      ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
      : `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    maxHeight: directionUp
      ? targetRect.top
      : window.innerHeight - targetRect.bottom - 10,
  };
}

function getCollisions(
  targetRect,
  popoverRect,
  offsetLeft = 0,
  offsetBottom = 0,
) {
  const collisions = {
    top: targetRect.top - popoverRect.height < 0,
    right: window.innerWidth < targetRect.left + popoverRect.width - offsetLeft,
    bottom:
      window.innerHeight <
      targetRect.bottom + popoverRect.height - offsetBottom,
    left: targetRect.left - popoverRect.width < 0,
  };

  const directionRight = collisions.right && !collisions.left;
  const directionUp = collisions.bottom && !collisions.top;

  return { directionRight, directionUp };
}

export { positionDefault, positionMatchWidth, positionCenter };
export default Popover;
