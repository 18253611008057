import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@mc/wink-icons';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import stylesheet from './TagInput.less';
import ClusterLayout from '../ClusterLayout';
import Combobox, { ComboboxOption } from '../Combobox';
import Tag from '../Tag';
import TextButton from '../TextButton';
import { TranslateTagInput } from './TranslateTagInput';

export const TagOption = (props) => {
  return <ComboboxOption {...props} />;
};

const includes = (tags, filter = '') => {
  return tags.map((tag) => tag.toLowerCase()).includes(filter.toLowerCase());
};

const defaultOnFilter = (tags, value = '') => {
  return tags.filter((tag) => tag.toLowerCase().includes(value.toLowerCase()));
};

const defaultOnMap = (tags, selectedTags = []) => {
  const set = new Set([...tags, ...selectedTags]);
  return [...set].sort();
};

const SelectedTags = ({
  hideSelectedTags = false,
  label,
  selectedTagsLimit,
  selectedTags,
  onRemove,
}) => {
  const labelId = useId();
  // If the limit isn't defined or a valid number, default allSelectedTagsVisible to true
  const [allSelectedTagsVisible, setAllSelectedTagsVisible] = useState(
    isNaN(selectedTagsLimit),
  );

  const visibleTags = allSelectedTagsVisible
    ? selectedTags
    : selectedTags.slice(0, selectedTagsLimit);
  const isOverLimit = selectedTags.length > selectedTagsLimit;
  const hiddenTagsCount = selectedTags.length - visibleTags.length;
  // Translate default text
  const {
    hiddenTagCountText,
    showFewerText,
    selectedTagText,
  } = TranslateTagInput({
    count: hiddenTagsCount,
  });
  return (
    <>
      {/* Selected tags are reached before the input. Announce the related label to screen readers. */}
      <span id={labelId} className="wink-visually-hidden">
        {label}
      </span>
      {/* Tags are in unordered list for screen readers to announce the number of selected tags 
      and navigate between lists. */}
      <ClusterLayout
        as="ul"
        gap={1}
        className={cx(
          stylesheet.selectedValues,
          hideSelectedTags ? 'wink-visually-hidden' : '',
        )}
        aria-labelledby={labelId}
      >
        {visibleTags.map((selected) => (
          <li key={selected}>
            <Tag
              appearance="dismissible"
              onClick={() => onRemove(selected)}
              size="small"
            >
              <span className="wink-visually-hidden">{selectedTagText}</span>
              {selected}
            </Tag>
          </li>
        ))}
        {isOverLimit && (
          <li>
            {hiddenTagsCount > 0 ? (
              <TextButton
                onClick={() => setAllSelectedTagsVisible(true)}
                className={stylesheet.selectedLimitToggle}
              >
                + {hiddenTagCountText}
              </TextButton>
            ) : (
              <TextButton
                onClick={() => setAllSelectedTagsVisible(false)}
                className={stylesheet.selectedLimitToggle}
              >
                {showFewerText}
              </TextButton>
            )}
          </li>
        )}
      </ClusterLayout>
    </>
  );
};

SelectedTags.propTypes = {
  hideSelectedTags: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedTags: PropTypes.array.isRequired,
  selectedTagsLimit: PropTypes.number,
};

const TagInput = React.forwardRef(function TagInput(
  {
    'aria-autocomplete': ariaAutocomplete = 'list',
    value = [],
    onChange,
    onInputChange = () => {},
    filterTags = defaultOnFilter,
    mapTags = defaultOnMap,
    selectedTagsLimit,
    children,
    className,
    hideCreateLabel = false,
    hideSelectedTags = false,
    ...props
  },
  forwardedRef,
) {
  const [filter, setFilter] = useState('');
  // Translate default text
  const { createText } = TranslateTagInput({});

  const remove = (valueToRemove) => {
    onChange(value.filter((v) => v !== valueToRemove));
  };

  const toggle = (valueToToggle) => {
    if (valueToToggle.length > 0) {
      let nextValue;
      if (value.includes(valueToToggle)) {
        nextValue = value.filter((v) => v !== valueToToggle);
      } else {
        nextValue = [...value, valueToToggle];
      }
      onChange(nextValue);
    }
  };

  const handleInputChange = (filterValue) => {
    setFilter(filterValue);
    onInputChange(filterValue);
  };

  const filteredTags = filterTags(
    mapTags(
      children.map((child) => child.props.value),
      value,
    ),
    filter,
  );

  return (
    <Combobox
      {...props}
      ref={forwardedRef}
      aria-autocomplete={ariaAutocomplete}
      aria-multiselectable={true}
      autohighlight
      value={filter}
      onChange={handleInputChange}
      onSelect={(selectedValue) => {
        toggle(selectedValue);
        handleInputChange('');
      }}
      closeOnSelect={false}
      // Refreshes Popup whenever value changes.
      unsafe_key={value.join('/')}
      unsafe_renderExtraContent={
        <SelectedTags
          selectedTagsLimit={selectedTagsLimit}
          selectedTags={value}
          onRemove={remove}
          // Pass label so screen readers announce it when reaching list of selected tags
          label={props.label}
          hideSelectedTags={hideSelectedTags}
        />
      }
    >
      {!hideCreateLabel &&
        filter.length > 0 &&
        !includes(filteredTags, filter) && (
          <TagOption key="create" value={filter}>
            <PlusIcon /> {createText} "{filter}"
          </TagOption>
        )}
      {filteredTags.map((tag) => (
        <TagOption key={tag} value={tag} aria-selected={value.includes(tag)} />
      ))}
    </Combobox>
  );
});

TagInput.propTypes = {
  /**
   * If the options filter as you type, use "list". If they remain static, use
   * "none".
   */
  'aria-autocomplete': PropTypes.oneOf(['none', 'list']),
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /** A group of `TagOption` components */
  children: PropTypes.node.isRequired,
  /** Makes the input unusable and un-clickable. */
  disabled: PropTypes.bool,
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error: PropTypes.string,
  /** Use this to override the default tag filtering behavior. To disable filtering, pass a noop function `(tags) => tags` */
  filterTags: PropTypes.func,
  /** Text that appears below the input */
  helpText: PropTypes.node,
  /** Removes the `create` label. Removing this label will not allow users to create new tags. */
  hideCreateLabel: PropTypes.bool,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** Visually hides the selected tags */
  hideSelectedTags: PropTypes.bool,
  /** The label of the tag input. */
  label: PropTypes.node,
  /** Use this to override the default tag mapping behavior. To prevent adding selected tags, pass a noop function `(tags) => tags` */
  mapTags: PropTypes.func,
  /** Text that appears above the input and right of the label. Usually shows Required state of the input. */
  miscText: PropTypes.node,
  /** Triggers when an item is added (usually by pressing Tab, Enter, or clicking an item) or removed */
  onChange: PropTypes.func.isRequired,
  /** Triggers when the object select is focused. */
  onFocus: PropTypes.func,
  /** Triggers when the input value is changed. */
  onInputChange: PropTypes.func,
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly: PropTypes.bool,
  /** When defined, a limit is applied to the visible tags with a "+N more" button to toggle the visibility of the rest. */
  selectedTagsLimit: PropTypes.number,
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: PropTypes.arrayOf(PropTypes.string),
};

export default TagInput;
