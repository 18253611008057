import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Portal from '../Portal';
import useJoinedRef from '@mc/hooks/useJoinedRef';
import { inertExcludeSelectors } from './inertExclude';
import hideAppLevelElements from './ariaHider';
import stylesheet from './Dialog.less';

const Dialog = React.forwardRef(function(
  { children, className, onRequestClose, ...rest },
  forwardedRef,
) {
  const dialogRef = useRef();
  const ref = useJoinedRef(forwardedRef, dialogRef);

  useEffect(() => {
    // Focus management
    const previousFocus = document.activeElement;
    const dialogEl = dialogRef.current;
    dialogEl.focus();

    // Hide elements
    // TODO: We should ensure this only happens once across all modals.
    const popoverEls = [
      ...document.querySelectorAll('.mcds-popup-portal-root'),
    ];

    // This hides elements based on the selectors in the inertExclude.js file
    const inertExcludeEls = inertExcludeSelectors.map((selector) =>
      document.querySelector(selector),
    );

    // ProseMirror uses `MutationObserver` on its content to detect
    // changes to the html element it manages. The `inert` polyfill causes attribute
    // changes to occur on elements inside the ProseMirror container.
    // ProseMirror has a schema that defines what are valid element and attributes so it
    // attempts fix them. When there's more than 1 child element on the ProseMirror container
    // that the `inert` polyfill mutates, it causes an infinite loop between these two
    // `MutationObserver`. For now, we will ignore the content of ProseMirror.
    // This can be deleted if the `inert` polyfill is no longer used.
    const proseMirrorContainers = [
      ...document.querySelectorAll('.ProseMirror'),
    ];

    const ignoreElements = [
      dialogEl,
      ...popoverEls,
      ...proseMirrorContainers,
      ...inertExcludeEls,
    ];

    const unhideAppLevelElements = hideAppLevelElements(ignoreElements);

    return () => {
      unhideAppLevelElements();
      // The `inert` polyfill uses a `MutationObserver` to detect changes to
      // the `inert` attribute. We must let the current task end before
      // focusing the previous element. Check if we use the inert polyfill
      // (Element.prototype.inert on polyfill.mailchimp.com) before removing.
      setTimeout(() => {
        previousFocus.focus();
      }, 0);
    };
  }, []);

  return (
    <Portal className="wink-dialog-portal-root">
      <div
        className={cx(stylesheet.dialog, className)}
        onKeyDown={(event) => {
          const { tagName: focusedElementType } = event.target;
          if (
            !event.defaultPrevented &&
            event.key === 'Escape' &&
            focusedElementType !== 'INPUT' &&
            focusedElementType !== 'TEXTAREA' &&
            focusedElementType !== 'SELECT'
          ) {
            onRequestClose();
            event.preventDefault();
          }
        }}
        role="dialog"
        tabIndex={-1}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    </Portal>
  );
});

Dialog.propTypes = {
  /** Children of the dialog. */
  children: PropTypes.node,
  /** Custom class that is appended to the dialog classes. */
  className: PropTypes.string,
  /** Callback function that should be ran when trigging a close action.  */
  onRequestClose: PropTypes.func.isRequired,
};

export default Dialog;
