import React from 'react';
import PropTypes from 'prop-types';
import stylesheet from './AnnouncementCardTunnel.less';

/** Toast wrapper for accessibility purposes. */
const AnnouncementCardTunnel = React.forwardRef(function AnnouncementCardTunnel(
  { id = 'portal-announcement' },
  forwardedRef,
) {
  return (
    <div className={stylesheet.root} role="status" id={id} ref={forwardedRef} />
  );
});

AnnouncementCardTunnel.propTypes = {
  /** Default `id` is configured and used within `Main/core/Shell.js`.
   * If you are not using the classic or redesign shell, you may create a
   * custom portal by importing this component and setting your own custom id. */
  id: PropTypes.string,
};

export default AnnouncementCardTunnel;
