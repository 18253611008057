import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ClusterLayout from '../ClusterLayout';

import stylesheet from './Modal.less';

const ModalFooter = ({
  children,
  className,
  justifyContent = 'flex-end',
  ...rest
}) => {
  return (
    <div className={cx(stylesheet.footer, className)} {...rest}>
      <ClusterLayout justifyContent={justifyContent}>{children}</ClusterLayout>
    </div>
  );
};

ModalFooter.propTypes = {
  /** The footer content inside the modal.  */
  children: PropTypes.node,
  /** Change the content justification for the footer. To ensure design consistency, Only a subset (flex-end and `space-between`)
   * of normal justification choices are available.
   */
  justifyContent: PropTypes.oneOf(['flex-end', 'space-between']),
};

export default ModalFooter;
