import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '../Text';
import ButtonOrLink from '../ButtonOrLink';
import useId from '@mc/hooks/useId';

import stylesheet from './ActionList.less';

const ActionListItem = ({
  children,
  label,
  description,
  className,
  icon,
  iconEnd,
  onClick,
  href,
  ...rest
}) => {
  const describeId = useId();

  return (
    <div className={cx(stylesheet.item, rest.disabled && stylesheet.disabled)}>
      <ButtonOrLink
        onClick={onClick}
        className={stylesheet.label}
        href={href}
        aria-describedby={description ? describeId : undefined}
        {...rest}
      >
        {icon} <div>{label ? label : children}</div> {iconEnd}
      </ButtonOrLink>
      {description && (
        <Text
          appearance="medium-secondary"
          id={describeId}
          className={stylesheet.description}
        >
          {description}
        </Text>
      )}
    </div>
  );
};

ActionListItem.propTypes = {
  /** `children` will not be supported in the long term.
   * Default to using the `label` prop. */
  children: PropTypes.node,
  /** Secondary text for each action item */
  description: PropTypes.string,
  /** Actions that are redirected as links */
  href: PropTypes.string,
  /** Icon in front of the label */
  icon: PropTypes.node,
  /** Icon at the end of the label */
  iconEnd: PropTypes.node,
  /** Title of each action item */
  label: PropTypes.string,
  /** Mouse click event */
  onClick: PropTypes.func,
};

export default ActionListItem;
