import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for Tabs default values
export const TranslateTabs = () => {
  return {
    scrollLeftText: useDsTranslateMessage({
      id: 'mcds.tabs.scroll_left_text',
      defaultMessage: 'Scroll left',
    }),
    scrollRightText: useDsTranslateMessage({
      id: 'mcds.tabs.scroll_right_text',
      defaultMessage: 'Scroll right',
    }),
  };
};
