import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuRightIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import { PageSwitcher, PageSwitcherItem } from '../PageHeader';
import Text from '../Text';
import { useWizardActions, useWizardState } from './Wizard';
import { TranslateWizardBreadcrumbs } from './TranslateWizardBreadcrumbs';
import stylesheet from './WizardBreadcrumbs.less';

/**
 * In the forest, the wizard finds breadcrumbs to follow.
 *
 * In this implementation, breadcrumbs can only navigate backward; all future
 * steps appear as disabled buttons. Future steps may depend on previous ones,
 * so we cannot assume that, if someone backtracks two steps, they can navigate
 * forward two steps again in a single bound.
 */
const WizardBreadcrumbs = ({
  onNavigate,
  interactive = true,
  unsafe_hideFutureSteps = false,
}) => {
  const { currentStep, stepNames, displayNames } = useWizardState();
  const { isPhone } = useBreakpoints();
  const { navigate } = useWizardActions();
  const id = useId();
  const currentStepIndex = stepNames?.indexOf(currentStep);

  const {
    wizardBreadcrumbsLabelText,
    wizardBreadcrumbsStepText,
    wizardBreadcrumbsFutureStepText,
  } = TranslateWizardBreadcrumbs(currentStepIndex + 1, stepNames?.length ?? 0);

  if (!stepNames) {
    // Nothing to render if we don't know the step names.
    return null;
  }

  return (
    <nav aria-labelledby={id}>
      <span id={id} className="wink-visually-hidden">
        {wizardBreadcrumbsLabelText}
      </span>
      {isPhone ? (
        <span>
          <strong className={stylesheet.steps}>
            {unsafe_hideFutureSteps
              ? wizardBreadcrumbsStepText
              : wizardBreadcrumbsFutureStepText}
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {interactive ? ' ' : ` ${displayNames[currentStepIndex]}`}
          </strong>
          {interactive && (
            <PageSwitcher label="Pages">
              {stepNames.map((stepName, index) => {
                const isCurrentStep = currentStep === stepName;
                const isAfterCurrentStep = index > currentStepIndex;
                const displayName = displayNames[index] || stepName;

                if (unsafe_hideFutureSteps && isAfterCurrentStep) {
                  return null;
                }

                return (
                  <PageSwitcherItem
                    key={displayName}
                    label={displayName}
                    aria-current={isCurrentStep ? 'step' : undefined}
                    disabled={isAfterCurrentStep}
                    onClick={() => {
                      let shouldNavigate = true;
                      if (onNavigate) {
                        shouldNavigate = onNavigate(currentStep, stepName);
                      }
                      if (shouldNavigate !== false) {
                        navigate(stepName);
                      }
                    }}
                  />
                );
              })}
            </PageSwitcher>
          )}
        </span>
      ) : (
        <ol className={stylesheet.breadcrumbs}>
          {stepNames.map((stepName, index) => {
            const isCurrentStep = currentStep === stepName;
            const isAfterCurrentStep = index > currentStepIndex;
            const displayName = displayNames[index] || stepName;

            if (unsafe_hideFutureSteps && isAfterCurrentStep) {
              return null;
            }

            return (
              <li key={displayName} className={stylesheet.step}>
                {/* icon */}
                {index > 0 && (
                  <MenuRightIcon
                    className={cx(
                      isAfterCurrentStep && stylesheet.disabledIcon,
                    )}
                  />
                )}

                {interactive ? (
                  <button
                    type="button"
                    className={cx(
                      stylesheet.stepName,
                      isCurrentStep && stylesheet.current,
                    )}
                    aria-current={isCurrentStep ? 'step' : undefined}
                    disabled={isCurrentStep || isAfterCurrentStep}
                    onClick={() => {
                      let shouldNavigate = true;
                      if (onNavigate) {
                        shouldNavigate = onNavigate(currentStep, stepName);
                      }
                      if (shouldNavigate !== false) {
                        navigate(stepName);
                      }
                    }}
                  >
                    {displayName}
                  </button>
                ) : (
                  <Text
                    appearance="small"
                    className={cx(
                      stylesheet.stepName,
                      isCurrentStep && stylesheet.current,
                      !isCurrentStep && stylesheet.notInteractive,
                    )}
                    aria-current={isCurrentStep ? 'step' : undefined}
                  >
                    {displayName}
                  </Text>
                )}
              </li>
            );
          })}
        </ol>
      )}
    </nav>
  );
};

WizardBreadcrumbs.propTypes = {
  interactive: PropTypes.bool,
  onNavigate: PropTypes.func,
  /** Unsafe! Only use when your Wizard has branching flows. This should not be the norm. */
  unsafe_hideFutureSteps: PropTypes.bool,
};

export default WizardBreadcrumbs;
