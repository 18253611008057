import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import cx from 'classnames';
import Select from '../Select/Select';
import stylesheet from './PageHeaderSelect.less';

const renderSelectedValue = (selected, placeholder) => {
  return selected.length > 1
    ? `${selected.length} selected`
    : selected.length === 1
    ? selected.map((selection) => selection.value)
    : placeholder;
};

const PageHeaderSelect = forwardRef(function(
  {
    children,
    className,
    callToActionLabel,
    callToActionOnClick,
    callToActionHref,
    badge,
    size = 'regular',
    ...props
  },
  forwardedRef,
) {
  const classes = cx(stylesheet.root, className, {
    [stylesheet.smallMenu]: size === 'small',
  });

  return (
    <div className={stylesheet.menuBadgeContainer}>
      <Select
        callToActionOnClick={callToActionOnClick}
        callToActionHref={callToActionHref}
        callToActionLabel={callToActionLabel}
        mode="listbox"
        matchTargetWidth={false}
        className={classes}
        renderSelectedValue={renderSelectedValue}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </Select>
      <div className={stylesheet.badge}>{badge}</div>
    </div>
  );
});

PageHeaderSelect.propTypes = {
  /**
   * Optional badge component to display next to the select
   */
  badge: PropTypes.node,
  /**
   *  Link for the call to action
   *  This prop is only relevant in listbox mode
   */
  callToActionHref: PropTypes.string,
  /**
   *  Label for the call to action at the bottom of the open menu
   *  This prop is only relevant in listbox mode
   */
  callToActionLabel: PropTypes.string,
  /**
   *  Click handler for the call to action at the bottom of the open menu
   *  This prop is only relevant in listbox mode
   */
  callToActionOnClick: PropTypes.func,
  children: PropTypes.node,
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: PropTypes.func.isRequired,
  /**
   * Option to control the width of the select menu. Defaults to `regular`.
   */
  size: PropTypes.oneOf(['small', 'regular']),
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: PropTypes.any,
};

export default PageHeaderSelect;
