import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import chainHandlers from '@mc/fn/chainHandlers';
import Animate from '../Animate';
import Popup from '../Popup';
import stylesheet from './Tooltip.less';
import useDeviceContext from '@mc/editing/hooks/useDeviceContext';

/**
 * Tooltips provide contextual, helpful, but nonessential secondary information
 * upon hover or keyboard focus.
 *
 * Its child must support ref forwarding. Without it, the Tooltip will not know
 * how to position itself.
 */
function Tooltip({
  ariaStrategy,
  children,
  className,
  label,
  'aria-label': ariaLabel,
  direction = 'bottom',
  forceVisible = false,
  showTimeout,
  hideTimeout,
  ...props
}) {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef();
  const delayedUpdate = useRef(false);
  const id = useId();
  const { isMobileWeb, isMobileNative } = useDeviceContext();

  React.Children.only(children);

  const show = () => {
    if (delayedUpdate.current) {
      clearTimeout(delayedUpdate.current);
    }

    if (showTimeout) {
      delayedUpdate.current = setTimeout(() => {
        setIsVisible(true);
      }, showTimeout);
    } else {
      setIsVisible(true);
    }
  };
  const hide = () => {
    if (delayedUpdate.current) {
      clearTimeout(delayedUpdate.current);
    }

    if (hideTimeout) {
      delayedUpdate.current = setTimeout(() => {
        setIsVisible(false);
      }, hideTimeout);
    } else {
      setIsVisible(false);
    }
  };
  const shouldShow =
    (forceVisible || isVisible) && !isMobileNative && !isMobileWeb;

  return (
    <React.Fragment>
      {React.cloneElement(children, {
        onFocus: chainHandlers(children.props.onFocus, show),
        onBlur: chainHandlers(children.props.onBlur, hide),
        onMouseEnter: chainHandlers(children.props.onMouseEnter, show),
        onMouseLeave: chainHandlers(children.props.onMouseLeave, hide),
        onClick: chainHandlers(children.props.onClick, hide),
        'aria-describedby':
          children.props['aria-describedby'] ||
          (ariaStrategy === 'description' ? id : undefined),
        'aria-labelledby':
          children.props['aria-labelledby'] ||
          (ariaStrategy === 'label' ? id : undefined),
        ref: triggerRef,
      })}
      <Animate
        component={Popup}
        toggle={shouldShow}
        // Popup props
        arrow={<div className={stylesheet.arrow} />}
        offset={14}
        placement={direction}
        targetRef={triggerRef}
        // Underlying props
        aria-label={ariaLabel}
        role="tooltip"
        className={cx(stylesheet.tooltip, className)}
        id={id}
        {...props}
      >
        {label}
      </Animate>
    </React.Fragment>
  );
}

Tooltip.propTypes = {
  /** Use if the tooltip's contents should become the children's label or description. */
  ariaStrategy: PropTypes.oneOf(['label', 'description']),
  /** Set this only if information for a screen reader needs to differ from the label */
  'aria-label': PropTypes.string,
  /** This should always be one element. This will be the element the tooltip appears when hovered. */
  children: PropTypes.node.isRequired,
  /** A className that is added to the tooltip popup. */
  className: PropTypes.string,
  /** The default direction. A different position will be used if this choice would render off screen. */
  direction: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /** When true, internal hovering logic is ignored and the Tooltip is permanently visible. Please only use this sparingly. */
  forceVisible: PropTypes.bool,
  /** Duration of delay in milliseconds when hiding the tooltip */
  hideTimeout: PropTypes.number,
  /** The content to show in the tooltip. */
  label: PropTypes.node.isRequired,
  /** Duration of delay in milliseconds when showing the tooltip */
  showTimeout: PropTypes.number,
};

export default Tooltip;
