import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SwitcherLayout from '../SwitcherLayout';

import stylesheet from './Modal.less';

const ModalBody = ({ children, className, ...rest }) => {
  return (
    <div className={cx(stylesheet.body, className)} {...rest}>
      <SwitcherLayout gap={0}>{children}</SwitcherLayout>
    </div>
  );
};

ModalBody.propTypes = {
  /** The body content inside the modal.  */
  children: PropTypes.node,
};

export default ModalBody;
