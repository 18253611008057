import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  createContext,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import tabbable from 'tabbable';

import useId from '@mc/hooks/useId';
import useOutsideClick from '@mc/hooks/useOutsideClick';
import chainHandlers from '@mc/fn/chainHandlers';
import Animate from '../Animate';
import Popup from '../Popup';

import stylesheet from './Popover.less';

const noop = () => {};

export const PopoverContext = createContext();

/** Focus into Popover when opened */
function useInnerFocus(triggerRef, popoverRef, isVisible) {
  const isMountedRef = useRef();

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const trigger = triggerRef.current;
      const popover = popoverRef.current;
      const tabbables = popover ? tabbable(popover) : [];
      if (tabbables.length) {
        tabbables[0].focus();
      }

      return () => {
        if (isMountedRef.current) {
          trigger.focus();
        }
      };
    }
  }, [isVisible, popoverRef, triggerRef]);
}

/**
 * Activating the Popover trigger opens an inline, interactive popup. Popovers
 * have a close button, trap focus, and are dismissible using the close button
 * or via outside click.
 *
 * A close button for assistive technology is required inside the Popover.
 *
 * The trigger must support ref forwarding. Without it, the Popover will not
 * know how to position itself.
 */
function Popover({
  arrowClassName,
  children,
  className,
  direction = 'bottom',
  label,
  description,
  hasArrow = false,
  forceVisible = false,
  trigger,
  offset = 12,
  onOpen = noop,
  onRequestClose = noop,
  ...props
}) {
  const labelId = useId();
  const descriptionId = useId();
  const triggerRef = useRef();
  const popoverRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const open = useCallback(() => {
    setIsVisible(true);
    onOpen();
  }, [onOpen]);

  const close = useCallback(() => {
    setIsVisible(false);
    onRequestClose();
  }, [onRequestClose]);
  const isOpen = forceVisible || isVisible;

  const contextValue = useMemo(
    () => ({
      isOpen,
      open,
      close,
    }),
    [open, close, isOpen],
  );

  useOutsideClick(popoverRef, close);
  useInnerFocus(triggerRef, popoverRef, isVisible);

  React.Children.only(trigger);

  return (
    <PopoverContext.Provider value={contextValue}>
      {React.cloneElement(trigger, {
        onClick: chainHandlers(trigger.props.onClick, open),
        'aria-expanded': isOpen.toString(),
        ref: triggerRef,
      })}
      <Animate
        ref={popoverRef}
        component={Popup}
        toggle={isOpen}
        // Popup props
        arrow={
          hasArrow ? (
            <div className={cx(stylesheet.arrowRoot, arrowClassName)} />
          ) : (
            <Fragment />
          )
        }
        placement={direction}
        targetRef={triggerRef}
        offset={offset}
        // Underlying component props
        className={cx(stylesheet.root, className)}
        {...props}
      >
        {label && (
          <span id={labelId} className="wink-visually-hidden">
            {label}
          </span>
        )}
        {description && (
          <span id={descriptionId} className="wink-visually-hidden">
            {description}
          </span>
        )}
        <div
          aria-labelledby={label && labelId}
          aria-describedby={description && descriptionId}
          role="dialog"
        >
          {children}
        </div>
      </Animate>
    </PopoverContext.Provider>
  );
}

Popover.propTypes = {
  /** HTML class name given to the arrow element that wraps the Popover's arrow content */
  arrowClassName: PropTypes.string,
  /** This should always be one element. This is the element that appears inside the popover dialog. */
  children: PropTypes.node.isRequired,
  /** HTML class name given to the container element that wraps the Popover's content */
  className: PropTypes.string,
  /** Visually hidden description of the popover dialog for assistive technology */
  description: PropTypes.string.isRequired,
  /** The default direction. A different position will be used if this choice would render off screen. */
  direction: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /** When true, internal logic is ignored and the popover dialog is permanently visible. Please only use this sparingly. */
  forceVisible: PropTypes.bool,
  /** Popover dialog arrow indication is visible if true. False is the default setting. */
  hasArrow: PropTypes.bool,
  /** Visually hidden label for the popover dialog for assistive technology */
  label: PropTypes.string,
  /** Assigns offset value. Defaults to 12 */
  offset: PropTypes.number,
  /** Event that fires when the Popover is opened */
  onOpen: PropTypes.func,
  /** Event that fires when the Popover is closed */
  onRequestClose: PropTypes.func,
  /** This should always be one element. This will be the element the popover dialog appears over when hovered. */
  trigger: PropTypes.node.isRequired,
};

export default Popover;
