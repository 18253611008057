import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ClusterLayout from '../ClusterLayout';
import Text from '../Text';
import useId from '@mc/hooks/useId';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';
import stylesheet from './Meter.less';

const Meter = React.forwardRef(function Meter(
  {
    'aria-labelledby': ariaLabelledBy,
    className,
    action,
    hideLabel,
    helpText,
    miscText,
    label,
    size = 'medium',
    min = 0,
    max = 100,
    value,
    variant = 'neutral',
  },
  forwardedRef,
) {
  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const miscTextId = useId();

  const fillWidth = (value / (max - min)) * 100;

  const _variant = typeof variant === 'function' ? variant(fillWidth) : variant;

  const textSize = size === 'tiny' ? 'small' : size;

  return (
    <div className={cx(stylesheet.root, className)} ref={forwardedRef}>
      <div className={stylesheet.before}>
        {label && (
          <span
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
          >
            <Text
              as="span"
              appearance={
                textSize === 'large' ? 'heading-3' : `${textSize}-bold`
              }
              className={stylesheet.label}
            >
              {label}
            </Text>
          </span>
        )}
        {miscText && (
          <Text id={miscTextId} appearance={`${textSize}-secondary`}>
            {miscText}
          </Text>
        )}
      </div>

      <div
        id={id}
        role="progressbar"
        aria-labelledby={ariaLabelledByIds(ariaLabelledBy, label && labelId)}
        aria-describedby={ariaDescribedByIds(
          helpText && helpTextId,
          miscText && miscTextId,
        )}
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
        className={cx(
          stylesheet.meterTrack,
          stylesheet[size],
          stylesheet[_variant],
        )}
      >
        <div
          className={stylesheet.meterFill}
          style={{
            width: fillWidth + '%',
          }}
        />
      </div>

      <ClusterLayout
        justifyContent="space-between"
        className={stylesheet.after}
      >
        {size !== 'tiny' && helpText ? (
          <Text
            as="span"
            id={helpTextId}
            appearance="small"
            className={stylesheet.helpText}
          >
            {helpText}
          </Text>
        ) : null}
        {size !== 'tiny' && action ? (
          <Text as="span" appearance="small" className={stylesheet.action}>
            {action}
          </Text>
        ) : null}
      </ClusterLayout>
    </div>
  );
});

Meter.propTypes = {
  /** @ignore Throwaway propType to do complex checks. */
  // eslint-disable-next-line react/no-unused-prop-types
  _: (props, propName, componentName) => {
    if (!props.label && !props['aria-labelledby']) {
      return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName));
    }
  },
  /** Should be a `TextButton` containing an action to take relevant to the meter */
  action: PropTypes.node,
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /** Optional class name that is applied to the root div */
  className: PropTypes.string,
  /** Text that appears below the input */
  helpText: PropTypes.node,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** The label of the meter. */
  label: PropTypes.node,
  /** The upper numeric bound of the measured range. This must be greater than the minimum value (min attribute), */
  max: PropTypes.number.isRequired,
  /** The lower numeric bound of the measured range. This must be less than the maximum value (max attribute),
   * if specified. If unspecified, the minimum value is 0.*/
  min: PropTypes.number,
  /** Text that appears above the input and right of the label. Usually shows Required state of the input. */
  miscText: PropTypes.node,
  /** Changes the height of the meter as well as the text size of the labels and descriptive text */
  size: PropTypes.oneOf(['large', 'medium', 'small', 'tiny']),
  /** The current numeric value. This must be between the minimum and maximum values (min attribute and max attribute)
   * if they are specified. If unspecified or malformed, the value is 0. If specified, but not within the range given by
   * the min attribute and max attribute, the value is equal to the nearest end of the range. */
  value: PropTypes.number.isRequired,
  /** Either the name of the color variant to use or a function that takes the current value percentage as an argument
   * and returns a variant (this is useful for swapping colors with different value ranges). */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['neutral', 'positive', 'warning', 'negative']),
    PropTypes.func,
  ]),
};

export default Meter;
