import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Import Theme styles
import './themes/apollo.css';

export const ThemeContext = React.createContext();
export const SubbrandContext = React.createContext();

const Theme = React.forwardRef(function Theme(
  { theme = 'light', subbrand, children },
  forwardedRef,
) {
  return (
    <SubbrandContext.Provider value={subbrand}>
      <ThemeContext.Provider value={theme}>
        <div className={cx(subbrand, theme)} ref={forwardedRef}>
          {children}
        </div>
      </ThemeContext.Provider>
    </SubbrandContext.Provider>
  );
});

Theme.propTypes = {
  /** The content inside theme. */
  children: PropTypes.node,
  /** Add custom color palette for design system components */
  subbrand: PropTypes.string,
  /** Change the color mode of the subbrand. */
  theme: PropTypes.oneOf(['light', 'dark']),
};

const useThemeContext = () => useContext(ThemeContext);
const useSubbrandContext = () => useContext(SubbrandContext);

export default Theme;
export { useThemeContext, useSubbrandContext };
