import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ModalIdContext } from './Modal';

import stylesheet from './Modal.less';

const ModalHeader = ({ children, className, ...rest }) => {
  const id = useContext(ModalIdContext);

  return (
    <div className={cx(stylesheet.header, className)} {...rest}>
      <h1 id={id} className={stylesheet.title}>
        {children}
      </h1>
    </div>
  );
};

ModalHeader.propTypes = {
  /** The header content inside the modal.  */
  children: PropTypes.node,
};

export default ModalHeader;
