import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import stylesheet from './Page.css';

export type PageType = PropsWithChildren<{
  className?: string;
}>;

/**
 * Wrap this around the `PageHeader` and `PageContent` components. Page will
 * provide an isolated
 * [stacking context](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context)
 * for the `PageHeader` component inside it.
 */
const Page = React.forwardRef<HTMLDivElement, PageType>(function Page(
  { children, className },
  forwardedRef,
) {
  return (
    <div className={cx(stylesheet.root, className)} ref={forwardedRef}>
      {children}
    </div>
  );
});

export default Page;
